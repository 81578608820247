import React from "react";
import { Form, Field, FieldArray } from 'formik';
import '../../components/SmartContract/SmartContract.css'
// import Select from 'react-select';
import ReactSelect from '../../components/common/ReactSelectFormik';
// import InitialImage from '@assets/images/Shape.png';
import remove from '../../asset/images/remove_30.png';
import { useNavigate } from "react-router";
import { customStyles, validateNumber } from '../helpers/utils';

const SmartContract = (props) => {
  const navigate = useNavigate();
    return (
        <div className='container'>
          <div className="mb-2 mt-2">
            <span className="smartHeader cursor-pointer"><i class="fa fa-angle-left" aria-hidden="true" onClick={() => navigate("/contractDashboard")}></i></span>
            <span className="smartHeader ml-3">Create Smart Contract</span>
          </div>
                <Form>
                <FieldArray
                    name="data"
                    render={arraryHelpers => (
                      <div className="shadow border border-primary tableBorder">
                       { console.log(props.formProps.values)}
                        {props.formProps.values.data && props.formProps.values.data.length === 0 && (
                      <button className="col-2 m-4 button" onClick={() => arraryHelpers.push({ eventName:'', argument: [{ arg:"", argType:"" }]})}>
                        Add
                      </button>
                      )}
                        { props.formProps.values.data && props.formProps.values.data.length > 0 && (
                          props.formProps.values.data.map((feeItem, index) => (
                            <div className="smartTxt textFont border border-primary">
                              <div>
                                <div className="row d-flex">
                                  <div className="col m-4">
                                    <div className="">
                                      <span> {index + 1}.</span>
                                      <span> Event Name</span>
                                    </div>
                                    <div>
                                      <Field
                                        className="inputBox border text tableBorder pl-3"
                                        name={`data.${index}.eventName`}
                                        type="text"
                                        placeholder = 'Event Name'
                                        autocomplete="off"
                                        onKeyDown={e => validateNumber(e)}
                                        onKeyPress={props.handleKeyPress}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-1 d-flex justify-content-center m-4 pt-20">
                                <div role="presentation" onClick={() => { arraryHelpers.remove(index); }}>
                                  <span className="cursor-pointer"> <img src={remove} className="removeIconMain" alt="remove" /> </span>
                                </div>
                              </div>
                                </div>
                                <div className="">
                                  { props.formProps.values.data[index] && (
                                  <div>
                                    <FieldArray
                                      name={`data.${index}.argument`}
                                      render={() => (
                                        <>
                                          { props.formProps.values.data[index].argument.length  === 0 && (
                                        <div className='textColor color-primary-purple cursor-pointer m-4 mt-4 font-size-20 addArg' onClick={() => { const preValue = { ...props.formProps.values.data[index] }; const argument = []; argument.push({ arg:"", argType:"" }); delete preValue.argument; preValue.argument = argument; arraryHelpers.replace(index, { ...preValue }); }}>
                                          <i className="fa fa-plus-circle mr-2 iconcolor"/>Inputs
                                        </div>
                                          )}
                                          { props.formProps.values.data[index].argument && props.formProps.values.data[index].argument.length > 0 && (
                                            props.formProps.values.data[index].argument.map((argumentItem, argumentIndex) => {
                                              return (
                                                <>
                                                  <div>
                                                    <div className="row m-3 d-flex align-items-center">
                                                      <div className="col">
                                                        <span className="font">Input {argumentIndex + 1}</span>
                                                      </div>
                                                      <div className="col">
                                                        <Field
                                                          className="inputBox border text tableBorder form-control"
                                                          name={`data.${index}.argument.${argumentIndex}.arg`}
                                                          type="text"
                                                          placeholder = {`Input ${argumentIndex + 1}`}
                                                          autocomplete="off"
                                                          onKeyDown={e => validateNumber(e)}
                                                          onKeyPress={props.handleKeyPress}
                                                        />
                                                      </div>
                                                      <div className="col">
                                                        <span>Type {argumentIndex + 1}</span>
                                                      </div>
                                                      <div className="p-10 col">
                                                      <ReactSelect
                                                          styles={customStyles}
                                                           name={`data.${index}.argument.${argumentIndex}.argType`}
                                                           options={props && props.datatypes}
                                                           onChange={() => {}}
                                                        />
                                                      </div>
                                                      <div className="col" >
                                                      <span className="cursor-pointer"onClick={() => { const preValue = { ...props.formProps.values.data[index] }; const argument = preValue.argument; argument.splice(argumentIndex, 1); delete preValue.argument; preValue.argument = argument; arraryHelpers.replace(index, { ...preValue }); }} > <img src={remove} className="removeIcon" alt="remove"/> </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  { argumentIndex === props.formProps.values.data[index].argument.length - 1 && (
                                               
                                                  <div className="textColor color-primary-purple cursor-pointer m-4 mt-4 addArg" onClick={() => { const preValue = { ...props.formProps.values.data[index] }; const argument = preValue.argument; argument.push({ arg:"", argType:"" }); delete preValue.argument; preValue.argument = argument; arraryHelpers.replace(index, { ...preValue }); }}>
                                                    <i className="fa fa-plus-circle mr-2 iconcolor" />Inputs
                                                  </div>
                                               
                                                  )}
                                                </>
                                              );
                                            })
                                          )}

                                        </>
                                      )}
                                    />
                                  </div>
                                  )}
                                </div>
                                { index === props.formProps.values.data.length - 1  && (
                              <button type="button" className="button col-2 m-4" onClick={() => arraryHelpers.push({ eventName:'', argument: [{ arg:"", argType:"" }]})}>
                                Add Event
                              </button>
                              )}
                              </div>
                            </div>
                          ))
                        )}
                        {props.formProps.values.data && props.formProps.values.data.length > 0 && (
                         <div>
                            <button className="button col-2 m-4" type="submit">Submit</button>
                         </div>
                        )}
                      </div>
                    )}
                  />
                  </Form>

        </div>
    )
}
export default SmartContract;