import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const customId = "custom-id-yes";
export const notifySuccess = (msg) => toast.success(msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    
});

export const notifyFailure = (msg) => toast.error(msg, {
    position: "top-center",
    autoClose: 5000,
    toastId: customId,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
})
export const validateEvent = (event) => {
    const validateObj = {
        validated : true
    }
    const smartdata = ['abstract', 'as','after', 'alias', 'apply', 'auto', 'case', 'catch', 'copyof', 'default', 'define', 'final',  'immutable', 'implements', 'in', 'inline', 'let', 'macro', 'match', 'mutable', 'null', 'of' , 'override', 'partial', 'promise', 'reference', 'relocatable', 'sealed', 'sizeof' ,'static', 'supports', 'switch', 'try', 'typedef',  'typeof', 'unchecked', 'uint', 'uint8', 'uint16', 'uint32', 'uint64', 'uint128', 'uint256', 'int', 'int8', 'int16', 'int32', 'int64', 'int128', 'int256', 'bool', 'address', 'payable', 'enum', 'bytes', 'bytes1', 'bytes2', 'bytes3', 'bytes4', 'bytes5', 'bytes6', 'bytes7', 'bytes8', 'bytes9', 'bytes10', 'bytes11', 'bytes12', 'bytes13', 'bytes14', 'bytes15', 'bytes16', 'bytes17', 'bytes18', 'bytes19', 'bytes20', 'bytes21', 'bytes22', 'bytes23', 'bytes24', 'bytes25', 'bytes26', 'bytes27', 'bytes28', 'bytes29', 'bytes30', 'bytes31', 'bytes32', 'string', 'function', 'contract', 'constructor', 'pure', 'view', 'public', 'msg', 'emit', 'event', 'modifier', 'import', 'pragma', 'solidity' ];
    if (smartdata.includes(event)) {
      validateObj.validated = false;
    }
    return validateObj
}

export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        '&:active': {
            color: 'white',
        }
    })
}
export const validateNumber = (event) => {
    const keyCode = event.keyCode;
    if (!((keyCode >= 65 && keyCode <= 90) || (keyCode === 8)|| (keyCode === 9) || (keyCode === 37)||  (keyCode === 39)|| (keyCode === 46) || (keyCode === 189))) {
      event.preventDefault();
    }
  }