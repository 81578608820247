import { useEffect, useState } from "react";
import ReactSelect from '../../components/common/ReactSelectFormik';

const ExportTab = (props) => {

    const name = window.localStorage.getItem('name');
    const address = window.localStorage.getItem('address')
    const date = window.localStorage.getItem('date')
    const [showInput, setShowInput] = useState(false);
    const [maxDate, setMaxDate] = useState(getCurrentDate());

    function handleClick() {
        setShowInput(!showInput);
    }
    function handleDateChange(event) {
        setMaxDate(event.target.value);
    }
    function getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    }



    console.log(props && props.demo, 'demo');
    return (
        <div className="container mt-4">
            <div className="d-flex">

                <div className="col-6 d-flex">

                    <button className="button" onClick={props.handleFilterButtonClick}>Quarterly</button>
                    <button className="button">half yearly </button>
                    <button className="button">year</button>

                </div>

            </div>

            <div>


            </div>
        </div>
    )
}
export default ExportTab;