import React, { useEffect, useState, useRef } from "react";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import "../../style.css";
import Logo from "../../asset/images/audit_block_logo.svg";
import DataLogging from "../../asset/images/dataLogging.png";
import axiosClient from "../../ApiClient";
import API_ENDPOINTS from "../../components/common/api";
import { notifyFailure, notifySuccess } from "../../components/helpers/utils";
import Option from "../../asset/images/Option.png";
import SSIMG1 from "../../asset/images/2silde.jpg";
import SSIMG2 from "../../asset/images/1slide.jpg";
import SSIMG3 from "../../asset/images/3slide.jpg";
import cn from "classnames";
import HandBurger from "../../asset/images/hamburger.png";
import Vid from '../../asset/images/auditblock_v1.mp4'

const LandingPage = () => {
  const [stickyClass, setStickyClass] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [selected, setSelected] = useState("home");
  const [isHandburgerOpen, setisHandburgerOpen] = useState(false);
  const resizableRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [dimensionsHeight, setDimensionsHeight] = useState("");
  const [play, setplay] = useState(false);
  
  console.log("dimensionsHeight", dimensions);

  useEffect(() => {
    // Function to update the size state
    const updateSize = () => {
      if (resizableRef.current) {
        const { clientWidth, clientHeight } = resizableRef.current;
        setDimensions({ width: clientWidth, height: clientHeight });
      }
    };
    // Call the updateSize function initially to set the initial size
    updateSize();
    // Attach event listener for resizing (if needed)
    window.addEventListener("resize", updateSize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  function stickNavbar() {
    let windowHeight = window.scrollY;
    windowHeight > 20 ? setStickyClass("header--sticky") : setStickyClass("");
    windowHeight > 20
      ? setDimensionsHeight("brightness")
      : setDimensionsHeight("");
  }

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    window.scrollTo(0, 0);
  }, []);

  const usecaseClick = (clickedUsecase) => {
    if (clickedUsecase === 1) {
      const element = document.querySelector("#dot1");
      const btn = document.querySelector("#mybtn1");
      const cnt1 = document.querySelector("#cnt1");
      if (btn.textContent === "Read more") {
        cnt1.textContent =
          "A blockchain-based audit trail enables tracking the origin and journey of products from their source to the end consumer. Each transaction , such as the transfer of ownership, manufacturing, packaging, and transportation, is recorded on the blockchain, creating an auditable trail of the products history. This helps in verifying the authenticity and provenance of products, reducing the risk of counterfeiting and ensuring compliance with regulations.";
        btn.textContent = "Read less";
      } else {
        cnt1.textContent =
          "A blockchain-based audit trail enables tracking the origin and journey of products from their source to the end consumer. Each transaction , such as the transfer of..";
        btn.textContent = "Read more";
      }
      // if (usecase1 === 'd-none') {
      //     setUsecase1("")
      //     element.style.display = 'none';
      //     btn.textContent = 'Read less';
      // } else {
      //     setUsecase1("d-none");
      //     element.style.display = 'inline-flex';
      //     btn.textContent = 'Read more';
      // }
    }
    if (clickedUsecase === 2) {
      const element = document.querySelector("#dot2");
      const btn = document.querySelector("#mybtn2");
      const cnt2 = document.querySelector("#cnt2");
      if (btn.textContent === "Read more") {
        cnt2.textContent =
          "By recording critical information related to quality control, inspections, certifications, and compliance checks on the blockchain, stakeholders can easily track and verify that the necessary standards and regulations are met throughout the supply chain. Any issues or deviations can be quickly identified and addressed, leading to improved quality assurance and regulatory compliance.";
        btn.textContent = "Read less";
      } else {
        cnt2.textContent =
          "By recording critical information related to quality control, inspections, certifications, and compliance checks on the blockchain, stakeholders can easily track and verify...";
        btn.textContent = "Read more";
      }
      // if (usecase2 === 'd-none') {
      //     setUsecase2("")
      //     element.style.display = 'none';
      //     btn.textContent = 'Read less';
      // } else {
      //     setUsecase2("d-none")
      //     element.style.display = 'inline-flex';
      //     btn.textContent = 'Read more';
      // }
    }
    if (clickedUsecase === 3) {
      const element = document.querySelector("#dot3");
      const btn = document.querySelector("#mybtn3");
      const cnt3 = document.querySelector("#cnt3");
      if (btn.textContent === "Read more") {
        cnt3.textContent =
          "A blockchain-based audit trail provides transparency by allowing all authorized participants in the supply chain to access and validate the recorded information. This transparency helps build trust among stakeholders, facilitates effective communication, and enables collaborative decision-making. It also allows consumers to gain insights into the production process, ethical sourcing, and sustainability practices, fostering a more informed purchasing experience.";
        btn.textContent = "Read less";
      } else {
        cnt3.textContent =
          "A blockchain-based audit trail provides transparency by allowing all authorized participants in the supply chain to access and validate the recorded information. This transparency helps build trust among...";
        btn.textContent = "Read more";
      }
      // if (usecase3 === 'd-none') {
      //     setUsecase3("")
      //     element.style.display = 'none';
      //     btn.textContent = 'Read less';
      // } else {
      //     setUsecase3("d-none")
      //     element.style.display = 'inline-flex';
      //     btn.textContent = 'Read more';
      // }
    }
    if (clickedUsecase === 4) {
      const element = document.querySelector("#dot4");
      const btn = document.querySelector("#mybtn4");
      const cnt4 = document.querySelector("#cnt4");
      if (btn.textContent === "Read more") {
        cnt4.textContent =
          "In the event of product recalls, a blockchain-based audit trail can expedite the identification and removal of affected products from the supply chain.The immutable and transparent nature of the blockchain ensures that the recall process is accurately documented and traceable, minimizing the impact on consumer safety and brand reputation.";
        btn.textContent = "Read less";
      } else {
        cnt4.textContent =
          "In the event of product recalls, a blockchain-based audit trail can expedite the identification and removal of affected products from the supply chain...";
        btn.textContent = "Read more";
      }
      // if (usecase4 === 'd-none') {
      //     setUsecase4("")
      //     element.style.display = 'none';
      //     btn.textContent = 'Read less';
      // } else {
      //     setUsecase4("d-none")
      //     element.style.display = 'inline-flex';
      //     btn.textContent = 'Read more';
      // }
    }
    if (clickedUsecase === 5) {
      const element = document.querySelector("#dot5");
      const btn = document.querySelector("#mybtn5");
      const cnt5 = document.querySelector("#cnt5");
      if (btn.textContent === "Read more") {
        cnt5.textContent =
          "The audit trail recorded on the blockchain serves as a reliable source of evidence in case of disputes or investigations. The chronological record of transactions and associated data can be used to reconstruct events, verify claims, and resolve conflicts more efficiently, reducing the need for time-consuming and costly investigations.";
        btn.textContent = "Read less";
      } else {
        cnt5.textContent =
          "The audit trail recorded on the blockchain serves as a reliable source of evidence in case of disputes or...";
        btn.textContent = "Read more";
      }
      // if (usecase5 === 'd-none') {
      //     setUsecase5("")
      //     element.style.display = 'none';
      //     btn.textContent = 'Read less';
      // } else {
      //     setUsecase5("d-none")
      //     element.style.display = 'inline-flex';
      //     btn.textContent = 'Read more';
      // }
    }
    if (clickedUsecase === 6) {
      const element = document.querySelector("#dot6");
      const btn = document.querySelector("#mybtn6");
      const cnt6 = document.querySelector("#cnt6");
      if (btn.textContent === "Read more") {
        cnt6.textContent =
          "A blockchain-based audit trail enables tracking the origin and journey of products from their source to the end consumer. Each transaction , such as the transfer of ownership, manufacturing, packaging, and transportation, is recorded on the blockchain, creating an auditable trail of the products history. This helps in verifying the authenticity and provenance of products, reducing the risk of counterfeiting and ensuring compliance with regulations.";
        btn.textContent = "Read less";
      } else {
        cnt6.textContent =
          "With a blockchain-based audit trail, organizations can maintain a transparent and auditable record of their...";
        btn.textContent = "Read more";
      }
      // if (usecase6 === 'd-none') {
      //     setUsecase6("")
      //     element.style.display = 'none';
      //     btn.textContent = 'Read less';
      // } else {
      //     setUsecase6("d-none")
      //     element.style.display = 'inline-flex';
      //     btn.textContent = 'Read more';
      // }
    }
  };

  const nameOnchange = (e) => {
    console.log("ee", e.target.value);
    setName(e.target.value);
  };

  const emailOnchange = (e) => {
    console.log("ee", e.target.value);
    setEmail(e.target.value);
  };

  const messageOnchange = (e) => {
    console.log("ee", e.target.value);
    setMessage(e.target.value);
  };

  const onSubmitValue = () => {
    console.log("ww2", name, email, message);
    axiosClient
      .post(API_ENDPOINTS.postMessage, {
        name: name,
        emailId: email,
        message: message,
      })
      .then((res) => {
        console.log("res", res);
        notifySuccess("Thank you for contacting us");
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((err) => {
        notifyFailure(err.response.data.message);
      });
  };

  const handleClick = (e, classs, id, position) => {
    e.preventDefault();
    const section = document.querySelector(id);

    if (
      (isHandburgerOpen && dimensions.width < 1025) ||
      position === "lastaboutus"
    ) {
      const offsetHeight = 75; // Set your desired offset height here
      // Calculate the target position with the offset height
      const targetPosition =
        section.getBoundingClientRect().top + window.pageYOffset - offsetHeight;
      // Perform the scroll
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth", // You can use "auto" for instant scrolling
      });
    } else {
      section.scrollIntoView({ behavior: "smooth" });
    }
    setSelected(classs);
  };

  const PlayVideo = (e) => {
    e.preventDefault();
if (play) {
  setplay(false) 
} else {
  setplay(true)
}
  };

  return (
    <>
     <Modal className="modalres" isOpen={play} keyboard={false}>
                <ModalBody style={{display: 'contents'}}>
                  <button class="closed" onClick={(e) => {PlayVideo(e)}}>X</button>
                <video className="videoRes" controls autoPlay>
        <source src={Vid} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
                </ModalBody>
            </Modal>
      <header
        ref={resizableRef}
        className={`header ${stickyClass}`}
        style={
          isHandburgerOpen && dimensions.width < 1025
            ? { backgroundColor: "white", height: "100vh" }
            : { height: "70px" }
        }
      >
        <div class="header__content header__content--fluid-width">
          <div class="header__logo-title">
            <img className="logoSize" src={Logo} />{" "}
            <span
              className="headerLogo"
              style={isHandburgerOpen ? { color: "black" } : {}}
            >
              AUDIT BLOCK
            </span>
          </div>
          <nav class="header__menu">
            <ul>
              <li>
                <a
                  className={cn(
                    selected == "home" ? "selected header-link" : "header-link"
                  )}
                  href="#intro"
                  onClick={(e) => {
                    handleClick(e, "home", "#intro");
                  }}
                >
                  HOME
                </a>
              </li>
              <li>
                <a
                  className={
                    selected == "about" ? "selected header-link" : "header-link"
                  }
                  href="#about"
                  onClick={(e) => {
                    handleClick(e, "about", "#about");
                  }}
                >
                  ABOUT
                </a>
              </li>
              <li>
                <a
                  className={
                    selected === "benefits"
                      ? "selected header-link"
                      : "header-link"
                  }
                  href="#benefits"
                  onClick={(e) => {
                    handleClick(e, "benefits", "#benefits");
                  }}
                >
                  BENEFITS
                </a>
              </li>
              <li>
                <a
                  className={
                    selected === "features"
                      ? "selected header-link"
                      : "header-link"
                  }
                  href="#features"
                  onClick={(e) => {
                    handleClick(e, "features", "#features");
                  }}
                >
                  FEATURES
                </a>
              </li>
              <li>
                <a
                  className={
                    selected === "usecases"
                      ? "selected header-link"
                      : "header-link"
                  }
                  href="#usecases"
                  onClick={(e) => {
                    handleClick(e, "usecases", "#usecases");
                  }}
                >
                  USE CASES
                </a>
              </li>
              <li>
                <a
                  className={
                    selected === "faq" ? "selected header-link" : "header-link"
                  }
                  href="#faq"
                  onClick={(e) => {
                    handleClick(e, "faq", "#faq");
                  }}
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  className={
                    selected === "sakha" ? "selected header-link" : "header-link"
                  }
                  href="#sakha"
                  onClick={(e) => {
                    handleClick(e, "sakha", "#sakha");
                  }}
                >
                  SAKHA GLOBAL
                </a>
              </li>
              {/* <!-- <li class="menu-item-has-children"><a href="#features" className={selected === 'home' ? 'selected': '' `header-link`}>FEATURES</a>
						<ul class="sub-menu">
							<li><a href="#hiw" className={selected === 'home' ? 'selected': '' `header-link`}>HOW IT WORKS</a></li>
							<li><a href="#clients" className={selected === 'home' ? 'selected': '' `header-link`}>OUR CLIENTS</a></li>
							<li><a href="#faq" className={selected === 'home' ? 'selected': '' `header-link`}>FAQ</a></li>
							<li><a href="#testimonials" className={selected === 'home' ? 'selected': '' `header-link`}>TESTIMONIALS</a></li>
							<li><a href="#support" className={selected === 'home' ? 'selected': '' `header-link`}>SUPPORT</a></li>
						</ul>
					</li> --> */}
              {/* <!-- <li><a href="#pricing" className={selected === 'home' ? 'selected': '' `header-link`}>PRICING</a></li> --> */}
              <li>
                <a
                  href="#cta"
                  className={
                    selected === "cta" ? "selected header-link" : "header-link"
                  }
                  onClick={(e) => {
                    handleClick(e, "cta", "#cta");
                  }}
                >
                  CONTACT
                </a>
              </li>
              {/* <!-- <li class="header__btn header__btn--login modal-toggle" data-openpopup="signuplogin" data-popup="login"><a href="#">LOGIN</a></li> */}
              {/* <li class="header__btn header__btn--signup modal-toggle" data-openpopup="signuplogin" data-popup="signup"><a href="#">GET STARTED</a></li>			 --> */}
            </ul>
          </nav>

          <div
            class="handburger__div"
            onClick={() => setisHandburgerOpen(!isHandburgerOpen)}
          >
            <img
              src={HandBurger}
              alt="Handburger"
              style={
                !isHandburgerOpen ? { filter: "brightness(0)invert(1)" } : {}
              }
              className={`${dimensionsHeight}`}
            />
          </div>
          {isHandburgerOpen && dimensions.width < 1025 && (
            <div class="handburger__open">
              <ul>
                <li
                  onClick={(e) => {
                    handleClick(e, "home", "#intro");
                    setisHandburgerOpen(!isHandburgerOpen);
                  }}
                >
                  <a
                    className={cn(
                      selected == "home"
                        ? "selected header-link"
                        : "header-link"
                    )}
                    href="#intro"
                  >
                    HOME
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    handleClick(e, "about", "#about");
                    setisHandburgerOpen(!isHandburgerOpen);
                  }}
                >
                  <a
                    className={
                      selected == "about"
                        ? "selected header-link"
                        : "header-link"
                    }
                    href="#about"
                  >
                    ABOUT
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    handleClick(e, "benefits", "#benefits");
                    setisHandburgerOpen(!isHandburgerOpen);
                  }}
                >
                  <a
                    className={
                      selected === "benefits"
                        ? "selected header-link"
                        : "header-link"
                    }
                    href="#benefits"
                  >
                    BENEFITS
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    handleClick(e, "features", "#features");
                    setisHandburgerOpen(!isHandburgerOpen);
                  }}
                >
                  <a
                    className={
                      selected === "features"
                        ? "selected header-link"
                        : "header-link"
                    }
                    href="#features"
                  >
                    FEATURES
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    handleClick(e, "usecases", "#usecases");
                    setisHandburgerOpen(!isHandburgerOpen);
                  }}
                >
                  <a
                    className={
                      selected === "usecases"
                        ? "selected header-link"
                        : "header-link"
                    }
                    href="#usecases"
                  >
                    USE CASES
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    handleClick(e, "faq", "#faq");
                    setisHandburgerOpen(!isHandburgerOpen);
                  }}
                >
                  <a
                    className={
                      selected === "faq"
                        ? "selected header-link"
                        : "header-link"
                    }
                    href="#faq"
                  >
                    FAQ
                  </a>
                </li>
                {/* <!-- <li class="menu-item-has-children"><a href="#features" className={selected === 'home' ? 'selected': '' `header-link`}>FEATURES</a>
						<ul class="sub-menu">
							<li><a href="#hiw" className={selected === 'home' ? 'selected': '' `header-link`}>HOW IT WORKS</a></li>
							<li><a href="#clients" className={selected === 'home' ? 'selected': '' `header-link`}>OUR CLIENTS</a></li>
							<li><a href="#faq" className={selected === 'home' ? 'selected': '' `header-link`}>FAQ</a></li>
							<li><a href="#testimonials" className={selected === 'home' ? 'selected': '' `header-link`}>TESTIMONIALS</a></li>
							<li><a href="#support" className={selected === 'home' ? 'selected': '' `header-link`}>SUPPORT</a></li>
						</ul>
					</li> --> */}
                {/* <!-- <li><a href="#pricing" className={selected === 'home' ? 'selected': '' `header-link`}>PRICING</a></li> --> */}
                <li
                  onClick={(e) => {
                    handleClick(e, "cta", "#cta");
                    setisHandburgerOpen(!isHandburgerOpen);
                  }}
                >
                  <a
                    href="#cta"
                    className={
                      selected === "cta"
                        ? "selected header-link"
                        : "header-link"
                    }
                  >
                    CONTACT
                  </a>
                </li>
                {/* <!-- <li class="header__btn header__btn--login modal-toggle" data-openpopup="signuplogin" data-popup="login"><a href="#">LOGIN</a></li> */}
                {/* <li class="header__btn header__btn--signup modal-toggle" data-openpopup="signuplogin" data-popup="signup"><a href="#">GET STARTED</a></li>			 --> */}
              </ul>
            </div>
          )}
        </div>
      </header>
      <section class="section section--intro" id="intro">
        <div class="section__content section__content--fluid-width section__content--intro">
          <div class="intro">
            <div class="intro__content">
              <div class="intro__title">
                <span>Empowering </span>
                <div>Data Integrity with Blockchain Audits</div>
              </div>
              <div class="intro__subtitle">
              Experience a seamless and secure transition from Web 2 to Web 3.{" "}
              </div>
              {/* <!-- <div class="intro__description">For as low as <span>$0.95</span> per user account</div> --> */}
              <div className="row m-0">
              <div class="col-12 col-xl-3 col-lg-12 col-md-12 intro__buttons intro__buttons--left">
                <a
                  href="index.html"
                  class="btn btn--blue-bg btn--play modal-toggle"
                  data-openpopup="animation"
                  onClick={(e) => {
                    PlayVideo(e);
                  }}
                >
                  Watch Demo
                </a>
                {/* <!-- <a href="index.html" class="btn btn--red-bg">START NOW</a> --> */}
              </div>
              <div class="col-12 col-xl-9 col-lg-12 col-md-12 intro__buttons intro__buttons--left">
                <a
                  href="index.html"
                  class="btn btn--blue-bg modal-toggle"
                  data-openpopup="animation"
                  onClick={(e) => {
                    handleClick(e, "cta", "#cta");
                  }}
                >
                  Contact Us For A Demo
                </a>
                {/* <!-- <a href="index.html" class="btn btn--red-bg">START NOW</a> --> */}
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="intro-animation">
          <img src={Option} alt="" title="" />
        </div>
      </section>
      <section>
        <section class="section" id="about">
          <div class="section__content section__content--fluid-width section__content--padding">
            <h2 class="section__title section__title--centered">About</h2>
            <div class="section__description section__description--centered">
              Audit Block is an innovative Blockchain-based Audit Trail
              application designed to revolutionize data tracking and audit
              processes.
            </div>
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div class="carousel-inner" style={{ borderRadius: 10 }}>
                <div class="carousel-item active">
                  <img src={SSIMG2} class="d-block w-100" alt="..." />
                </div>
                <div class="carousel-item">
                  <img src={SSIMG1} class="d-block w-100" alt="..." />
                </div>
                <div class="carousel-item">
                  <img src={SSIMG3} class="d-block w-100" alt="..." />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
                style={{justifyContent: 'flex-start', marginRight: 2}}
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
                style={{justifyContent: 'end', marginRight: 2}}
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
            <div class="grid grid--5col">
              <div class="slideshow-container">
                <div class="mySlides fade">
                  <img
                    src="https://www.w3schools.com/howto/img_snow_wide.jpg"
                    width="100%"
                  />
                  <div class="text">Caption Text</div>
                </div>

                <div class="mySlides fade">
                  <img
                    src="https://www.w3schools.com/howto/img_mountains_wide.jpg"
                    width="100%"
                  />
                  <div class="text">Caption Two</div>
                </div>

                <div class="mySlides fade">
                  <img
                    src="https://www.w3schools.com/howto/img_nature_wide.jpg"
                    width="100%"
                  />
                  <div class="text ">Caption Three</div>
                </div>

                {/* <!-- <a class="prev" onclick="plusSlides(-1)">&#10094;</a>
					<a class="next" onclick="plusSlides(1)">&#10095;</a> --> */}
              </div>
              {/* <div style={{textAlign: 'center', marginTop: 20}}>
					<span class="dot" onclick="currentSlide(1)"></span>
					<span class="dot" onclick="currentSlide(2)"></span>
					<span class="dot" onclick="currentSlide(3)"></span>
				</div> */}
              <br />
            </div>
          </div>
        </section>
        <section class="section section--testimonials1" id="benefits">
          <div class="section__content section__content--full-width section__content--padding">
            <h2 class="section__title section__title--centered">Benefits</h2>
            {/* <div class="testimonials1 swiper-container-horizontal">
				<div class="testimonials1__content swiper-wrapper"
				 style={{transform: `translate3d('0px', '0px', '0px')`, transitionDuration: '0ms' }}>
					<div class="testimonials1__slide swiper-slide swiper-slide-active">
                    <div class="grid__icon mb-4"><svg width="68" height="86" viewBox="0 0 68 86" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.0466 12.5992L2.12388 20.2217C2.56528 83.4424 34.5466 84.2201 34.5466 84.2201C34.5466 84.2201 66.5143 82.9959 66.0729 19.7752L34.0466 12.5992Z" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M55.4265 7.02373L33.9584 2.21681L12.5977 7.32275" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M20.5887 47.9117L32.1426 58.0612L48.5378 37.7284" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>

</div>
						<div class="testimonials1__source">Immutability and Tamper-proof Data</div>
						<div class="testimonials1__text">
							<p>Audit Block secures your data by logging it on the Blockchain, creating an immutable
								record that cannot be altered or tampered with, ensuring data integrity.</p>
						</div>

					</div>
					<div class="testimonials1__slide swiper-slide swiper-slide-next">
                    <div class="grid__icon mb-4"><svg width="68" height="71" viewBox="0 0 68 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.4956 21.4764C36.5306 26.1616 33.4576 29.973 29.6306 29.998C25.807 30.023 22.6758 26.252 22.6408 21.5668C22.6058 16.8817 25.6804 13.0702 29.5041 13.0453C33.3311 13.0203 36.4606 16.7912 36.4956 21.4764Z" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.571 27.4081C34.571 27.4081 39.4994 29.9933 41.4204 31.4955C41.8758 31.8547 42.2632 32.2804 42.576 32.7723C43.1574 33.6742 43.4342 34.7426 43.4419 35.7965L43.4876 42.1033C39.8281 45.3556 35.0704 47.4132 29.7649 47.4488C24.4211 47.4847 19.6843 45.5239 15.9677 42.2882L15.922 35.9814C15.9143 34.9275 16.1756 33.8554 16.7438 32.9459C17.051 32.4498 17.4306 32.0189 17.8825 31.6536C19.7798 30.1258 24.6702 27.4746 24.6702 27.4746" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M57.0919 30.9969C56.9804 15.0284 44.6032 2.16532 29.446 2.27114C14.2889 2.37697 2.09078 15.4116 2.20228 31.3802C2.31389 47.3665 14.6928 60.2296 29.8499 60.1238C45.0071 60.018 57.2035 46.9832 57.0919 30.9969Z" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M55.2659 52.4498L65.3576 62.9393C66.7856 64.413 66.8025 66.839 65.3954 68.3499C63.9864 69.8434 61.6894 69.8594 60.2597 68.3858L50.1714 57.8963" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M41.2539 63.0447C50.014 59.6562 56.9265 52.2528 60.0289 42.9846" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


</div>
						<div class="testimonials1__source">Transparency and Traceability</div>
						<div class="testimonials1__text">
							<p>With Audit Block, you gain complete transparency and traceability of your data
								transactions. Every change, update, or access is recorded on the Blockchain, allowing
								you to track the history of data effortlessly.</p>
						</div>

					</div>
					<div class="testimonials1__slide swiper-slide othersection">
                    <div class="grid__icon mb-4"><svg width="68" height="83" viewBox="0 0 68 83" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.59 39.8528L17.3906 40.0427" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M44.5366 32.6807L17.3389 32.8706" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M38.98 10.1368L22.5751 10.2513C21.8637 10.2563 21.2883 10.8114 21.2929 11.4726L21.3395 18.1407C21.3493 19.5552 22.5781 20.7039 24.0845 20.6934L24.1253 20.6931C25.2584 20.6852 26.2275 19.9986 26.6637 19.0036C27.3412 17.4741 28.9449 16.3791 30.8226 16.366C32.6982 16.3529 34.3171 17.4254 35.0159 18.9453C35.464 19.9341 36.4426 20.6071 37.5777 20.5992L37.6185 20.5989C39.1229 20.5884 40.3374 19.4226 40.3276 18.0082L40.281 11.3401C40.2764 10.6788 39.6933 10.1318 38.98 10.1368Z" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M59.5396 15.438L65.5426 15.3961L65.9114 68.221C65.9609 75.3169 59.9224 81.0984 52.4236 81.1507L7.46973 81.4646L7.43004 75.7806" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.9136 10.2912L2.05587 10.3949L2.5127 75.8254L47.7001 75.5099C49.7818 75.4954 51.4539 73.8855 51.4402 71.92L51.409 67.4564L56.1361 67.4234C58.2178 67.4088 59.8919 65.7989 59.8781 63.8334L59.5022 9.99384L44.6445 10.0976" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M44.6386 47.1405L44.6724 51.9844L17.4746 52.1743L17.4408 47.3304" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M57.7141 6.49806L53.0004 2.08689L48.3096 6.56372" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</div>
						<div class="testimonials1__source">Enhanced Compliance</div>
						<div class="testimonials1__text">
							<p>Audit Block helps you meet regulatory requirements with ease. By providing an auditable
								and transparent data trail, it simplifies compliance processes and minimizes the risk of
								non-compliance.</p>
						</div>

					</div>
					<div class="testimonials1__slide swiper-slide">
                    <div class="grid__icon mb-4"><svg width="68" height="63" viewBox="0 0 68 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.3193 23.8831L48.18 13.6105" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M52.186 23.8324L48.1794 13.6143" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M45.7871 19.9595L50.6619 19.9254" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M53.2351 31.4554L53.3066 41.6997" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M53.2191 31.4596L55.5085 31.4436C56.9224 31.4337 58.0748 32.5724 58.0847 33.9856C58.0946 35.4021 56.9582 36.5569 55.5443 36.5668L53.2549 36.5828" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M55.5447 36.5674C56.9585 36.5575 58.111 37.6962 58.1208 39.1094C58.1307 40.5259 56.9943 41.6807 55.5804 41.6906L53.291 41.7065" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M66.387 57.3488L66.3947 58.4566C66.4045 59.8516 65.2661 60.9946 63.8839 61.0043L63.0074 61.0104C61.6926 61.0196 60.64 59.9629 60.6309 58.6557L60.5925 53.1533C60.5833 51.8427 61.621 50.7715 62.9359 50.7623L63.8123 50.7562C65.1946 50.7466 66.3488 51.8735 66.3585 53.2719L66.3634 53.9743" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M19.0605 24.0637L29.1075 2.22515L39.257 23.9227L19.0605 24.0637Z" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.0205 42.3757L15.9838 31.3459L42.6626 31.1629L47.7794 42.1156L11.0205 42.3757Z" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.58195 49.9393L2.41211 61.4365L29.5369 61.2471L56.6282 61.058L51.2983 49.6307L7.58195 49.9393Z" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


</div>
						<div class="testimonials1__source">Streamlined Audit Processes</div>
						<div class="testimonials1__text">
							<p>Conducting the audit becomes more efficient and reliable with Audit Block. The ability to access an immutable audit trail ensures accuracy and reduces the time and effort required for audits.</p>
						</div>

					</div>
					<div class="testimonials1__slide swiper-slide">
                    <div class="grid__icon mb-4"><svg width="68" height="71" viewBox="0 0 68 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.023 46.4617C14.2053 43.3839 13.1517 39.8212 13.1251 36.0027" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M41.1966 30.3301C41.23 35.1132 38.1218 38.99 34.2495 39.017C30.3791 39.044 27.2153 35.211 27.1819 30.428C27.1486 25.6628 30.2585 21.768 34.1289 21.741C38.0011 21.7139 41.1633 25.565 41.1966 30.3301Z" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39.2419 36.3819C39.2419 36.3819 44.2275 39.0286 46.1699 40.5422C46.6322 40.9119 47.0233 41.353 47.3398 41.848C47.9294 42.7673 48.2053 43.8487 48.2128 44.9319L48.2577 51.36C44.5568 54.6711 39.7437 56.7645 34.3761 56.802C28.9673 56.8398 24.1757 54.8489 20.4197 51.5543L20.3748 45.1262C20.3673 44.043 20.6298 42.9579 21.2047 42.0305C21.516 41.5311 21.901 41.0845 22.3581 40.7084C24.2774 39.1678 29.2256 36.4518 29.2256 36.4518" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M55.3375 35.7083C55.3642 39.5269 54.3605 43.1039 52.5859 46.2068" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.68825 35.9973C7.6885 36.0328 7.68887 36.0861 7.68912 36.1217C7.72689 39.9935 6.3237 43.751 3.63638 46.5584L2.32199 47.9353L8.03956 57.6113L9.88982 57.1366C13.6725 56.1866 17.6557 56.7981 21.0435 58.7283C21.0811 58.7458 21.1189 58.7632 21.1583 58.7807C24.571 60.6929 27.1653 63.7833 28.2772 67.5055L28.8178 69.3312L40.119 69.2523L40.634 67.4192C41.6922 63.6818 44.236 60.5735 47.6162 58.6138C47.6322 58.596 47.6483 58.5958 47.6643 58.578C47.6838 58.5601 47.6999 58.5599 47.7159 58.542C51.0872 56.5824 55.0668 55.8976 58.8713 56.7946L60.7298 57.2434L66.3117 47.4886L64.98 46.1301C62.2411 43.3429 60.7997 39.5875 60.7815 35.6977C60.7812 35.6622 60.781 35.6266 60.7806 35.5734" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.126 35.8169C13.0452 24.2419 22.4295 14.8015 34.085 14.7201C45.7405 14.6388 55.2539 23.9472 55.3347 35.5222" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M60.7754 35.5438C60.775 35.4899 60.7747 35.4538 60.7744 35.3999C60.7347 31.5371 62.1382 27.7722 64.8261 24.9684L66.1413 23.5936L60.4216 13.9131L58.5735 14.3931C54.7922 15.3358 50.8081 14.7348 47.4211 12.8C47.3812 12.7823 47.3449 12.7646 47.305 12.7469C43.8928 10.8302 41.2994 7.73985 40.1875 4.01033L39.6464 2.19944L28.3464 2.27834L27.8289 4.11469C26.769 7.8414 24.2282 10.9676 20.8484 12.9137C20.8322 12.9318 20.8141 12.9318 20.7979 12.9499C20.7818 12.968 20.7637 12.9681 20.7475 12.9862C17.3769 14.9502 13.3965 15.6428 9.59136 14.735L7.73312 14.2809L2.15096 24.0404L3.48342 25.3967C6.2212 28.1805 7.66441 31.9257 7.68258 35.8246C7.68283 35.8605 7.68321 35.9143 7.68346 35.9502" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


</div>
						<div class="testimonials1__source">Customizability for Your Needs</div>
						<div class="testimonials1__text">
							<p>Audit Block offers both public and private versions that can be tailored to meet the
								specific requirements of your organization. Enjoy the flexibility of customization while
								benefiting from the underlying Blockchain technology.</p>
						</div>

					</div>
				</div> */}

            {/* <!-- <div class="testimonials1__pagination swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-bullets-dynamic" style="width: 90px;"><span class="swiper-pagination-bullet swiper-pagination-bullet-active swiper-pagination-bullet-active-main" tabindex="0" role="button" aria-label="Go to slide 1" style="left: 9px;"></span><span class="swiper-pagination-bullet swiper-pagination-bullet-active-next" tabindex="0" role="button" aria-label="Go to slide 2" style="left: 9px;"></span></div> --> */}

            {/* <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
			</div> */}
            <div className="row m-0 mt-5 justify-content-center benefitStyles">
              <div className="col-1"></div>
              <div className="col text-center">
                <div class="testimonials1__slide swiper-slide swiper-slide-active">
                  <div class="grid__icon mb-4">
                    <svg
                      width="68"
                      height="68"
                      viewBox="0 0 68 86"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M34.0466 12.5992L2.12388 20.2217C2.56528 83.4424 34.5466 84.2201 34.5466 84.2201C34.5466 84.2201 66.5143 82.9959 66.0729 19.7752L34.0466 12.5992Z"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M55.4265 7.02373L33.9584 2.21681L12.5977 7.32275"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M20.5887 47.9117L32.1426 58.0612L48.5378 37.7284"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  <div class="testimonials1__source">
                    Immutability and Tamper-proof Data
                  </div>
                  <div class="testimonials1__text">
                    <p>
                      Audit Block secures your data by logging it on the
                      Blockchain, creating an immutable record that cannot be
                      altered or tampered with, ensuring data integrity.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col text-center">
                <div class="testimonials1__slide swiper-slide swiper-slide-next">
                  <div class="grid__icon mb-4">
                    <svg
                      width="68"
                      height="68"
                      viewBox="0 0 68 71"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M36.4956 21.4764C36.5306 26.1616 33.4576 29.973 29.6306 29.998C25.807 30.023 22.6758 26.252 22.6408 21.5668C22.6058 16.8817 25.6804 13.0702 29.5041 13.0453C33.3311 13.0203 36.4606 16.7912 36.4956 21.4764Z"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M34.571 27.4081C34.571 27.4081 39.4994 29.9933 41.4204 31.4955C41.8758 31.8547 42.2632 32.2804 42.576 32.7723C43.1574 33.6742 43.4342 34.7426 43.4419 35.7965L43.4876 42.1033C39.8281 45.3556 35.0704 47.4132 29.7649 47.4488C24.4211 47.4847 19.6843 45.5239 15.9677 42.2882L15.922 35.9814C15.9143 34.9275 16.1756 33.8554 16.7438 32.9459C17.051 32.4498 17.4306 32.0189 17.8825 31.6536C19.7798 30.1258 24.6702 27.4746 24.6702 27.4746"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M57.0919 30.9969C56.9804 15.0284 44.6032 2.16532 29.446 2.27114C14.2889 2.37697 2.09078 15.4116 2.20228 31.3802C2.31389 47.3665 14.6928 60.2296 29.8499 60.1238C45.0071 60.018 57.2035 46.9832 57.0919 30.9969Z"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M55.2659 52.4498L65.3576 62.9393C66.7856 64.413 66.8025 66.839 65.3954 68.3499C63.9864 69.8434 61.6894 69.8594 60.2597 68.3858L50.1714 57.8963"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M41.2539 63.0447C50.014 59.6562 56.9265 52.2528 60.0289 42.9846"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="testimonials1__source">
                    Transparency and Traceability
                  </div>
                  <div class="testimonials1__text">
                    <p>
                      With Audit Block, you gain complete transparency and
                      traceability of your data transactions. Every change,
                      update, or access is recorded on the Blockchain, allowing
                      you to track the history of data effortlessly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col text-center">
                <div class="testimonials1__slide swiper-slide">
                  <div class="grid__icon mb-4">
                    <svg
                      width="68"
                      height="68"
                      viewBox="0 0 68 83"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M44.59 39.8528L17.3906 40.0427"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M44.5366 32.6807L17.3389 32.8706"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M38.98 10.1368L22.5751 10.2513C21.8637 10.2563 21.2883 10.8114 21.2929 11.4726L21.3395 18.1407C21.3493 19.5552 22.5781 20.7039 24.0845 20.6934L24.1253 20.6931C25.2584 20.6852 26.2275 19.9986 26.6637 19.0036C27.3412 17.4741 28.9449 16.3791 30.8226 16.366C32.6982 16.3529 34.3171 17.4254 35.0159 18.9453C35.464 19.9341 36.4426 20.6071 37.5777 20.5992L37.6185 20.5989C39.1229 20.5884 40.3374 19.4226 40.3276 18.0082L40.281 11.3401C40.2764 10.6788 39.6933 10.1318 38.98 10.1368Z"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M59.5396 15.438L65.5426 15.3961L65.9114 68.221C65.9609 75.3169 59.9224 81.0984 52.4236 81.1507L7.46973 81.4646L7.43004 75.7806"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.9136 10.2912L2.05587 10.3949L2.5127 75.8254L47.7001 75.5099C49.7818 75.4954 51.4539 73.8855 51.4402 71.92L51.409 67.4564L56.1361 67.4234C58.2178 67.4088 59.8919 65.7989 59.8781 63.8334L59.5022 9.99384L44.6445 10.0976"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M44.6386 47.1405L44.6724 51.9844L17.4746 52.1743L17.4408 47.3304"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M57.7141 6.49806L53.0004 2.08689L48.3096 6.56372"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="testimonials1__source">Enhanced Compliance</div>
                  <div class="testimonials1__text">
                    <p>
                      Audit Block helps you meet regulatory requirements with
                      ease. By providing an auditable and transparent data
                      trail, it simplifies compliance processes and minimizes
                      the risk of non-compliance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
            <div className="row m-0 mt-5 justify-content-center benefitStyles">
              <div className="col-2"></div>
              <div className="col text-center">
                <div class="testimonials1__slide swiper-slide">
                  <div class="grid__icon mb-4">
                    <svg
                      width="68"
                      height="68"
                      viewBox="0 0 68 63"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M44.3193 23.8831L48.18 13.6105"
                        stroke="white"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M52.186 23.8324L48.1794 13.6143"
                        stroke="white"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M45.7871 19.9595L50.6619 19.9254"
                        stroke="white"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M53.2351 31.4554L53.3066 41.6997"
                        stroke="white"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M53.2191 31.4596L55.5085 31.4436C56.9224 31.4337 58.0748 32.5724 58.0847 33.9856C58.0946 35.4021 56.9582 36.5569 55.5443 36.5668L53.2549 36.5828"
                        stroke="white"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M55.5447 36.5674C56.9585 36.5575 58.111 37.6962 58.1208 39.1094C58.1307 40.5259 56.9943 41.6807 55.5804 41.6906L53.291 41.7065"
                        stroke="white"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M66.387 57.3488L66.3947 58.4566C66.4045 59.8516 65.2661 60.9946 63.8839 61.0043L63.0074 61.0104C61.6926 61.0196 60.64 59.9629 60.6309 58.6557L60.5925 53.1533C60.5833 51.8427 61.621 50.7715 62.9359 50.7623L63.8123 50.7562C65.1946 50.7466 66.3488 51.8735 66.3585 53.2719L66.3634 53.9743"
                        stroke="white"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M19.0605 24.0637L29.1075 2.22515L39.257 23.9227L19.0605 24.0637Z"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.0205 42.3757L15.9838 31.3459L42.6626 31.1629L47.7794 42.1156L11.0205 42.3757Z"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.58195 49.9393L2.41211 61.4365L29.5369 61.2471L56.6282 61.058L51.2983 49.6307L7.58195 49.9393Z"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="testimonials1__source">
                    Streamlined Audit Processes
                  </div>
                  <div class="testimonials1__text">
                    <p>
                      Conducting the audit becomes more efficient and reliable
                      with Audit Block. The ability to access an immutable audit
                      trail ensures accuracy and reduces the time and effort
                      required for audits.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col text-center">
                <div class="testimonials1__slide swiper-slide">
                  <div class="grid__icon mb-4">
                    <svg
                      width="68"
                      height="68"
                      viewBox="0 0 68 71"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.023 46.4617C14.2053 43.3839 13.1517 39.8212 13.1251 36.0027"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M41.1966 30.3301C41.23 35.1132 38.1218 38.99 34.2495 39.017C30.3791 39.044 27.2153 35.211 27.1819 30.428C27.1486 25.6628 30.2585 21.768 34.1289 21.741C38.0011 21.7139 41.1633 25.565 41.1966 30.3301Z"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M39.2419 36.3819C39.2419 36.3819 44.2275 39.0286 46.1699 40.5422C46.6322 40.9119 47.0233 41.353 47.3398 41.848C47.9294 42.7673 48.2053 43.8487 48.2128 44.9319L48.2577 51.36C44.5568 54.6711 39.7437 56.7645 34.3761 56.802C28.9673 56.8398 24.1757 54.8489 20.4197 51.5543L20.3748 45.1262C20.3673 44.043 20.6298 42.9579 21.2047 42.0305C21.516 41.5311 21.901 41.0845 22.3581 40.7084C24.2774 39.1678 29.2256 36.4518 29.2256 36.4518"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M55.3375 35.7083C55.3642 39.5269 54.3605 43.1039 52.5859 46.2068"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.68825 35.9973C7.6885 36.0328 7.68887 36.0861 7.68912 36.1217C7.72689 39.9935 6.3237 43.751 3.63638 46.5584L2.32199 47.9353L8.03956 57.6113L9.88982 57.1366C13.6725 56.1866 17.6557 56.7981 21.0435 58.7283C21.0811 58.7458 21.1189 58.7632 21.1583 58.7807C24.571 60.6929 27.1653 63.7833 28.2772 67.5055L28.8178 69.3312L40.119 69.2523L40.634 67.4192C41.6922 63.6818 44.236 60.5735 47.6162 58.6138C47.6322 58.596 47.6483 58.5958 47.6643 58.578C47.6838 58.5601 47.6999 58.5599 47.7159 58.542C51.0872 56.5824 55.0668 55.8976 58.8713 56.7946L60.7298 57.2434L66.3117 47.4886L64.98 46.1301C62.2411 43.3429 60.7997 39.5875 60.7815 35.6977C60.7812 35.6622 60.781 35.6266 60.7806 35.5734"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.126 35.8169C13.0452 24.2419 22.4295 14.8015 34.085 14.7201C45.7405 14.6388 55.2539 23.9472 55.3347 35.5222"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M60.7754 35.5438C60.775 35.4899 60.7747 35.4538 60.7744 35.3999C60.7347 31.5371 62.1382 27.7722 64.8261 24.9684L66.1413 23.5936L60.4216 13.9131L58.5735 14.3931C54.7922 15.3358 50.8081 14.7348 47.4211 12.8C47.3812 12.7823 47.3449 12.7646 47.305 12.7469C43.8928 10.8302 41.2994 7.73985 40.1875 4.01033L39.6464 2.19944L28.3464 2.27834L27.8289 4.11469C26.769 7.8414 24.2282 10.9676 20.8484 12.9137C20.8322 12.9318 20.8141 12.9318 20.7979 12.9499C20.7818 12.968 20.7637 12.9681 20.7475 12.9862C17.3769 14.9502 13.3965 15.6428 9.59136 14.735L7.73312 14.2809L2.15096 24.0404L3.48342 25.3967C6.2212 28.1805 7.66441 31.9257 7.68258 35.8246C7.68283 35.8605 7.68321 35.9143 7.68346 35.9502"
                        stroke="white"
                        stroke-width="3"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="testimonials1__source">
                    Customizability for Your Needs
                  </div>
                  <div class="testimonials1__text">
                    <p>
                      Audit Block offers both public and private versions that
                      can be tailored to meet the specific requirements of your
                      organization. Enjoy the flexibility of customization while
                      benefiting from the underlying Blockchain technology.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-2"></div>
            </div>
            <div class="clear"></div>
          </div>
        </section>
      </section>

      <section class="section section--features" id="features">
        <div class="section__content section__content--fluid-width section__content--padding">
          <h2 class="section__title section__title--centered">Features</h2>
          {/* <!-- <div class="section__description section__description--centered">
				We believe we have created the most efficient SaaS landing page for your users. Landing page with
				features that will convince you to use it for your SaaS business.
			</div> --> */}

          <div class="grid grid--4col grid--features grid--noborder">
            <div class="grid__item">
              <div class="grid__icon">
                <svg
                  width="68"
                  height="61"
                  viewBox="0 0 68 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M65.9998 38.0337V40.7105C65.9998 41.7336 65.3158 42.6166 64.3348 42.8829C55.9985 45.1113 47.2264 45.1113 38.8902 42.8829C37.9091 42.6166 37.2266 41.7336 37.2266 40.7105V38.0337"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M65.9998 45.4614V48.1383C65.9998 49.1614 65.3158 50.0443 64.3348 50.3106C55.9985 52.525 47.2264 52.525 38.8902 50.3106C37.9091 50.0443 37.2266 49.1614 37.2266 48.1383V45.4614"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M65.9998 52.8754V55.5665C65.9998 56.5756 65.3158 57.4727 64.3348 57.7249C55.9985 59.9533 47.2264 59.9533 38.8902 57.7249C37.9091 57.4727 37.2266 56.5756 37.2266 55.5665V52.8754"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M58.0153 29.512C62.7483 30.1848 65.9998 31.5722 65.9998 33.17C65.9998 35.4124 59.5584 37.2344 51.6132 37.2344C43.6679 37.2344 37.2266 35.4124 37.2266 33.17C37.2266 31.5722 40.4781 30.1848 45.2096 29.512"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M52.3264 16.0852V5.71395C52.3264 3.66772 50.6684 1.99991 48.6221 1.99991H45.1436"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M45.1426 2.00008H6.2994C4.25318 2.00008 2.59375 3.66789 2.59375 5.71411V35.2161C2.59375 37.2624 4.25318 38.9302 6.2994 38.9302H33.7145"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.7753 38.9302L12.5146 44.6345H34.2467"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M35.3707 59.3923H3.76354C2.42088 59.3923 1.57297 57.9488 2.22327 56.7856L6.55119 48.9792H34.248"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M55.037 29.0915C55.037 30.9835 53.5051 32.5112 51.6159 32.5112C49.7252 32.5112 48.1934 30.9835 48.1934 29.0915C48.1934 27.1994 49.7252 25.6717 51.6159 25.6717C53.5051 25.6717 55.037 27.1994 55.037 29.0915Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M37.8827 19.519C37.8827 21.397 36.3508 22.9387 34.4602 22.9387C32.5709 22.9387 31.0391 21.397 31.0391 19.519C31.0391 17.6269 32.5709 16.0852 34.4602 16.0852C36.3508 16.0852 37.8827 17.6269 37.8827 19.519Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M22.3114 28.5448C22.3114 30.4229 20.7795 31.9646 18.8889 31.9646C16.9996 31.9646 15.4678 30.4229 15.4678 28.5448C15.4678 26.6528 16.9996 25.1111 18.8889 25.1111C20.7795 25.1111 22.3114 26.6528 22.3114 28.5448Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M18.6854 13.3666C18.6854 15.2587 17.1536 16.7861 15.2629 16.7861C13.3736 16.7861 11.8418 15.2587 11.8418 13.3666C11.8418 11.4746 13.3736 9.94687 15.2629 9.94687C17.1536 9.94687 18.6854 11.4746 18.6854 13.3666Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M51.6145 25.6719V19.5191H37.874"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.4941 14.4878L31.1947 18.4822"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M21.9111 26.7651L31.4555 21.1451"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h3 class="grid__title">
                <span>Blockchain-Powered </span> Data Logging
              </h3>
              <p class="grid__text">
                {" "}
                Audit Block leverages the power of Blockchain to log and store
                data securely. Benefit from the immutability and transparency of
                blockchain technology, ensuring that your data remains
                tamper-proof and verifiable.
              </p>
            </div>

            <div class="grid__item">
              <div class="grid__icon">
                <svg
                  width="68"
                  height="69"
                  viewBox="0 0 68 69"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.7988 38.0496L55.7146 19.2337"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.3896 29.9594L31.7221 38.2522"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.34766 40.6836L18.1227 29.9594"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M36.834 24.0866L55.2507 38.2551"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-dasharray="2.67 4"
                  />
                  <path
                    d="M7.08398 53.8403L31.5314 23.2377"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-dasharray="2.67 4"
                  />
                  <path
                    d="M7.08398 14.1685V53.8403H60.9173"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.0837 29.7537H18.417V24.0863H24.0837V29.7537Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M36.8337 24.0867H31.167V18.4193H36.8337V24.0867Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M36.8337 43.9224H31.167V38.255H36.8337V43.9224Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M60.9167 43.9224H55.25V38.255H60.9167V43.9224Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M60.9167 19.8359H55.25V14.1685H60.9167V19.8359Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h3 class="grid__title">
                Data History and <span>Audit Trail</span>
              </h3>
              <p class="grid__text">
                Audit Block Keeps a comprehensive record of data history and
                changes in a detailed audit trail, providing a complete and
                transparent view of data modifications, access, and
                transactions, allowing you to track the entire lifecycle of your
                data.
              </p>
            </div>

            <div class="grid__item">
              <div class="grid__icon">
                <svg
                  width="70"
                  height="71"
                  viewBox="0 0 70 71"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.1628 36.6478L15.6107 39.1996C14.6252 40.1857 13.0267 40.1857 12.0406 39.1996L9.48851 36.6478C8.50253 35.6615 8.50253 34.0628 9.48851 33.0766L12.0406 30.5242C13.0267 29.5385 14.6252 29.5385 15.6107 30.5242L18.1628 33.0766C19.1489 34.0628 19.1489 35.6615 18.1628 36.6478Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.6789 26.1305L26.1274 28.6829C25.1413 29.6692 23.543 29.6692 22.5569 28.6829L20.0048 26.1305C19.0192 25.1448 19.0192 23.5461 20.0048 22.5606L22.5569 20.0082C23.543 19.0208 25.1413 19.0208 26.1274 20.0082L28.6789 22.5606C29.6651 23.5461 29.6651 25.1448 28.6789 26.1305Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M39.1947 15.6141L36.6432 18.1665C35.6571 19.1525 34.0586 19.1525 33.0725 18.1665L30.5204 15.6141C29.5349 14.6282 29.5349 13.0296 30.5204 12.0436L33.0725 9.49121C34.0586 8.50525 35.6571 8.50525 36.6432 9.49121L39.1947 12.0436C40.1807 13.0296 40.1807 14.6282 39.1947 15.6141Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.821 47.3073L26.2689 49.8597C25.2828 50.8453 23.6843 50.8453 22.6982 49.8597L20.1467 47.3073C19.1607 46.3211 19.1607 44.7224 20.1467 43.7361L22.6982 41.1843C23.6843 40.198 25.2828 40.198 26.2689 41.1843L28.821 43.7361C29.8066 44.7224 29.8066 46.3211 28.821 47.3073Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M39.3386 36.7905L36.7865 39.3423C35.8004 40.3286 34.202 40.3286 33.2159 39.3423L30.6644 36.7905C29.6783 35.8043 29.6783 34.2057 30.6644 33.2195L33.2159 30.6676C34.202 29.6814 35.8004 29.6814 36.7865 30.6676L39.3386 33.2195C40.3241 34.2057 40.3241 35.8043 39.3386 36.7905Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M49.8524 26.2728L47.3003 28.8253C46.3142 29.8115 44.7157 29.8115 43.7302 28.8253L41.1781 26.2728C40.1919 25.2872 40.1919 23.6885 41.1781 22.7019L43.7302 20.1495C44.7157 19.1635 46.3142 19.1635 47.3003 20.1495L49.8524 22.7019C50.8384 23.6885 50.8384 25.2872 49.8524 26.2728Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M39.4802 57.9668L36.9281 60.5192C35.942 61.5053 34.3435 61.5053 33.358 60.5192L30.8059 57.9668C29.8199 56.9805 29.8199 55.3818 30.8059 54.3962L33.358 51.8438C34.3435 50.8575 35.942 50.8575 36.9281 51.8438L39.4802 54.3962C40.4662 55.3818 40.4662 56.9805 39.4802 57.9668Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M49.9958 47.4498L47.4437 50.0022C46.4577 50.9879 44.8597 50.9879 43.8737 50.0022L41.3216 47.4498C40.3355 46.4635 40.3355 44.865 41.3216 43.8793L43.8737 41.3269C44.8597 40.3406 46.4577 40.3406 47.4437 41.3269L49.9958 43.8793C50.9819 44.865 50.9819 46.4635 49.9958 47.4498Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M60.5102 36.9327L57.9581 39.4851C56.9725 40.4708 55.374 40.4708 54.3879 39.4851L51.8358 36.9327C50.8503 35.9464 50.8503 34.3478 51.8358 33.3615L54.3879 30.8097C55.374 29.8235 56.9725 29.8235 57.9581 30.8097L60.5102 33.3615C61.4961 34.3478 61.4961 35.9464 60.5102 36.9327Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.8877 31.8005L21.2809 27.4067"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27.4033 21.2849L31.7965 16.8904"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M38.0625 31.9431L42.4557 27.5493"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27.8037 42.2012L31.5754 38.4294"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M38.4619 52.8606L42.3026 49.0194"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M48.7178 42.6028L53.111 38.209"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M38.0889 16.7191L42.6244 21.2551"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27.5459 48.5835L32.0815 53.1196"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h3 class="grid__title">
                Integration <span>Capabilities </span>
              </h3>
              <p class="grid__text">
                Seamlessly integrate Audit Block with your existing systems and
                workflows. It provides integration capabilities that allow for
                smooth data flow and interoperability.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="section" id="usecases">
        <div class="section__content section__content--fluid-width section__content--padding">
          <h2 class="section__title section__title--centered">Use Cases</h2>
          <div class="grid grid--2col grid--support">
            <div class="grid__item grid__item--padding">
              <div class="grid__icon">
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.1662 17.2268V15.8624C24.1662 13.8435 22.4871 12.2055 20.388 12.2055C18.3189 12.2055 16.6396 13.8435 16.6396 15.8624V17.2268"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M26.772 28.5251H14.0508V17.2286H26.772V28.5251Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.5464 23.3745C22.5464 22.2258 21.5871 21.2922 20.388 21.2922C19.2189 21.2922 18.2598 22.2258 18.2598 23.3745"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.417 6.65056L5.66016 9.96577C5.66016 38.2775 20.417 38.7266 20.417 38.7266C20.417 38.7266 35.1735 38.2775 35.1735 9.96577L20.417 6.65056Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M30.3096 4.21973L20.4016 2.00011L10.5234 4.21973"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M46.1054 56.0854C41.2559 54.8318 36.2255 54.3322 31.2254 54.6098C27.9422 54.791 24.6289 55.3111 21.3456 55.1825C18.0323 55.051 14.2974 53.853 11.8877 51.6554L2.48983 43.0026C1.85728 42.4065 1.82699 41.4246 2.45953 40.8051C4.44754 38.7917 7.76088 38.7332 9.83925 40.6765L13.5744 44.0839"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M34.7542 46.4312L28.7826 47.6688C26.9128 48.0579 25.013 48.2568 23.0829 48.2568H19.8859C18.2272 48.2568 16.8701 46.9607 16.8701 45.3603C16.8701 43.7599 18.2274 42.4638 19.8559 42.4638H22.6909C25.3147 42.4638 27.8779 41.9459 30.2905 40.9453C31.9794 40.2402 33.6383 39.5087 35.4478 39.0757C40.3636 37.8819 47.33 39.1868 52.1252 44.8659"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M62.0006 41.0186H55.9869C55.2314 41.0186 54.5366 41.4231 54.2042 42.0681L45.0175 59.1541C44.3225 60.4499 45.2896 62.0007 46.8006 62.0007H62.0006"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h3 class="grid__title">Product Provenance and Authenticity</h3>
              <p class="grid__text" id="cnt1">
                {" "}
                A blockchain-based audit trail enables tracking the origin and
                journey of products from their source to the end consumer. Each
                transaction , such as the transfer of...
              </p>
              <div
                onClick={() => {
                  usecaseClick(1);
                }}
                id="mybtn1"
                class="grid__more"
              >
                Read more
              </div>
            </div>
            <div class="grid__item grid__item--padding">
              <div class="grid__icon">
                <svg
                  width="64"
                  height="72"
                  viewBox="0 0 64 72"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32.5002 8.76342L5 15.1081C5 69.2068 32.5002 70.0508 32.5002 70.0508C32.5002 70.0508 60 69.2068 60 15.1081L32.5002 8.76342Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M50.9218 6.24512L32.5057 1.99993L14.0898 6.24512"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M40.1749 28.7673C40.1749 34.0131 36.7287 38.2502 32.4715 38.2502C28.2481 38.2502 24.8018 34.0131 24.8018 28.7673C24.8018 23.4878 28.2481 19.2508 32.4715 19.2508C36.7287 19.2508 40.1749 23.4878 40.1749 28.7673Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.2363 51.9204V44.8755C17.2363 43.6678 17.5396 42.4604 18.18 41.454C18.5171 40.9173 18.9215 40.4138 19.4608 40.0112C21.5506 38.3339 27.0112 35.3818 27.0112 35.3818"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M37.9814 35.4107C37.9814 35.4107 43.4288 38.3404 45.5603 40.024C46.0678 40.4281 46.4739 40.9332 46.8122 41.4719C47.4889 42.4822 47.7595 43.6943 47.7595 44.8729V51.9443"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M30.7459 41.4887L28.9902 48.3771L32.5015 51.9387L35.9789 48.3771L34.2232 41.4887"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h3 class="grid__title">Quality Assurance and Compliance</h3>
              <p class="grid__text" id="cnt2">
                By recording critical information related to quality control,
                inspections, certifications, and compliance checks on the
                blockchain, stakeholders can easily track and verify...
              </p>
              <div
                onClick={() => {
                  usecaseClick(2);
                }}
                id="mybtn2"
                class="grid__more"
              >
                Read more
              </div>
            </div>
            <div class="grid__item grid__item--padding">
              <div class="grid__icon">
                <svg
                  width="64"
                  height="77"
                  viewBox="0 0 64 77"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M35.4463 56.8386V70.9298C35.4463 72.7413 33.9779 74.2217 32.1682 74.2217C30.3567 74.2217 28.8884 72.7413 28.8884 70.9298V56.8386C25.5494 56.2116 22.3584 54.6092 19.7736 52.0313C12.9283 45.1861 12.9283 34.0734 19.7736 27.2281C26.6188 20.3829 37.7176 20.3829 44.5611 27.2281C51.4064 34.0734 51.4064 45.1861 44.5611 52.0313C41.9781 54.6092 38.7871 56.2116 35.4463 56.8386Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.8887 56.8386H31.7296"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M38.401 8.20071C38.401 11.632 35.6245 14.4189 32.1984 14.4189C28.7723 14.4189 25.9941 11.632 25.9941 8.20071C25.9941 4.78679 28.7723 1.99999 32.1984 1.99999C35.6245 1.99999 38.401 4.78679 38.401 8.20071Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M32.197 11.0403C30.6329 11.0403 29.3613 9.76881 29.3613 8.20119C29.3613 6.651 30.6329 5.37945 32.197 5.37945C33.7594 5.37945 35.031 6.651 35.031 8.20119C35.031 9.76881 33.7594 11.0403 32.197 11.0403Z"
                    fill="white"
                  />
                  <path
                    d="M61.9996 21.2293C61.9996 24.6606 59.2232 27.4302 55.7971 27.4302C52.371 27.4302 49.5928 24.6606 49.5928 21.2293C49.5928 17.798 52.371 15.0286 55.7971 15.0286C59.2232 15.0286 61.9996 17.798 61.9996 21.2293Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M55.7966 24.0686C54.2325 24.0686 52.9609 22.797 52.9609 21.2293C52.9609 19.6617 54.2325 18.3903 55.7966 18.3903C57.359 18.3903 58.6304 19.6617 58.6304 21.2293C58.6304 22.797 57.359 24.0686 55.7966 24.0686Z"
                    fill="white"
                  />
                  <path
                    d="M37.6357 11.1968L50.6801 17.4324"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2 21.2293C2 24.6606 4.77818 27.4302 8.20429 27.4302C11.6304 27.4302 14.4086 24.6606 14.4086 21.2293C14.4086 17.798 11.6304 15.0286 8.20429 15.0286C4.77818 15.0286 2 17.798 2 21.2293Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.20483 24.0686C6.6407 24.0686 5.36914 22.797 5.36914 21.2293C5.36914 19.6617 6.6407 18.3903 8.20483 18.3903C9.76722 18.3903 11.0405 19.6617 11.0405 21.2293C11.0405 22.797 9.76722 24.0686 8.20483 24.0686Z"
                    fill="white"
                  />
                  <path
                    d="M26.3636 11.1968L13.3193 17.4324"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="32.0001"
                    cy="39.5013"
                    r="12.1876"
                    stroke="white"
                    stroke-width="3"
                  />
                </svg>
              </div>
              <h3 class="grid__title">Supply Chain Transparency</h3>
              <p class="grid__text" id="cnt3">
                A blockchain-based audit trail provides transparency by allowing
                all authorized participants in the supply chain to access and
                validate the recorded information. This transparency helps build
                trust among...
              </p>
              <div
                onClick={() => {
                  usecaseClick(3);
                }}
                id="mybtn3"
                class="grid__more"
              >
                Read more
              </div>
            </div>
          </div>
          <div class="clear"></div>

          <div class="grid grid--2col grid--support distance">
            <div class="grid__item grid__item--padding">
              <div class="grid__icon">
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M56.9609 21.187L60.36 23.9546C61.3987 24.8029 62 26.0685 62 27.4036V34.6494C62 36.8607 60.1947 38.6548 57.9674 38.6548"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M57.7766 28.4739L57.7935 30.8521V54.5226C57.7935 56.4419 56.2208 57.9995 54.2822 57.9995H53.1777"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M43.7451 45.8448V54.5231C43.7451 56.4424 45.3178 58 47.2563 58C49.1963 58 50.7689 56.4424 50.7689 54.5231V40.713"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M57.8277 13.0092C57.8277 16.8755 54.6669 20.0186 50.7688 20.0186C46.8707 20.0186 43.71 16.8755 43.71 13.0092C43.71 9.1429 46.8707 5.99979 50.7688 5.99979C54.6669 5.99979 57.8277 9.1429 57.8277 13.0092Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M7.0391 21.187L3.64 23.9546C2.60135 24.8029 2 26.0685 2 27.4036V34.6494C2 36.8607 3.80545 38.6548 6.03273 38.6548"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M20.2545 45.8443V54.5226C20.2545 56.4419 18.6817 57.9995 16.7432 57.9995H15.6387"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M6.22289 28.4744L6.20605 30.8526V54.5231C6.20605 56.4424 7.77886 58 9.7174 58C11.6573 58 13.23 56.4424 13.23 54.5231V40.713"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M20.2898 13.0092C20.2898 16.8755 17.1289 20.0186 13.2308 20.0186C9.33133 20.0186 6.17188 16.8755 6.17188 13.0092C6.17188 9.1429 9.33133 5.99979 13.2308 5.99979C17.1289 5.99979 20.2898 9.1429 20.2898 13.0092Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M39.5781 32.0066L41.3219 34.0371L43.3712 32.2987"
                    stroke="white"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.4154 34.0793L22.3353 32.3826L20.6279 34.4409"
                    stroke="white"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.5 28.5C23.4798 24.2304 27.4081 22 32 22C36.7419 22 40.6842 24.0357 41.5 28.5"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M40.5 37.5C38.9343 40.6848 35.321 42.5 31.5 42.5C27.6201 42.5 24.5363 40.7822 23 37.5"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M47.4088 32.0069C47.4088 40.4487 40.5098 47.2913 31.9987 47.2913C23.4891 47.2913 16.5898 40.4487 16.5898 32.0069C16.5898 23.565 23.4891 16.7086 31.9987 16.7086C40.5098 16.7086 47.4088 23.565 47.4088 32.0069Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h3 class="grid__title">Recall Management</h3>
              <p class="grid__text" id="cnt4">
                {" "}
                In the event of product recalls, a blockchain-based audit trail
                can expedite the identification and removal of affected products
                from the supply chain...
              </p>
              <div
                onClick={() => {
                  usecaseClick(4);
                }}
                id="mybtn4"
                class="grid__more"
              >
                Read more
              </div>
            </div>
            <div class="grid__item grid__item--padding">
              <div class="grid__icon">
                <svg
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.9119 25.0083L10.5322 14.7985L32.5634 2.00006L54.5946 14.7985L37.1725 25.0244"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M36.9794 51.0207L32.5634 53.6252L10.5322 40.6831V29.2588"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M54.5938 29.5784V37.104"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M32.5625 33.3012V44.9331"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M54.5937 14.7985L63.1257 19.7995L41.0945 32.7417L32.5625 27.7406"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.532 14.7985L2 19.7995L24.0327 32.7417L32.5632 27.7406"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M59.349 56.3574L65.4156 62.5248C66.1945 63.3237 66.1945 64.6019 65.4156 65.4008C64.6351 66.1997 63.3709 66.1997 62.592 65.4008L56.5255 59.2334C54.8153 60.4158 52.7503 61.1028 50.525 61.1028C44.6313 61.1028 39.8525 56.2455 39.8525 50.2537C39.8525 44.246 44.6313 39.3887 50.525 39.3887C56.4187 39.3887 61.1975 44.246 61.1975 50.2537C61.1975 52.5066 60.5143 54.6158 59.349 56.3574Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M56.5264 59.2332L57.7482 57.9869"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h3 class="grid__title">Dispute Resolution and Investigations</h3>
              <p class="grid__text" id="cnt5">
                The audit trail recorded on the blockchain serves as a reliable
                source of evidence in case of disputes or...
              </p>
              <div
                onClick={() => {
                  usecaseClick(5);
                }}
                id="mybtn5"
                class="grid__more"
              >
                Read more
              </div>
            </div>
            <div class="grid__item grid__item--padding">
              <div class="grid__icon">
                <svg
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M61.5742 53.5447L66.0006 57.4429V65.8533"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M49.1053 53.5445L44.6582 57.4414"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.41305 53.5533L2 57.4477V65.8611"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.8818 53.555L23.3084 57.4512"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27.7592 53.5493L23.3291 57.4451"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M40.2432 53.5485L44.6527 57.4457V65.855"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.3076 57.4508V66"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M61.4407 46.5052C61.4407 50.3968 58.698 53.5537 55.3382 53.5537C51.944 53.5537 49.2012 50.3968 49.2012 46.5052C49.2012 42.6136 51.944 39.4567 55.3382 39.4567C58.698 39.4567 61.4407 42.6136 61.4407 46.5052Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M40.1214 46.4983C40.1214 50.3889 37.389 53.5476 34.0078 53.5476C30.6266 53.5476 27.8945 50.3889 27.8945 46.4983C27.8945 42.6043 30.6266 39.4491 34.0078 39.4491C37.389 39.4491 40.1214 42.6043 40.1214 46.4983Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.7764 46.4991C18.7764 50.3919 16.0546 53.5488 12.6523 53.5488C9.28413 53.5488 6.52832 50.3919 6.52832 46.4991C6.52832 42.6062 9.28413 39.4526 12.6523 39.4526C16.0546 39.4526 18.7764 42.6062 18.7764 46.4991Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M29.1787 37.3933V13.969H38.8077V37.3933"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.02246 37.2608V24.7594H17.6488V37.5083"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M50.335 37.5176V24.7649H59.9661V37.2682"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.9873 15.9769C15.9873 17.7299 14.559 19.1494 12.8587 19.1494C11.0904 19.1494 9.69629 17.7299 9.69629 15.9769C9.69629 14.2205 11.0904 12.7975 12.8587 12.7975C14.559 12.7975 15.9873 14.2205 15.9873 15.9769Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M58.3036 15.9737C58.3036 17.7297 56.8987 19.1497 55.1512 19.1497C53.4379 19.1497 52.0332 17.7297 52.0332 15.9737C52.0332 14.2212 53.4379 12.7976 55.1512 12.7976C56.8987 12.7976 58.3036 14.2212 58.3036 15.9737Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M37.1443 5.1777C37.1443 6.93046 35.7447 8.35205 34.0038 8.35205C32.2629 8.35205 30.8633 6.93046 30.8633 5.1777C30.8633 3.42495 32.2629 1.99998 34.0038 1.99998C35.7447 1.99998 37.1443 3.42495 37.1443 5.1777Z"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M36.7197 6.77784L52.4309 14.386"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.5518 14.386L31.2713 6.77926"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h3 class="grid__title">Supplier Management and Compliance</h3>
              <p class="grid__text" id="cnt6">
                With a blockchain-based audit trail, organizations can maintain
                a transparent and auditable record of their...
              </p>
              <div
                onClick={() => {
                  usecaseClick(6);
                }}
                id="mybtn6"
                class="grid__more"
              >
                Read more
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </section>

      {/* <section>
    <Slider {...settings} >
    <div class="testimonials1__slide swiper-slide swiper-slide-active">
						<div class="testimonials1__source">Immutability and Tamper-proof Data</div>
						<div class="testimonials1__text">
							<p>" Audit Block secures your data by logging it on the Blockchain, creating an immutable
								record that cannot be altered or tampered with, ensuring data integrity."</p>
						</div>

					</div>
					<div class="testimonials1__slide swiper-slide swiper-slide-next">
						<div class="testimonials1__source">Transparency and Traceability</div>
						<div class="testimonials1__text">
							<p>" With Audit Block, you gain complete transparency and traceability of your data
								transactions. Every change, update, or access is recorded on the Blockchain, allowing
								you to track the history of data effortlessly. "</p>
						</div>

					</div>
					<div class="testimonials1__slide swiper-slide othersection">
						<div class="testimonials1__source">Enhanced Compliance</div>
						<div class="testimonials1__text">
							<p>" Audit Block helps you meet regulatory requirements with ease. By providing an auditable
								and transparent data trail, it simplifies compliance processes and minimizes the risk of
								non-compliance. "</p>
						</div>

					</div>
					<div class="testimonials1__slide swiper-slide">
						<div class="testimonials1__source">Streamlined Audit Processes</div>
						<div class="testimonials1__text">
							<p>"  Conducting the audit becomes more efficient and reliable with Audit Block. The ability to access an immutable audit trail ensures accuracy and reduces the time and effort required for audits. "</p>
						</div>

					</div>
					<div class="testimonials1__slide swiper-slide">
						<div class="testimonials1__source">Customizability for Your Needs</div>
						<div class="testimonials1__text">
							<p>" Audit Block offers both public and private versions that can be tailored to meet the
								specific requirements of your organization. Enjoy the flexibility of customization while
								benefiting from the underlying Blockchain technology. "</p>
						</div>

					</div>
    </Slider>
    </section> */}

      <section class="section" id="faq">
        <div class="section__content section__content--fluid-width section__content--padding">
          <div class="grid grid--5col grid--faq">
            <div class="grid__item grid__item--x2">
              <h3 class="grid__title">Frequently asked questions</h3>
              <p class="grid__text">
                Have a presale question about our product ? We would love to
                hear what your concern is.{" "}
              </p>
              {/* <ul class="grid__list">
						<li>Simple and Smart HTML code</li>
						<li>Works reintegrated in any part of the layout</li>
						<li>Reuse the elements from one design to another</li>
					</ul> */}
            </div>
            <div class="grid__item grid__item--x3">
              <div class="faq">
                <div class="faq__item">
                  <input class="faq__input" id="faq1" type="radio" name="faq" />
                  <label class="faq__label" for="faq1">
                    What is Audit Block?<span></span>
                  </label>
                  <div class="faq__content">
                    <p>
                      Audit Block is a blockchain-based Audit Trail application
                      that allows users to log and track data securely on the
                      Blockchain. It provides a tamper-proof and transparent
                      audit trail, going beyond private data stores and ensuring
                      data integrity for compliance, auditing, and various other
                      purposes.
                    </p>
                  </div>
                </div>
                <div class="faq__item">
                  <input class="faq__input" id="faq2" type="radio" name="faq" />
                  <label class="faq__label" for="faq2">
                    Can I customize Audit Block to fit my organization's
                    specific needs? <span></span>
                  </label>
                  <div class="faq__content">
                    <p>
                      Yes, Audit Block offers customization options to tailor
                      the application according to your organization's
                      requirements. Whether it's custom reporting formats,
                      integration with existing systems, or specific data
                      fields, our team can work with you to configure Audit
                      Block to align with your unique needs.
                    </p>
                  </div>
                </div>
                <div class="faq__item">
                  <input class="faq__input" id="faq3" type="radio" name="faq" />
                  <label class="faq__label" for="faq3">
                    What industries can benefit from using Audit Block?
                    <span></span>
                  </label>
                  <div class="faq__content">
                    <p>
                      Audit Block is versatile and can benefit organizations
                      across various industries. It can be particularly valuable
                      for industries such as finance, healthcare, manufacturing,
                      supply chain, human resources, and compliance-driven
                      sectors where data tracking, transparency, and
                      auditability are crucial.
                    </p>
                  </div>
                </div>
                <div class="faq__item">
                  <input class="faq__input" id="faq4" type="radio" name="faq" />
                  <label class="faq__label" for="faq4">
                    How user-friendly is Audit Block? Do I need technical
                    expertise to use it? <span></span>
                  </label>
                  <div class="faq__content">
                    <p>
                      Audit Block is designed with user-friendliness in mind.
                      Its intuitive interface makes it accessible to users of
                      all technical levels. You don't need extensive technical
                      expertise to use Audit Block, as it provides a
                      user-friendly experience with simple data entry, real-time
                      tracking, and easy access to reports and audit trails.
                    </p>
                  </div>
                </div>
                <div class="faq__item">
                  <input class="faq__input" id="faq5" type="radio" name="faq" />
                  <label class="faq__label" for="faq5">
                    Can Audit Block integrate with our existing systems?
                    <span></span>
                  </label>
                  <div class="faq__content">
                    <p>
                      Yes, Audit Block offers integration capabilities to
                      seamlessly connect with your existing systems. Whether
                      it's integrating with enterprise applications, databases,
                      or APIs, Audit Block can be configured to ensure smooth
                      data flow and interoperability.
                    </p>
                  </div>
                </div>
                <div class="faq__item">
                  <input class="faq__input" id="faq6" type="radio" name="faq" />
                  <label class="faq__label" for="faq6">
                    How does Audit Block support compliance requirements?
                    <span></span>
                  </label>
                  <div class="faq__content">
                    <p>
                      Audit Block supports compliance requirements by providing
                      a comprehensive and transparent audit trail. The
                      application helps you meet industry standards and
                      regulatory obligations by maintaining an auditable history
                      of data changes, access, and transactions. This enables
                      you to demonstrate compliance during audits and regulatory
                      inspections.
                    </p>
                  </div>
                </div>
                <div class="faq__item">
                  <input class="faq__input" id="faq7" type="radio" name="faq" />
                  <label class="faq__label" for="faq7">
                    Is there customer support available for Audit Block?
                    <span></span>
                  </label>
                  <div class="faq__content">
                    <p>
                      Yes, we provide customer support to assist you with any
                      queries or technical issues related to Audit Block. Our
                      dedicated support team is available to address your
                      concerns and provide guidance whenever you need
                      assistance.
                    </p>
                  </div>
                </div>
                <div class="faq__item">
                  <input class="faq__input" id="faq8" type="radio" name="faq" />
                  <label class="faq__label" for="faq8">
                    How can I get started with Audit Block for my organization?{" "}
                    <span></span>
                  </label>
                  <div class="faq__content">
                    <p>
                      To get started with Audit Block, simply reach out to us
                      through our contact page or by calling our sales team. We
                      will guide you through the process, understand your
                      specific requirements, and provide you with the necessary
                      information to onboard your organization onto the Audit
                      Block platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" id="sakha">
      <div class="section__content section__content--fluid-width section__content--padding">
        <div className="container">
        <h2 class="section__title section__title--centered">About Sakha</h2>
        <div class="section__description section__description--centered">
        AuditBlock is brought to you by Sakha, a leading custom blockchain development company dedicated to revolutionizing the way businesses operate in the digital age. With a team of skilled blockchain experts and developers, Sakha empowers organizations across industries to harness the transformative power of blockchain technology.
            </div>
        <div class="info-wrap">
          <div class="row">
            <div class="col-lg-4 col-md-6 info">
              <i class="fa fa-map-marker"></i>
              <h4>Location:</h4>
              <p>988, 13th Cross Rd, Siddanna Layout, Banashankari Stage II, Banashankari, Bengaluru, Karnataka 560070</p>
            </div>

            <div class="col-lg-4 col-md-6 info mt-4 mt-lg-0">
              <i class="fa fa-link"></i>
              <h4>Company Link:</h4>
              <p><a href="https://www.sakhaglobal.com/" style={{wordBreak: 'break-all'}}>https://www.sakhaglobal.com/</a></p>
            </div>

            <div class="col-lg-4 col-md-6 info mt-4 mt-lg-0">
              <i class="fa fa-envelope-open"></i>
              <h4>Email:</h4>
              <p>enquiry@auditblock.in</p>
            </div>

          </div>
        </div>
        </div>
        </div>
      </section>
      <section class="section section--cta" id="cta">
        <div class="section__content section__content--fluid-width section__content--padding">
          {/* <div class="grid grid--2col grid--cta">
				<div class="grid__item">

					<h3 class="grid__title grid__title--cta">Start <span>growing</span> your bussiness.</h3>
					<p class="grid__text">Your issue is our main priority. Our 24/7 support team is here to help you and
						make sure our product is up to date. Have a presale question about our products and features? Or
						looking for a refund? We would love to hear what you concern is. Online awards and publications.
					</p>
				</div>
				<div class="grid__item grid__item--centering">
					<a href="#" class="grid__more">Contact Us</a>
				</div>
			</div> */}
          <div class="clear"></div>
        </div>
        <svg
          class="svg-cta-bottom"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <defs>
            <linearGradient
              id="linear"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
              spreadMethod="pad"
            >
              <stop offset="0%" stop-color="#030c2c" />
              <stop offset="100%" stop-color="#182964" />
            </linearGradient>
          </defs>
          <path
            d="M0,80 L10,90 Q21,101 30,90 L100,0 100,100 0,100 Z"
            fill="url(#linear)"
          />
        </svg>
      </section>

      <footer class="footer" id="footer">
        <div class="footer__content footer__content--fluid-width footer__content--svg">
          <div
            class="grid grid--5col footerStyle"
            style={{ position: "relative" }}
          >
            <div class="grid__item grid__item--x2">
              <h3 class="grid__title grid__title--footer-logo">
                <img src={Logo} width={"60px"} />{" "}
                <span className="headerLogo">AUDIT BLOCK</span>
              </h3>
              <p
                class="grid__text grid__text--copyright"
                style={{ fontSize: "12px" }}
              >
                Copyright &copy; 2023 AUDIT BLOCK. <br />
                All Rights Reserved.{" "}
              </p>
              {/* <ul class="grid__list grid__list--sicons">
                <li>
                  <a href="#">
                    <img
                      src="images/social/white/twitter.png"
                      alt=""
                      title=""
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="images/social/white/facebook.png"
                      alt=""
                      title=""
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="images/social/white/linkedin.png"
                      alt=""
                      title=""
                    />
                  </a>
                </li>
              </ul> */}
            </div>
            <div class="grid__item companyDetails">
              <h3 class="grid__title grid__title--footer">Company</h3>
              <ul class="grid__list grid__list--fmenu">
                <li>
                  <a
                    href="#about"
                    onClick={(e) => {
                      handleClick(e, "sakha", "#sakha", "lastaboutus");
                    }}
                  >
                    About Us
                  </a>
                </li>
                {/* <li><a href="#contact">Contact Us</a></li> */}
              </ul>
            </div>
            <div
              class="grid__item grid__item--x2 grid__item--form"
              id="ContactForm"
            >
              <h3 class="grid__title grid__title--footer contactus">
                Contact us
              </h3>
              <div class="grid__form form">
                <svg
                  class="svg-form-left"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                >
                  <polygon fill="#698bd6" points="0,0 100,0 0,100"></polygon>
                </svg>
                <form class="form__container" id="ContactForm">
                  <div class="form__row">
                    <label class="form__label" for="namec">
                      Name
                    </label>
                    <input
                      name="namec"
                      id="namec"
                      class="form__input"
                      value={name}
                      type="text"
                      onChange={(e) => {
                        nameOnchange(e);
                      }}
                    />
                    <span class="form__row-border"></span>
                  </div>
                  <div class="form__row">
                    <label class="form__label">Email</label>
                    <input
                      name="emailc"
                      class="form__input"
                      type="text"
                      value={email}
                      onChange={(e) => {
                        emailOnchange(e);
                      }}
                    />
                    <span class="form__row-border"></span>
                  </div>
                  <div class="form__row">
                    <label class="form__label">Message</label>
                    <textarea
                      name="message"
                      class="form__textarea"
                      value={message}
                      rows="3"
                      cols="3"
                      onChange={(e) => {
                        messageOnchange(e);
                      }}
                    ></textarea>
                    {/* <span class="form__row-border"></span> */}
                  </div>

                  <input
                    type="button"
                    name="submit"
                    class="form__submit btn btn--red-bg"
                    id="submitc"
                    value="Contact us for a demo"
                    onClick={() => {
                      onSubmitValue();
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default LandingPage;
