import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import EmitEvents from "../../components/EmitEvents";
import axiosClient from '../../ApiClient';
import Loader from '../../components/common/Loader';
import { useNavigate } from "react-router";
import { notifyFailure, notifySuccess } from '../../components/helpers/utils';
import messages from '../../components/common/messages'
import API_ENDPOINTS from '../../components/common/api';

const EmittingEvents = () => {
  const contractAddress = window.localStorage.getItem('address');
  const [events, setEvents] = useState();
  const [argsDisplay, setArgsDisplay] = useState();
  const [loading, setLoading]= useState(false);
  const [timer, setTimer] = useState(false);
  const [id, setId] = useState();
  const [checkbox, setcheckbox]= useState(1);
  const [type, setType] = useState()
  const contractId = window.localStorage.getItem('contractId')
  const API_KEY = window.localStorage.getItem('APIKEY')
  const navigate = useNavigate();

  useEffect(() => {
    axiosClient.post(API_ENDPOINTS.fetchEvents, {
        "smartContractId": contractId
    }).then((res) => {
        console.log('resdata', res.data.data);
        setEvents(res.data.data);
    })
}, []);

const handledot = (e) => {
  const forbiddenKeys = ['.', '-', '+'];
  const isForbiddenKey = forbiddenKeys.includes(e.key);
  const isNumberInput = e.target.type === 'number';
  const isTextInput = e.target.type === 'text';
  const isFirstCharacter = e.target.value.length === 0;
  const isNumericKey = isFirstCharacter && /^\d$/.test(e.key);

  if (isForbiddenKey && isNumberInput) {
    e.preventDefault();
  } else if ((e.key === 'e' || e.key === 'E') && isNumberInput) {
    e.preventDefault();
  } else if (isTextInput && isNumericKey) {
    e.preventDefault();
  }
};

function validateData(data) {
  console.log(data, 'data');
  let formValidated = false;
  let argument = data.eventpayload.arguments
  for(let i = 0; i < argument.length; i++) {
    if (argument[i].argValue === "") {
      notifyFailure('Please fill all required fields');
      return formValidated;
    }
    if (argsDisplay && argsDisplay[0] && argsDisplay[0].argTypes && argsDisplay[0].argTypes[i] === 'string') {
      if (Number(argument[i].argValue)) {
        notifyFailure(`Please enter string in input field ${i + 1}`);
        return formValidated;
      } else {
        try {
          if (JSON.parse(argument[i].argValue)) {
            notifyFailure(`Please enter String in input field ${i + 1}`);
            return formValidated;
          }
      } catch (e) {
      }
      }
      
    }
    if ((argsDisplay && argsDisplay[0] && argsDisplay[0].argTypes && argsDisplay[0].argTypes[i] === 'int') && typeof(argument[i].argValue) === 'string') {
      notifyFailure(`Please enter number in input field ${i + 1}`);
      return formValidated;
    }
    if (argsDisplay && argsDisplay[0] && argsDisplay[0].argTypes && argsDisplay[0].argTypes[i] === 'JSON') {
      try {
        if (Number(argument[i].argValue)) {
          notifyFailure(`Please enter ${argsDisplay[0].argTypes[i]} in input field ${i + 1}`);
          return formValidated;
        }
        if(typeof(JSON.parse( argument[i].argValue)) !== 'object'){
          notifyFailure(`Please enter ${argsDisplay[0].argTypes[i]} in input field ${i + 1}`);
          return formValidated;
        }
        JSON.parse(argument[i].argValue);
    } catch (e) {
      notifyFailure(`Please enter JSON in input field ${i + 1}`);
      return formValidated;
    }
    }
    if (argument[i].argValue < 0) {
      notifyFailure('Amount cannot be negative');
      return formValidated;
    }
  }
    formValidated = true;
    return formValidated;
  }

const onChange = async (e, arglist) => {
  const preValue = { ...arglist}; 
  delete preValue.values.eventpayload.arguments;
  preValue.values.eventpayload.arguments = [];
  setId(e.id);
  
  const res = await axiosClient.post(API_ENDPOINTS.fetchArgs, {
      "id": e.id
  });
   
    setArgsDisplay(res.data.data);

    {res.data.data && res.data.data[0].name && res.data.data[0].name.length > 0 && res.data.data[0].name.forEach((elName) => ( 
      arglist.values.eventpayload.arguments.push({ arg: elName, argValue: "" })
    ))}

};
// console.log(checkbox, 'checkbox')
 const onSubmit=(data)=>{
  setLoading(true);
  const preValue = { ...data}; 
  const eventname= preValue.eventpayload.eventname.name;
  delete preValue.eventpayload.eventname;
  preValue.eventpayload.eventname = eventname;
  preValue.eventpayload.id = id;
  preValue.eventpayload.choice = checkbox
  console.log({...preValue}, 'success');
  setTimeout(() => {
    setTimer(true);
  }, 10000);
  axiosClient.post(`${API_ENDPOINTS.postEvents}`,{...preValue}).then((res) => {
      setLoading(false);
      notifySuccess(messages.CONTRACT_EMITTED_SUCCESS);
      navigate("/viewtab/events");
      window.localStorage.setItem('tabName', 2);
})
.catch(()=>{
  setLoading(false);
  notifyFailure(messages.EMITT_ARGUMENTS_FAIL);
});
}


 const generateUnique = `0X${Math.floor(100000 + Math.random() * 900000)}` ;
//  console.log(generateUnique, 'generateUnique');
  return (
    <div> {loading ? (<>
            <Loader timer={timer} />
          </>) : 
        <div>
             <Formik
              render={formProps => <EmitEvents  formProps={formProps} events={events} onChange={onChange} argsDisplay={argsDisplay} handledot={handledot} setcheckbox={setcheckbox} setType={setType} validateData={validateData} />}
              initialValues={{eventpayload: {txnId : generateUnique, eventname: "", arguments: [] }}}
              onSubmit={onSubmit}
            />
            
        </div>
         }
    </div>
)
}

export default EmittingEvents;
