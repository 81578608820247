import React, { useState } from 'react'
import style from './apiKeyGen.module.css'
import copy from '../../asset/images/copy.png'
import cn from 'classnames'
import CopyToClipboard from 'react-copy-to-clipboard'

const ApiKeyGenrator = (props) => {
    const [copied, setCopied] = useState();
    const copyApiKey = () => {
       if(props && props.apiKey) {
        // navigator.clipboard.writeText(props && props.apiKey);
        setCopied('Copied!');
       }
    }
  //   const genrateApiKey = () => {
  //       setCopied();
  //       setDisabled(true);
  //       let d = new Date().getTime();
  //       if (window.performance && typeof window.performance.now === "function") {
  //           d += performance.now();
  //         }

  //       let apiKey = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
  //           /[xy]/g,
  //           function (c) {
  //             var r = (d + Math.random() * 16) % 16 | 0;
  //             d = Math.floor(d / 16);
  //             return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  //           }
  //         );     
  //   const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	// // Generate a random string of 32 characters
	// let apiKey = "";
	// for (let i = 0; i < 32; i++) {
	// 	apiKey += chars.charAt(Math.floor(Math.random() * chars.length));
	// }

	// setApiKey(apiKey);
  //   }
  return (
      <div className='container'> 
        <div className='row m-0 mb-3 mt-3'>
            <div className={cn(style.box, "smartHeader d-flex align-items-center")}>API Keys</div>
        </div>
      <div className="row m-0 p-3 border border-primary">
        <div className={cn(style.apiKeyCont, 'd-flex align-items-center')}>
        {/* <div className={style.inputContWidth}><input className={cn(style.inputWidth, 'inputBox border text fs16 textShadow border-primary')} placeholder="Click on the button to generate a new API key ..." type="text" value={apiKey ? apiKey : ''} readOnly/></div> */}
        <div className={cn(style.inputContWidth, "d-flex align-items-center text fs16 textShadow")}>{props && props.apiKey ? props.apiKey : 'Click on the button to generate a new API key ...'}</div>
        <div className={cn(style.imgContWidth, 'ml-2')}>
        <CopyToClipboard text={props && props.apiKey} onCopy={copyApiKey} >
            <img className={props.apiKey ? cn(style.imgWidth, 'cursor-pointer') : cn(style.imgWidth, style.noCursor)} src={copy} alt="copy" />
         </CopyToClipboard>   
        </div>
        <div className={cn(style.copiedCont, 'ml-2')}>{copied}</div>
        <div className={props && props.apiKey ? cn(style.box, style.noCursor): cn(style.box)}><button type="button" disabled={props.disabled} onClick={props.handleApiKeyGenration} className={props && props.apiKey  ? cn(style.buttonWidth,style.noPointerEvent, "float-right createBtn") : cn(style.buttonWidth, "float-right createBtn cursor-pointer")}>Generate API Key</button></div>
        </div>
      </div>
        {/* <div className="textFont mt-2">
            <table className="table borderTable">
                <thead>
                    <tr>
                        <th scope="col-2">Name</th>
                        <th scope="col-2">Created</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='col-2'></td>
                        <td className='col-2'></td>
                    </tr>
                </tbody>
            </table>
            <div>
            <div className="d-flex justify-content-center textFont">No Records Found</div>
          </div>
        </div> */}
      </div>
  )
}

export default ApiKeyGenrator