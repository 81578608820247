
import axios from 'axios';
import { notifyFailure } from './components/helpers/utils';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = 'https://auditblock.in';

axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
  // 'x-access-token': window.localStorage.getItem('token')
};

axiosClient.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem("token");
  const apikey = window.localStorage.getItem("APIKEY");
  config.headers['x-access-token'] = token;
  config.headers['x-api-key'] = apikey;
  return config;
});

//All request will wait 2 seconds before timeout
// axiosClient.defaults.timeout = 2000;

axiosClient.interceptors.response.use(
  function (response) {
    // console.log('response', response);
    return response;
  },
  function (error) {
    console.log('response error', error);
    if (error.response.status === 401) {
      window.localStorage.clear();
      notifyFailure('Session expired! Please log in again');
      setTimeout(() => {
        if(window.location.pathname !== '/'){
          window.location.replace('/');
        }
        
      }, 4000);
    }
    return Promise.reject(error);
  }
);

axiosClient.defaults.withCredentials = false;

export default axiosClient;

