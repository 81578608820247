import React, { useEffect, useState } from "react";
import EventsComponent from "../../components/Events";
import axiosClient from '../../ApiClient';
import API_ENDPOINTS from '../../components/common/api';
import Loader from '../../components/common/Loader';
import { Formik } from "formik";
import { notifyFailure } from "../../components/helpers/utils";
import moment from 'moment';

const Events = () => {
    const seletedScreen = window.localStorage.getItem('SelectedDashboard');
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(false);
    const [filterData, setFilterData] = useState();
    const [headerExport, setHeaderExport] = useState();
    const [dataExport, setDataExport] = useState();
    const [eventsName, setEventsName] = useState([]);
    const [eventOption, setEventOption] = useState();
    const [todate, setTodate] = useState();
    const [fromdate, setFromdate] = useState();
    const [optionName, setoptionName] = useState()
    const [page, setPage] = useState(1);
    const [curPage, setCurPage] = useState(1);
    const [totalCount, setTotalCount] = useState();
    const itemsPerPage = 10;

    console.log('qwe', todate, fromdate);

    const [EventName, setEventName] = useState();
    const [arg, setArg] = useState()
    const [demo, setDemo] = useState([])
    const [hideManualCsv, setHideManualCsv] = useState(true);

    const contractAddress = window.localStorage.getItem('address');
    const contractId = window.localStorage.getItem('contractId')
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setTimer(true);
        }, 1000);
        onGiftCardExport();
        axiosClient.post(API_ENDPOINTS.emittedEvents, {
            "address": contractAddress
        },{params : {noOfItems : itemsPerPage,pageNo: page}}).then((res) => {
            console.log('resdata', res.data.result)
            if (res && res.data && res.data.totalCount) {
                setTotalCount(res && res.data && res.data.totalCount);
                setPage(Math.ceil((res && res.data && res.data.totalCount) / itemsPerPage))
            }
            setEvents(res.data.result);
            setLoading(false);
        }).catch(() => {
            setPage(0);
        });
    }, []);

    const onGiftCardExport = () => {
        axiosClient.post(API_ENDPOINTS.getEmittedGiftData, {
            "address": contractAddress
        }).then((res) => {
            console.log('res', res && res.data && res.data.result)
            exportHeader(res && res.data && res.data.result);
            handleExportCsv(res && res.data && res.data.result);
        });
    }

    const onChange = (e) => {
        setEventOption(e.name);
    }
    const changeTimeToMidNight = (date) => { 
        const newDateString = date.slice(0, 10) +' '+'00:00:00';
        return newDateString
    }
    const onChangeCheckBox = (e, optName) => {
        if (optName === 'Quarterly') {
            const currentDate = new Date();
            const startDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - 3,
                currentDate.getDate()
            );
            const efrom = Date.parse(currentDate)
            const eto = Date.parse(startDate)

            if (eto && efrom) {
                axiosClient.post(seletedScreen === '1' ? API_ENDPOINTS.getEmittedGiftData : `api/emittedevents?search=${eventOption}&fromdate=${eto}&todate=${efrom}`, {
                    "address": contractAddress
                }).then((res) => {
                    console.log('res', res && res.data && res.data.result)
                    exportHeader(res && res.data && res.data.result);
                    handleExportCsv(res && res.data && res.data.result);
                });
            }
        }
        if (optName === 'Half_yearly') {
            const currentDate = new Date();
            const startDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - 6,
                currentDate.getDate()
            );
            const efrom = Date.parse(currentDate)
            const eto = Date.parse(startDate)

            if (eto && efrom) {
                axiosClient.post(seletedScreen === '1' ? API_ENDPOINTS.getEmittedGiftData : `api/emittedevents?search=${eventOption}&fromdate=${eto}&todate=${efrom}`, {
                    "address": contractAddress
                }).then((res) => {
                    console.log('res', res && res.data && res.data.result)
                    exportHeader(res && res.data && res.data.result);
                    handleExportCsv(res && res.data && res.data.result);
                });
            }
        }
        if (optName === 'Yearly') {
            const currentDate = new Date();
            const startDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - 12,
                currentDate.getDate()
            );
            console.log(currentDate,  startDate, 'sss' )
            const efrom = Date.parse(currentDate)
            const eto = Date.parse(startDate)

            if (eto && efrom) {
                axiosClient.post(seletedScreen === '1' ? API_ENDPOINTS.getEmittedGiftData : `api/emittedevents?search=${eventOption}&fromdate=${eto}&todate=${efrom}`, {
                    "address": contractAddress
                }).then((res) => {
                    console.log('res', res && res.data && res.data.result)
                    exportHeader(res && res.data && res.data.result);
                    handleExportCsv(res && res.data && res.data.result);
                });
            }
        }
        if (optName === 'Manually') {
            setoptionName(optionName);
            if ((fromdate === undefined && todate === undefined) || (fromdate === '' && todate === '')) {
                setHideManualCsv(false); 
            }
        }
    }
    const validateData = (fromdate, toDate) => {
        let formValidated = false;
        let date1 = new Date(fromdate).getTime();
        let date2 = new Date(toDate).getTime();
      
        if (date1 > date2) {
            notifyFailure("Please select To date greater than From date!");
            return formValidated;
        };
        formValidated = true;
        return formValidated;
      };

    useEffect(() => {
    if(fromdate && todate) {
    let formvalidate= validateData(fromdate, todate);
    if(formvalidate){
        let date = new Date(`${changeTimeToMidNight(fromdate)}`);
        let fromdatemili = date.getTime();
        let datee = new Date(`${changeTimeToMidNight(todate)} `);
        let todatemili = datee.getTime();


        if (fromdatemili && todatemili) {
            axiosClient.post(seletedScreen === '1' ? API_ENDPOINTS.getEmittedGiftData : `api/emittedevents?search=${eventOption}&fromdate=${fromdatemili}&todate=${todatemili}`, {
                "address": contractAddress
            }).then((res) => {
                console.log('res', res && res.data && res.data.result)
                if((res && res.data && res.data.result && res.data.result.length > 0 ) && (res && res.data && res.data.success === '1')) {
                    setHideManualCsv(true);
                }
                if((res && res.data && res.data.success === '0')) {
                    setHideManualCsv(false);
                    notifyFailure(res && res.data && res.data.result && res.data.result);  
                }
                exportHeader(res && res.data && res.data.result);
                handleExportCsv(res && res.data && res.data.result);
            });
        }
    }
 }     
    }, [optionName === 'Manually', fromdate, todate])
    const exportHeader = (header) => {
        console.log('header', header);
        header.forEach((ele, index) => {
            console.log('ele', ele.returnValues);
            const allLength = (Object.values(ele.returnValues).length / 2);
            let genders = Object.keys(ele.returnValues);
            const arr = [];
            const arr2 = [];
            if(seletedScreen === '2') {
                genders.forEach((ele2, index2) => {
                    if (Number(index2) >= Number(allLength)) {
                        arr.push({ label: ele2, key: ele2 , width: 32},);
                    }
                })
                arr.push({ label: 'TransactionHash', key: 'transactionHash' });
            } else {
                arr.push({ label: 'methodName', key: 'methodName' });
                arr.push({ label: 'status', key: 'status' });
                arr.push({ label: 'cardId', key: 'cardId' });
                arr.push({ label: 'Date', key: 'Date' });
                arr.push({ label: 'Balance', key: 'Balance' });
                arr.push({ label: 'Amount', key: 'Amount' });
            }
            console.log('arr', arr);
            setHeaderExport(JSON.stringify(arr));
        });
    };

    const data = [];
    const headers = [];

    const Argument = (val, transactionHash) => {
        console.log('val', val, transactionHash)
        const allLength = (Object.values(val).length / 2);
        let genders = Object.keys(val);
        const arr = [];
        let obj = {}
        genders.forEach((gender, index) => {
            if (Number(index) >= Number(allLength)) {
                arr.push(gender);
                let str = gender
                let str2 = str.charAt(0).toUpperCase() + str.slice(1);
                headers.push({ label: str2, key: gender })
                if (obj[gender] === undefined) {
                    if (gender === 'date') {
                      const date = moment(Number(val[gender])).format("DD-MM-YYYY");  
                      obj[gender] = date
                    } else {
                        const strr =  val[gender]
                       obj[gender] = strr.replace(/"/g, '""')
                    //    obj[gender] = `"${strr.replace(/"/g, '""')}"`
                    }
                }
            }
        })
        data.push({ ...obj, transactionHash: transactionHash })
        setDataExport(JSON.stringify(data));
    }
    const handleExportCsv = (msg) => {
        if (msg) {
            msg.forEach((item) => {
                Argument(item && item.returnValues, item && item.transactionHash)
            })
        }
    }

    // const handleEventFilter = (eve) => {
    //     console.log('eve', eve)
    //     axiosClient.post(`api/emittedevents?search=${eve}`, {
    //         "address": contractAddress
    //     }).then((res) => {
    //         console.log('res', res && res.data && res.data.result)
    //         setFilterData(res && res.data && res.data.result);
    //         exportHeader(res && res.data && res.data.result);
    //         handleExportCsv(res && res.data && res.data.result);
    //     });
    // }

    const getExportData = () => {
        axiosClient.post(API_ENDPOINTS.fetchEvents, {
            "smartContractId": contractId
        }).then((res) => {
            // console.log('resdata', res.data.data);
            setEventsName(res.data.data);
        })

    }

    const handlePageClick = (data) => {
        const pageNo = data.selected + 1;
        setCurPage(pageNo)
        setLoading(true);
        setTimeout(() => {
            setTimer(true);
        }, 1000);
        axiosClient.post(API_ENDPOINTS.emittedEvents, {
            "address": contractAddress
        },{params : {noOfItems : itemsPerPage, pageNo: pageNo}}).then((res) => {
            console.log('resdata', res.data.result)
            if (res && res.data && res.data.totalCount) {
                setPage(Math.ceil((res && res.data && res.data.totalCount) / itemsPerPage))
            }
            setEvents(res.data.result);
            setLoading(false);
        }).catch(() => {
            setPage(0);
        });
    }

    //    const handleFilterButtonClick = (data) => {
    //     // const eve =data.;
    //     const currentDate = new Date();
    //     const startDate = new Date(
    //         currentDate.getFullYear(),
    //         currentDate.getMonth() - 3,
    //         currentDate.getDate()
    //     );
    //     // console.log(currentDate, startDate, "date");
    //     let from = Date.parse(currentDate)
    //     let to = Date.parse(startDate)
    //     setTo(to)
    //     setFrom(from)
    //     // console.log(from, to, "date format");
    //     if (to && from) {
    //         // console.log('test');
    //         axiosClient.post(`api/emittedevents?search=${data.eventname.name}`, {
    //             "address": contractAddress
    //         }).then((res) => {
    //             setDemo(res.data.result);
    //             exportHeader(res && res.data && res.data.result);
    //         handleExportCsv(res && res.data && res.data.result);
    //             // console.log('export', demo, res);
    //         });
    //     }
    // }

    // const handleExport = (data) => {
    //     if(!data.eventname){
    //         notifyFailure('Please give Event name')
    //     }
    //      console.log(data, 'filtered data');
    //      handleFilterButtonClick(data)
    //    };


    return (
        <>
            {loading ? (<Loader timer={timer} />) : (
                <Formik
                    render={formProps => <EventsComponent formProps={formProps} onGiftCardExport={onGiftCardExport} setFromdate={setFromdate} setTodate={setTodate} events={events} eventOption={eventOption} eventsName={eventsName} onChange={onChange} onChangeCheckBox={onChangeCheckBox} dataExport={dataExport} headerExport={headerExport} filterData={filterData} setFilterData={setFilterData} getExportData={getExportData} arg={arg} demo={demo} setEventOption={setEventOption} handlePageClick={handlePageClick} curPage={curPage} page={page} hideManualCsv={hideManualCsv} setHideManualCsv={setHideManualCsv} totalCount={totalCount} setHeaderExport={setHeaderExport} setDataExport={setDataExport} />}
                    initialValues={{ select: '' }}
                // onSubmit={handleExport}
                />
            )}
        </>
    )
}
export default Events;