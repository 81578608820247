import { Form, Field, FieldArray } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ReactSelect from '../common/ReactSelectFormik';
import { customStyles } from '../helpers/utils';
import '../SmartContract/SmartContract.css';
import style from '../ApiKeyGen/apiKeyGen.module.css';
import cn from 'classnames';

const EmittingEvents = (props) => {
  const [modal, setModal] = useState(false);

  const navigate = useNavigate();
  const handleCheckbox = (e) => {
    console.log(Number(e.target.checked), e.target.checked);
    if(e.target.checked){
      props.setcheckbox(0);
    }else{
      props.setcheckbox(1);
    }

  };

  const saveEventvalues = () => {
    let formValidated = props.validateData(props.formProps.values);
    if (formValidated) {
      setModal(true);
    }
  };

  const argsDisplayFunction = () => {
    return (
      <>
        {props && props.argsDisplay && props.argsDisplay[0].name && props.argsDisplay[0].name.length > 0 && props.argsDisplay[0].name.map((rowdata, index) => (
          <>
            {console.log(props.formProps.values.eventpayload, 'props.formProps.values.eventpayload')}
            <div className="row col mt-4 align-items-center">
              <div className="col"> <span>Input {index + 1}</span> </div>
              <div className="col">
                <Field
                  name={`eventpayload.arguments.${index}.arg`}
                  value={rowdata}
                  className="inputBox border text isDisabled"
                  type="text"
                />
              </div>
              <div className="col"> <span>Input Value {index + 1}</span> </div>
              <div className="col">
                <Field
                  className="inputBox border text"
                  name={`eventpayload.arguments.${index}.argValue`}
                  type={((props && props.argsDisplay && props.argsDisplay[0] && props.argsDisplay[0].argTypes[index] === 'string' ) || (props && props.argsDisplay && props.argsDisplay[0] && props.argsDisplay[0].argTypes[index] === 'JSON'))? 'text' : 'number'}
                  min='0'
                  placeholder={`Input Value in ${(props && props.argsDisplay && props.argsDisplay[0] && props.argsDisplay[0].argTypes[index] === 'int') ? 'Number' : (props && props.argsDisplay && props.argsDisplay[0] && props.argsDisplay[0].argTypes[index] === 'string') ? 'Text' : 'JSON'}`}
                  onKeyPress={(e)=>props.handledot(e)}
                  oninput= {props.setType(props && props.argsDisplay && props.argsDisplay[0] && props.argsDisplay[0].argTypes[index])}
                />
              </div>
            </div>
          </>
        ))}
      </>
    )
  };

  return (<>
    <Modal style={{ maxWidth: 520 }} isOpen={modal} keyboard={false}>
      <ModalHeader className={cn(style.modal, "row mx-0")}>
        <div className="d-flex justify-content-end pr-0"><img onClick={() => setModal(false)} src="../close.png" /></div>
      </ModalHeader>
      <ModalBody className={style.modal}>
        <Form>
          <div className='d-flex '>

            <div className="col-1">
              <Field type="checkbox"
                onClick={(e) => handleCheckbox(e)}
                checked={props.formProps.values.eventpayload.choice}
                name="eventpayload.choice"
              />
            </div>
            <div>Do you want to save data in Database ?</div>
          </div>
          <div className='d-flex justify-content-center'>
            <button className="button col-3 m-4 " type="submit" onClick={()=> setModal(false)}>Submit</button>
          </div>

        </Form>
      </ModalBody>
    </Modal>
    <div className="container">
      <div className="mb-2 mt-2">
        <span className="smartHeader cursor-pointer"><i class="fa fa-angle-left" aria-hidden="true" onClick={() => navigate("/viewTab/view")}></i></span>
        <span className="smartHeader ml-3">Audit Events</span>
      </div>

      <div className="shadow border border-primary">
        <Form>
          <FieldArray
            name="eventpayload.arguments"
            render={(arraryHelpers) => (
              <div className='m-4' >
                <div className="">
                  <div className="col">
                    <span>Event Name</span>
                  </div>
                  <div className='col-3'>
                    <ReactSelect
                      styles={customStyles}
                      name={"eventpayload.eventname"}
                      options={props && props.events}
                      onChangeOption={(e) => { props.onChange(e, props.formProps); }}
                    />
                  </div>
                </div>

                {props && props.argsDisplay && props.formProps.values.eventpayload.eventname !== "" && (
                  argsDisplayFunction()

                )}

              </div>
            )}
          />

          {props.formProps.values.eventpayload.arguments && props.formProps.values.eventpayload.arguments.length > 0 && (
            <div>
              <button className="button col-2 m-4" type="button" onClick={() => saveEventvalues()}>Save</button>
            </div>
          )}

        </Form>
      </div>
    </div>
  </>
  );
}

export default EmittingEvents;
