import React from "react";
import HeaderComponent from '../../components/Header';

const Header = () => {
return(
<div>
    <HeaderComponent /> 
</div>
);
};

export default Header;