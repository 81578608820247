import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import polygonLogo from '../../asset/images/polygonLogo.png';
import style from '../ApiKeyGen/apiKeyGen.module.css'
import ReactSelect from '../common/ReactSelectFormik';
import { CSVLink } from "react-csv";
import cn from 'classnames'
import { Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { Field, Form } from "formik";
import { customStyles } from "../helpers/utils";
import moment from 'moment';
import Pagination from "../common/Pagination";
import privateBlock from "../../asset/images/privateblockicon.png";
import DateTimePicker from '../common/FormElements/DateTimepicker';

const Events = (props) => {
    const seletedScreen = window.localStorage.getItem('SelectedDashboard');
    const seletedBlockchain = JSON.parse(window.localStorage.getItem('userjson')).blockChainType;
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [exportModal, setexportModal] = useState(false);
    const [modalData, setModalData] = useState();
    const [selected, setselected] = useState();
    const [manually, setManually] = useState(false);
    const [ischecked, setIschecked] = useState();
    console.log('1fill', typeof seletedBlockchain);
    // console.log('events', props.events[0] && props.events[0].returnValues && props.events[0].returnValues.amount);
    //    const filterOption = [
    //     {id:1, name :'Last Week' },
    //     {id:2, name :'Last Month' },
    //     {id:3, name :'Last Year' }
    //    ];
    const minDate = new Date(new Date(props && props.formProps && props.formProps.values && props.formProps.values.fromDate).getTime() + ((1 * 24) * 60 * 60 * 1000));

    useEffect(() => {
        if (props.formProps.values.select === 'Manually') {
            props.setFromdate(props.formProps.values.fromDate);
            props.setTodate(props.formProps.values.toDate);
        }

    }, [props.formProps.values])

    const options = [
        { label: "Quarterly", name: "Quarterly" },
        { label: "Half_yearly", name: "Half_yearly" },
        { label: "Yearly", name: "Yearly" },
        { label: "Manually", name: "Manually" }
    ];

    const handleModal = (data) => {
        if (modal) {
            setModal(false)
        } else {
            setModalData(data);
            setModal(true);
        }
    }
    const returnValues = (val) => {
        console.log('vallll', (Object.values(val).length / 2));

        const allLength = (Object.values(val).length / 2);
        console.log('val2', allLength);
        // const letters = new Set({ad: (Object.values(val))});
        // console.log('letters', letters);

        let genders = Object.keys(val);
        const arr = [];
        genders.forEach((gender, index) => {
            if (Number(index) >= Number(allLength)) {
                console.log(`{${gender}:${val[gender]}}`);
                if (gender === 'date') {
                    var date = moment(Number(val[gender])).format("DD-MM-YYYY");
                    console.log('date', moment(Number(val[gender])).format("DD-MM-YYYY"));
                    arr.push(`${gender}:${date}`);
                } else {
                    arr.push(`${gender}:${val[gender]}`);
                }
            }
        })

        return JSON.stringify(arr);
    };

    const breakHash = (value) => {
        console.log(value, 'value');
        let newvalue = value.slice(0, 18)
        return `${newvalue}...`
    }

    const redirect = (saveTranshash) => {
        window.open(`https://amoy.polygonscan.com/tx/${saveTranshash}#eventlog`, '_blank');
    };
    const dynamicGrid = (length) => {
        if (length === 1) {
            return style.gridItemOne
        } else {
            return style.gridItem
        }
    }
    const csvReport = {
        data: props && props.dataExport && JSON.parse(props && props.dataExport),
        headers: props && props.headerExport && JSON.parse(props && props.headerExport),
        filename: seletedScreen === '2' ? `Auditblock${props && props.eventOption}.csv` : `Auditblock.csv`
    };

    const handleCheckbox = (e, name) => {
        delete props.formProps.values.select;
        props.formProps.values.select = name;
        setselected(name);
        setIschecked(e.target.checked);
        if (props.formProps.values.select === 'Quarterly' || props.formProps.values.select === 'Half_yearly' || props.formProps.values.select === 'Yearly') {
            props.setHideManualCsv(true);
            props.setFromdate();
            props.setTodate();
            props.formProps.setValues({ ...props.formProps.values, toDate: '', fromDate: '' });
        }
        if (name === 'Manually') {
            if (e.target.checked) {
                setManually(true);
            } else {
                setManually(false);
            }
            // console.log(e.target.checked, 'e.target.checked');
        } else {
            setManually(false);
        }
    }

    const splitVal = (str) => {
        const index = str.indexOf(':');
        const arr = [str.slice(0, index), str.slice(index + 1)];
        // console.log(str, arr, 'str');
        return arr
    }
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    // const redirectPrivate = (saveTranshash) => {
    //     window.open(`http://49.206.233.181:8080/contracts/${saveTranshash}`, '_blank');
    //   };

    const redirectPrivateTrans = (saveTranshash) => {
        window.open(`http://49.206.233.181:8080/transactions/${saveTranshash}`, '_blank');
      };
    return (
        <>
            <div className="container">
                {(props && props.events && props.events.length > 0) && (
                    <>
                    {seletedScreen === '2' ? (
                        <div className="d-flex justify-content-end">
                        <button type="reset" onClick={() => { setexportModal(true); props.getExportData(); props.formProps.resetForm(); props.setEventOption(); }} className="createBtn cursor-pointer">Export</button>
                    </div>
                    ) : (
                        <div className="d-flex justify-content-end">
                            {props.headerExport && props && props.dataExport && (
                                <>
                                    {props.hideManualCsv && (
                                        <CSVLink {...csvReport}>
                                            <button type="button" onClick={() => { props.onGiftCardExport(); }} className={cn("createBtn cursor-pointer w-160")}>Export to CSV</button>
                                        </CSVLink>
                                    )}
                                </>
                            )}
                        {/* <button type="reset" onClick={() => { props.onGiftCardExport(); }} className="createBtn cursor-pointer">Export</button> */}
                    </div>
                    )}
                    </>
                )}
                <table class="table borderTable mt-4">
                    <thead>
                        <tr className="">
                            <th className="textColor cursor-pointer col-2">Event</th>
                            <th className="textColor cursor-pointer ">

                                <div className="row">
                                    <div className="col-3 text-right">Input</div>
                                    <div className="col">Input Values</div>
                                </div>
                            </th>
                            <th className='text-center col-2'></th>
                            <th className='col-1'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props && props.events && props.events.length > 0 && props.events.map((smartEvent) => {
                            // console.log(smartEvent, 'smartEvent');
                            return (
                                <>
                                    <tr>
                                        <td className="textColor td_vertical_align capitaliz2">
                                            {console.log('Smartevent', smartEvent.returnValues.methodName)}
                                            {smartEvent.returnValues.methodName ? smartEvent.returnValues.methodName : smartEvent.event}
                                            </td>
                                        <td className="textColor cursor-pointer td_vertical_align" >
                                            {/* <td className="textColor cursor-pointer td_vertical_align"> */}
                                            {JSON.parse(returnValues(smartEvent.returnValues)).map((item) => (
                                                <>
                                                    <div className="row">
                                                        <div className="col-3 text-right text-break text-wrap">{capitalizeFirstLetter(splitVal(item)[0])}</div>
                                                        <div className="col">{splitVal(item)[1]}</div>
                                                    </div>
                                                </>
                                            ))}
                                            {/* </td> */}
                                        </td>

                                        {/* <td scope="col">{JSON.parse(returnValues(smartEvent.returnValues)).map((item) => (<div>{splitVal(item)[0]}</div>))}</td> */}
                                        {/* <td >{JSON.parse(returnValues(smartEvent.returnValues)).map((item) => (<div>{splitVal(item)[1]}</div>))}</td> */}
                                        <td className="textColor cursor-pointer td_vertical_align text-center col-2" onClick={() => handleModal(smartEvent)}>View More</td>
                                        {seletedBlockchain === 1 ? (<td class="td_vertical_align text-center col-1"><div role="presentation" onClick={() => { redirect(smartEvent.transactionHash) }}>
                                            <span className="cursor-pointer"> <img src={polygonLogo} className="removeIconMain" alt="polygonLogo" /> </span>
                                        </div>
                                        </td>) : (<td class="td_vertical_align text-center col-1"><div role="presentation" onClick={() => { redirectPrivateTrans(smartEvent.transactionHash) }}>
                                            <span className="cursor-pointer"> <img src={privateBlock} className="removeIconMain" alt="polygonLogo" /> </span>
                                        </div>
                                        </td>)}

                                    </tr>
                                </>
                            )
                        })}
                    </tbody>

                </table>
                {(props && props.events && props.events.length === 0) && (
                    <div>
                        <div className="d-flex justify-content-center textFont">No Records Found</div>
                    </div>
                )
                }
                {(props && props.contracts && props.contracts.length === 0) && (
                    <div>
                        <div className="d-flex justify-content-center textFont">No Records Found</div>
                    </div>
                )
                }
                <div className="row m-0 mt-5">
                    {props && props.totalCount > 10 && (
                        <Pagination handlePageClick={props.handlePageClick} pageCount={props.page} forcePage={props.curPage - 1} />
                    )}
                </div>
            </div>






            <Modal style={{ maxWidth: 920 }} isOpen={modal} keyboard={false}>
                <ModalHeader className={cn(style.modal, "row mx-0")}>
                    <div className="d-flex justify-content-end pr-0"><img onClick={handleModal} src="../close.png" /></div>
                </ModalHeader>
                <ModalBody className={style.modal}>
                    <div className="row m-0">
                        {/* <div className="col-3 pl-0 fw-600">
                            Event:
                        </div>
                        {seletedScreen === '2' ? (<div className="col-9" style={{ wordBreak: 'break-all' }}>
                            {modalData && modalData.event}
                        </div>): (<div className="col-9" style={{ wordBreak: 'break-all' }}>
                            {JSON.parse(returnValues(modalData && modalData.returnValues)) ? splitVal(JSON.parse(returnValues(modalData.returnValues))[0])[1] : modalData && modalData.event}
                        </div>)} */}
                        <div className="col-3 pl-0 pt-3 fw-600">
                            Transaction Hash:
                        </div>
                        <div className="col-9 pt-3" style={{ wordBreak: 'break-all' }}>
                            {modalData && modalData.transactionHash}
                        </div>
                        <div className="col-3 pl-0 pt-3 fw-600">
                            Block Hash:
                        </div>
                        <div className="col-9 pt-3" style={{ wordBreak: 'break-all' }}>
                            {modalData && modalData.blockHash}
                        </div>
                        <div className="col-3 pl-0 pt-3 fw-600">
                            Block Number:
                        </div>
                        <div className="col-9 pt-3" style={{ wordBreak: 'break-all' }}>
                            {modalData && modalData.blockNumber}
                        </div>
                        {modalData && modalData.returnValues && (
                            <table className={cn("pl-0 pt-3 fw-600 mt-4 textFont")}>
                                <thead>
                                    <tr>
                                        <th className="col-3 pl-0">Input</th>
                                        <th className="col-9">Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {JSON.parse(returnValues(modalData.returnValues)).map((item) => (
                                        <tr>
                                            <td className="col-3 pl-0">{capitalizeFirstLetter(splitVal(item)[0])}</td>
                                            <td className="col-9">{splitVal(item)[1]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </ModalBody>
            </Modal>
            <Modal style={{ maxWidth: 750 }} isOpen={exportModal} keyboard={false}>
                <ModalHeader className={cn(style.modal, "row mx-0")}>
                    <div className="d-flex justify-content-end pr-0"><img onClick={() => { setexportModal(false); props.setDataExport(); props.setHeaderExport(); }} src="../close.png" /></div>
                </ModalHeader>
                <ModalBody className={style.modal}>
                    <Form >
                        <div className=''>

                        {seletedScreen === '2' ? (<div className="m-2 mb-3">
                                <div className="col">
                                    <span>Event Name</span>
                                </div>
                                <div className='col-4'>
                                    <ReactSelect
                                        styles={customStyles}
                                        name="eventname"
                                        options={props && props.eventsName}
                                        onChangeOption={(e) => { props.onChange(e); setselected(); }}
                                    />
                                </div>
                            </div>): ('')}
                            {seletedScreen === '2' && (
                                <>
{props && props.eventOption && 
                                <div className="d-flex justify-content-between mb-3 align-items-center">
                                    {options.map((opt) => {
                                        return <div className='d-flex m-4'>
                                            <Field
                                                type="checkbox"
                                                name='select'
                                                checked={ischecked && selected && selected === opt.name}
                                                onChange={(e) => { handleCheckbox(e, opt.name); props.onChangeCheckBox(e, opt.name); }}
                                            />
                                            <div className="ml-2">{opt.label}</div>
                                        </div>
                                    })
                                    }
                                </div>
                            }
</>
                            )}
                            {seletedScreen === '1' && (
                                <>
                                {/* <div className="d-flex justify-content-between mb-3 align-items-center">
                                    {options.map((opt) => {
                                        return <div className='d-flex m-4'>
                                            <Field
                                                type="checkbox"
                                                name='select'
                                                checked={ischecked && selected && selected === opt.name}
                                                onChange={(e) => { handleCheckbox(e, opt.name); props.onChangeCheckBox(e, opt.name); }}
                                            />
                                            <div className="ml-2">{opt.label}</div>
                                        </div>
                                    })
                                    }
                                </div> */}
                                <div>
                                    Are You sure you want to export all the data 
                                </div>
</>
                            )}
                            
                            {manually && selected && props && props.eventOption &&
                                <div className="row d-flex m-2 align-items-center">
                                    <div className="col-2 text-right">
                                        <span>From Date</span>
                                    </div>
                                    <div className='col'>
                                        <DateTimePicker
                                            name='fromDate'
                                            placeholder='from date'
                                            valueFormat
                                        // valueFormat
                                        // min={moment().toDate()}
                                        //    selected={values.startDate}
                                        // dateFormat="MMMM d, yyyy"
                                        // className="form-control"
                                        />
                                    </div>

                                    <div className="col-2 text-right">
                                        <span>To Date</span>
                                    </div>
                                    <div className='col'>
                                        <DateTimePicker
                                            name='toDate'
                                            placeholder='to date'
                                            min={minDate}
                                            valueFormat
                                        //    selected={values.startDate}
                                        // dateFormat="MMMM d, yyyy"
                                        // className="form-control"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        {/* <button type="button" className={cn("createBtn cursor-pointer w-25")} onClick={()=> validateData(props.formProps.values.fromDate, props.formProps.values.toDate)}>compare date</button> */}
                        {seletedScreen === '1' ? (
                        <div className='text-center mt-4'>
                            {console.log(selected, 'selected', props.eventOption, 'props.eventOption sss', props.headerExport, 'props.headerExport')}
                            {props.headerExport && props && props.dataExport && (
                                <>
                                    {props.hideManualCsv && (
                                        <CSVLink {...csvReport}>
                                            <button type="button" className={cn("createBtn cursor-pointer w-25")}>Export to CSV</button>
                                        </CSVLink>
                                    )}
                                </>
                            )}
                        </div>
): (
    <div className='text-center mt-4'>
                            {console.log(selected, 'selected', props.eventOption, 'props.eventOption sss', props.headerExport, 'props.headerExport')}
                            {selected && props && props.eventOption && props.headerExport && props && props.dataExport && (
                                <>
                                    {props.hideManualCsv && (
                                        <CSVLink {...csvReport}>
                                            <button type="button" className={cn("createBtn cursor-pointer w-25")}>Export to CSV</button>
                                        </CSVLink>
                                    )}
                                </>
                            )}
                        </div>
)}

                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}
export default Events