import { useState } from 'react';
import { Field, Form } from 'formik';
import style from './Login.module.css';
import image2 from '../../asset/images/imageLogo.png';
import image from '../../asset/images/logo.png';
import Logo from '../../asset/images/audit_block_logo.svg';
import cn from 'classnames';

const Login = (props) => {
    const handleEye = () => {
        if (!props.eyeShow) {
          props.setEyeShow(true);
        } else {
          props.setEyeShow(false);
        }
      };
    return (
        <>
            <div className={(style.loginContainer)}>
                <Form className={(style.loginform)}>

                    <div className={(style.headingsContainer)}>
                        <img className="logoSize" src={Logo} /> <span className="headerLogo">AUDIT BLOCK</span>
                        {/* <p>Login in with your username and password</p> */}
                    </div>
                    <div className={(style.mainContainer)}>
                        <label for="emailId">Your Email</label>
                        <Field
                            className={(style.logininput)}
                            type="text"
                            placeholder="Enter Email"
                            name="emailId"
                        />
                        <br /><br />
                        <label for="password">Your Password</label>
                        <div className="form-group m-0 "> 
                        <Field
                            className={cn(style.logininput)}
                            type={props.eyeShow ? 'text' : 'password'}
                            placeholder="Enter Password"
                            name="password"
                        />
                          {props.eyeShow ? (
                            <i role="presentation" className="fa fa-eye fa-lg cursor-pointer" style={{marginLeft: '-35px',color:'black'}} onClick={() => handleEye()} />
                          ) : (
                            <i role="presentation" className="fa fa-eye-slash fa-lg cursor-pointer" style={{marginLeft: '-35px',color:'black'}} onClick={() => handleEye()} />
                          )
                       }
                       </div>
                        <br /><br />
                        <button className={props && props.apiStatus ?'button cursor-no-pointer':'button' } type="submit" disabled={props && props.apiStatus}>Login</button>

                        <p className={(style.register)}>Don't have an account? <a href="/signup">Sign up</a></p>
                    </div>

                </Form>
            </div>
        </>
    )
}
export default Login;