import React from "react";
// import Button from 'react-bootstrap/Button';
import Logo from '../../asset/images/logo.png';
import image2 from '../../asset/images/audit_block_logo.svg';
import '../../components/Header/Header.css';
import cn from 'classnames';
import DefaultProfileImage from '../../asset/images/defaultProfileImage.jpg';
import { useNavigate } from "react-router";

const Header = () => {
  const userdata = JSON.parse(window.localStorage.getItem('userjson'));

  const navigate = useNavigate();
  const logout = () => {
    window.localStorage.clear();
    navigate('/');
  }

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const setBg = () => {
    const randomColor = Math.floor(Math.random()*16777215).toString(16);
    document.body.style.backgroundColor = "#" + randomColor;
    color.innerHTML = "#" + randomColor;
  }
  return (
    <div>
      <div className="container-fluid p-0">
        <nav className="navbar navbar-dark bg-darks shadow m-0">
          <div className="navbar-brand" href="#" onClick={() => window.location.replace('/dashboard')}>
            {/* <img src={image2} className={cn('logo d-inline-block align-top cursor-pointer')} width="150" height="80" alt="" /> */}
            <img src={image2} className={cn('logo d-inline-block align-top cursor-pointer')} width={'15%'} /> <span className="headerLogo">AUDIT BLOCK</span>
          </div>
          <div className="dropdown d-flex">
            {/* <div className="smartHeader mr-2" >{capitalizeFirstLetter(userdata.fName)} {capitalizeFirstLetter(userdata.lName)}</div> */}
            {(
              <span className={cn('cursor-pointer mr-2 ml-2 fs-20 fw-600 fs-16 pl-2 mb-2 text-center')} data-toggle="dropdown">
                 <div className="mr-3 mt-2 profile pt-2 font-weight-bold border"> {userdata.fName.charAt(0).toUpperCase()}</div></span>
            )}
            <div className="dropdown-menu pt-0 pb-0 text-center dropdown-menu-right mt-10" style={{ backgroundColor: 'rgba(33, 43, 38, 0.582)', borderRadius: "12px", width: "320px" }}>
              <div className="sidenav" >

                <div className="cursor-pointer" style={{ fontSize: "20px" }} >
                  <span className="subMenu d-flex justify-content-right m-auto ">
                    <div className="mr-3 mt-2 profile pt-1"> {userdata.fName.charAt(0).toUpperCase()}</div>
                    <div className="text-left text-break text-wrap">
                      <div>{capitalizeFirstLetter(userdata.fName)} {capitalizeFirstLetter(userdata.lName)}</div>
                      <div className="profileFont">{userdata.emailId}</div>
                    </div>
                  </span>
                 
                </div>
              
                <hr className=""/>
                <div className="cursor-pointer" style={{ fontSize: "35px" }} role="presentation" onClick={() => logout()}>
                  <span className="subMenu">
                    LOGOUT  <i class="fa fa-sign-out pl-2"></i>
                  </span>
                </div>

              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;