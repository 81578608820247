import React from "react";
import '../MainDashboard/mainDashboard.css'
import { useNavigate } from "react-router";
import polygonLogo from "../../asset/images/polygonLogo.png"
import datetimeDifference from "datetime-difference";
import Pagination from "../common/Pagination";

const Dashboard = (props) => {
  const navigate = useNavigate();

  const storeAddress = (address, name, date, id) => {
    window.localStorage.setItem('address', address);
    window.localStorage.setItem('name', name)
    window.localStorage.setItem('date', date.split('T')[0])
    window.localStorage.setItem('contractId', id);
    window.localStorage.setItem('tabName', 1);
  };
  const userJson = JSON.parse(window.localStorage.getItem('userjson'));
  let constructAge = (val) => {
    let constructedAge = ''
    const date = new Date(val);
    const formattedDate = new Date(`${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${((date.getHours() % 12) || 12).toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`);
    console.log(formattedDate, 'formatedData');
    const now = new Date();
    const formattedCurrentDate = new Date(`${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}-${now.getFullYear()} ${((now.getHours() % 12) || 12).toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')} ${now.getHours() >= 12 ? 'PM' : 'AM'}`);
    const result = datetimeDifference(formattedDate, formattedCurrentDate);
    console.log('result.days')
    if (result.days > 0) {
      constructedAge += result.days + 'days' + ' '
    }
    if (result.hours > 0) {
      constructedAge += result.hours + 'hr' + ' '
    }
    if (!result.days && result.minutes > 0) {
      constructedAge += result.minutes + 'min' + ' '
    }
    if (!result.days && !result.hours && result.seconds >= 0) {
      constructedAge += result.seconds + 'sec' + ' '
    }
    console.log('constructedAge', constructedAge)
    console.log('result', result, 'formattedCurrentDate', formattedCurrentDate);
    return constructedAge
  }
  return (
    <div className="row m-0">
      {userJson && userJson.roleId === 2 && (
        <div className='container textFont'>
          <div class='my-5 smartHeader pt-1 d-flex justify-content-center align-items-center'>
            Balance : {props && props.balanceData && props.balanceData.balance}{' '}
          </div>
        </div>
      )}
      {userJson && userJson.roleId !== 2 && (
        <>
        <center>
        <div class="my-5 smartHeader w-f typewriter pt-1"><h1>Lets Create !!!</h1></div>
        </center>
        <div className="container textFont">
            <div className="row mt-5">
            <div className="col text-center">
          <button type="button" class="my-5 createBtns p-3" onClick={() => {navigate("/contractDashboard"); props.SelectedDashboard(2)}}>Generic Event Logger</button>
        </div>
        <div className="col text-center">
        <button type="button" class="my-5 createBtns p-3" onClick={() => {navigate("/CouponDashboard"); props.SelectedDashboard(1)}}>Gift Card Management</button>
        </div>
        <div className="col text-center">
          <button type="button" class="my-5 createBtns p-3" onClick={() => { props.SelectedDashboard(3)}}>Claims Management</button>
        </div>
            </div>
      </div>
        </>
      )}
    </div>
  )
}

export default Dashboard;