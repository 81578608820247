import { Formik } from "formik"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import axiosClient from "../../ApiClient"
import { notifyFailure, notifySuccess } from "../../components/helpers/utils"
import Login from "../../components/Login"
import API_ENDPOINTS from '../../components/common/api';

const LoginContainer = () => {
    const navigate = useNavigate();
    const [eyeShow, setEyeShow] = useState(false);
    const [apiStatus, setApiStatus] = useState();

    const validateData = (data) => {
        let formValidated = false;
        if (data.emailId === '') {
            notifyFailure('Please enter your Email address.');
            return formValidated;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.emailId)) {
            notifyFailure('Please enter valid Email address');
            return formValidated;
        }
        if (data.password === '') {
            notifyFailure('Please enter your Password');
            return formValidated;
        }
        formValidated = true;
        return formValidated;
    }

    const onSubmit = (data) => {
        setEyeShow(false)
        let formValidated = validateData(data)
        if (formValidated) {
            setApiStatus(true);
            axiosClient.post(API_ENDPOINTS.login, data).then((res) => {
                console.log(res, 'ress');
                window.localStorage.setItem('userjson', JSON.stringify(res.data.user));
                window.localStorage.setItem('token', res.data.token);
                notifySuccess(res.data.msg);
                navigate('/dashboard');
            }).catch((error) => {
                notifyFailure(error.response.data.msg);
                setApiStatus(false);
            });
        }
    }
    return (
        <>
            <div>
                <Formik
                    render={formProps => <Login formProps={formProps} setEyeShow={setEyeShow} eyeShow={eyeShow} apiStatus={apiStatus} />}
                    initialValues={{ emailId: '', password: '' }}
                    onSubmit={onSubmit}
                />
            </div>
        </>
    )
}

export default LoginContainer;