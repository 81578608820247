import notFound from '../../../asset/images/notFound.png';
export default function NotFound() {
    return (
        <div className='container'>
            <div className='row'>
                <img className='m-auto w-50' src={notFound} />
                <h3 className='text-center'>Oops! You seem to be lost.</h3>
            </div>

        </div>
    )
}