import React from 'react';
import MainDashboardComponent from '../../components/MainDashboard';

const MainDashboard = () => {

    const SelectedDashboard = (selectedOption) => {
        window.localStorage.setItem('SelectedDashboard', selectedOption);
    }

return(
    <MainDashboardComponent SelectedDashboard={SelectedDashboard} />
)
}

export default MainDashboard;