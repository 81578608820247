import React from 'react';
import { Field } from 'formik';
import DatePicker from "react-widgets/DatePicker";
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import "react-widgets/styles.css";

//  moment.locale('en');
//  new momentLocalizer();
const renderDateTimePicker = ({
  field, form: { setFieldValue }, className, showTime, date, placeholder, onChangeDate = () => {}, disabled, min, defaultCurrentDate, onChangeNameDate = () => {},valueFormat
}) => (
  <>
    <DatePicker
      onChange={(value) => { setFieldValue(field.name, moment(new Date(value)).format('YYYY-MM-DD HH:mm:ss')); onChangeDate(value); onChangeNameDate({ name: field.name, date: value }); }}
      format="YYYY-MM-DD"
      className={className}
      date={date}
      disabled={disabled}
      time={showTime}
      value={!field.value ? null : new Date(field.value)}
      placeholder={placeholder}
      min={min}
      defaultCurrentDate={defaultCurrentDate}
      valueFormat={{ dateStyle: "medium" }}
    />
  </>
);
const DateTimepicker = (props) => {
  const {
    name, showTime, dateLabel, onChangeDate, placeholder, className, date, disabled, min, defaultCurrentDate, onChangeNameDate, valueFormat
  } = props;
  return (
    <div>
      {dateLabel && (
        <label className="fs-12 clr-input-box-label mb-1 background_color_transparant">
          {dateLabel}
        </label>
      )}
      <Field
        name={name}
        onChangeDate={onChangeDate}
        className={className}
        placeholder={placeholder}
        date={date}
        showTime={showTime}
        component={renderDateTimePicker}
        disabled={disabled}
        min={min}
        defaultCurrentDate={defaultCurrentDate}
        onChangeNameDate={onChangeNameDate}
        valueFormat={valueFormat}
      />
      {/* <ErrorMessage name={name} /> */}
    </div>
  );
};
export default DateTimepicker;
