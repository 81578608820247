import { Outlet, Route, Routes } from 'react-router-dom';
import NotFound from './components/common/NotFound';
import ViewTab from './components/ViewTab';
import Dashboard from './containers/Dashboard';
import EmittingEvents from './containers/EmitEvents';
import Events from './containers/Events';
import ApiKeyGenrator from './containers/ApiKeyGen';
import Header from './containers/Header';
import LoginContainer from './containers/Login';
import SignupContainer from './containers/Signup';
import SmartContract from './containers/SmartContract';
import ViewContracts from './containers/ViewContract';
import { AuthorisedRoute, ProtectedRoute } from './components/helpers/ProtectedRoute';
import ExportTab from './containers/ExportTab';
import MainDashboard from './containers/MainDashboard';
import CouponDashboard from './containers/CouponDashboard';
import LandingPage from './components/LandingPage';
import TryImplementation from './containers/TryImplementation';

const Router = () => {

    const WithNav = () => {
        return (
            <>
                <ProtectedRoute>
                    <Header />
                    <Outlet />
                </ProtectedRoute>
            </>
        );
    };

    const WithoutNav = () => <Outlet />

    const Tabchanges = () => {
        return (
            <>
                <ViewTab />
                <Outlet />
            </>
        );
    };
    return (
        <Routes>
            <Route element={<WithoutNav />}>
                <Route path="/" element={<AuthorisedRoute> <LandingPage /> </AuthorisedRoute>} />
                <Route path="/demo" element={<AuthorisedRoute> <LoginContainer /> </AuthorisedRoute>} />
                <Route exact path="/signup" element={<SignupContainer />} />
            </Route>
            <Route element={<WithNav />}>
                <Route exact path="/dashboard" element={<MainDashboard />} />
                <Route exact path="/CouponDashboard" element={<CouponDashboard />} />
                <Route exact path="/contractDashboard" element={<Dashboard />} />
                <Route exact path="/tryNow" element={<TryImplementation />} />
                <Route exact path="/smart" element={<SmartContract />} />
                <Route exact path="/edit" element={<EmittingEvents />} />
                <Route path="viewtab" element={<Tabchanges />} >
                    <Route path="view" element={<ViewContracts />} />
                    <Route path="events" element={<Events />} />
                    <Route path="export" element={<ExportTab />} />
                    <Route path="apiKeyGenration" element={<ApiKeyGenrator/>} />
                </Route>
                <Route path='*' element={<NotFound />} />
            </Route>

        </Routes>
    )
}
export default Router;