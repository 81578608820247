
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axiosClient from "../../ApiClient";
import { notifyFailure, notifySuccess } from "../../components/helpers/utils";
import Signup from "../../components/Signup";
import API_ENDPOINTS from '../../components/common/api';

const SignupContainer = () => {
    const [tenantOption, setTenantOption] = useState();
    const [eyeShow, setEyeShow] = useState(false);
    const [confirmEyeShow, setConfirmEyeShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axiosClient.get(API_ENDPOINTS.fetchTenantName).then((res) => {
            console.log(res.data.data, 'res.data.data');
            setTenantOption(res.data.data);
        }).catch(() => { });
    }, []);

    const validateFirstpage=(data)=>{
        let formValidated = false;
        if(!data.fName){
          notifyFailure('Please enter your First name.');
          return formValidated;
        }
        if(!/^[a-z][a-z\s]{1,15}$/i.test(data.fName)){
            notifyFailure('Please enter valid First name.');
            return formValidated;
          }
        if(!data.lName){
          notifyFailure('Please enter your Last name.');
          return formValidated;
        }
        if(!/^[a-z][a-z\s]{0,15}$/i.test(data.lName)){
            notifyFailure('Please enter valid Last name.');
            return formValidated;
          }
        if(!data.emailId){
          notifyFailure('Please enter your Email address');
          return formValidated;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.emailId)) {
            notifyFailure('Please enter valid Email address');
            return formValidated;
        }
        formValidated = true;
        return formValidated;
      }

    const validateData = (data) => {
        let formValidated = false;
        if (!data.fName) {
            notifyFailure('Please enter your First name.');
            return formValidated;
        }
        if (!data.lName) {
            notifyFailure('Please enter your Last Name');
            return formValidated;
        }
        if (!data.emailId) {
            notifyFailure('Please enter your Email address');
            return formValidated;
        }
        
        if (!data.mobileNumber) {
            notifyFailure('Please enter your Mobile number');
            return formValidated;
        }
        if(!/^\d{10}$/i.test(data.mobileNumber)){
            notifyFailure('Please enter valid Mobile number');
            return formValidated;
        }
        if (!data.tenantId) {
            notifyFailure('Please select Tenant name');
            return formValidated;
        }
        if (!data.password) {
            notifyFailure('Please enter Password');
            return formValidated;
        }
        if (!/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/i.test(data.password)) {
            notifyFailure('Please enter valid Password.');
            return formValidated;
        }
        ;
        if (!data.passwordConfirm) {
            notifyFailure('Confirm Password is required');
            return formValidated;
        }
        if (data.passwordConfirm !== data.password) {
            notifyFailure('Password and Confirm Password does not match');
            return formValidated;
        }
        formValidated = true;
        return formValidated;
    }

    const onSubmit = (data) => {
        setEyeShow(false);
        setConfirmEyeShow(false);
        let formValidated = validateData(data)
        if (formValidated) {
            axiosClient.post(API_ENDPOINTS.register, data).then((res) => {
                console.log(res, 'resss');
                notifySuccess(res.data.msg);
                navigate('/');
            }).catch((error) => {
                console.log(error, 'error');
                notifyFailure(error.response.data.msg);
            });

        }
    }
    return (
        <>
            <div>
                <Formik
                    render={formProps => <Signup formProps={formProps} tenantOption={tenantOption} validateFirstpage={validateFirstpage} eyeShow={eyeShow}
                    setEyeShow={setEyeShow} confirmEyeShow={confirmEyeShow} setConfirmEyeShow={setConfirmEyeShow}/>}
                    initialValues={{}}
                    onSubmit={onSubmit}
                />
            </div>
        </>
    )
}
export default SignupContainer;