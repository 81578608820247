import React from "react";
import ReactPaginate from 'react-paginate';
import styles from './pagination.module.css';
import cn from 'classnames';

const Pagination = (props) => {
    const leftArrow = () => {
        return (
          <div>
            <i className={cn(styles.arrow, "fa fa-angle-left")}/>
          </div>
        );
      };
      const rightArrow = () => {
        return (
          <div>
            <i className={cn(styles.arrow, "fa fa-angle-right")} />
          </div>
        );
      };
    return (
        <div className="col-12">
            <ReactPaginate 
            previousLabel={leftArrow()}
            nextLabel={rightArrow()}
            breakLabel={(
              <a href="#page">
    ...
              </a>
    )}
            breakClassName="break-me"
            pageCount={props.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={props.handlePageClick}
            containerClassName={styles.pagination}
            subContainerClassName={cn(styles.pages, styles.pagination)}
            activeClassName={cn(styles.active,styles.unsetZindex)}
            forcePage={props.forcePage}/>
        </div>
    )
}

export default Pagination; 