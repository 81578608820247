import { Field, Form } from "formik";
import { useState } from "react";
import style from '../Login/Login.module.css';
import image from '../../asset/images/logo.png'
import ReactSelect from '../../components/common/ReactSelectFormik';
import cn from 'classnames';
import stylee from '../Signup/signup.module.css';
import { customStyles } from '../helpers/utils';

const Signup = (props) => {
  const [page, setPage] = useState(1);
  
  const handleEye = () => {
    if (!props.eyeShow) {
      props.setEyeShow(true);
    } else {
      props.setEyeShow(false);
    }
  };
  const handleConfirmEye = () => {
    if (!props.confirmEyeShow) {
      props.setConfirmEyeShow(true);
    } else {
      props.setConfirmEyeShow(false);
    }
  };
  const clearForm = () => {
    props.formProps.resetForm();
  };
  const Message = ['Use 8 or more characters with a mix of letters, numbers & symbols'].map(item => (
    <ul>
      <li>{item}</li>
    </ul>
  ));
  console.log(props.formProps, 'form');
  return (
    <>

      <div className={(style.loginContainer)}>
        <Form className={(style.loginform)}>
          <div>
            {page === 1 ? (
              <div className={(style.headingsContainer)}>
                <img src={image} className={(style.logo)} />
              </div>
            ) : (
              <div className="mb-2 mt-2 d-flex">
                <span className="smartHeader cursor-pointer"><i class="fa fa-angle-left" aria-hidden="true" onClick={() => {setPage(1)}}></i></span>
                <h4 className="ml-2">Back</h4>
              </div>)}

            {page === 1 && (

              <div className={(style.mainContainer)}>

                <label for="fName">First Name <span className={(stylee.asterisk)}>*</span></label>
                <Field
                  className={(style.logininput)}
                  type="text"
                  placeholder="Enter First Name"
                  name="fName"
                />
                <br /><br />

                <label for="lName">Last Name <span className={(stylee.asterisk)}>*</span></label>
                <Field
                  className={(style.logininput)}
                  type="text"
                  placeholder="Enter Last Name"
                  name="lName"
                />
                <br /><br />
                <label for="email">Email <span className={(stylee.asterisk)}>*</span></label>
                <Field
                  className={(style.logininput)}
                  type="text"
                  placeholder="Enter Email"
                  name="emailId"
                />

                <br /><br />

                <div className="d-flex justify-content-end">
                  <button className="button w-25 " type="button" onClick={() => { props.validateFirstpage(props.formProps.values) && setPage(2) }}>Next</button>
                </div>
                <p className={(style.register)}>Have an account? <a href="/">Log in</a></p>
              </div>
            )}

            {page === 2 && (
              <div className={(style.mainContainer)}>

                <label for="mobileNumber">Mobile Number <span className={(stylee.asterisk)}>*</span></label>
                <Field
                  className={(style.logininput)}
                  type="text"
                  placeholder="Enter Mobile Number"
                  name="mobileNumber"
                />
                <br/><br/>

                <label for="tenantId">Tenant Name <span className={(stylee.asterisk)}>*</span></label>

                <ReactSelect
                  name="tenantId"
                  autoComplete="new-password"
                  options={props && props.tenantOption}
                  isSearchable={false}
                  styles={{option: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? 'white' : 'black',
                    '&:active': {
                        color: 'white',
                    }
                }),
                    valueContainer: provided => ({ ...provided, borderRadius: '2px', height: '53px', marginLeft: '10px' }), control: (baseStyles, state) => ({
                      ...baseStyles,
                      // borderColor: 'grey',
                    }), 
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors, primary: 'rgb(80, 80, 80)', neutral20: 'black'
                    },
                  })}
                />

                <br />
                <div>
                <label for="password">Set Password <span className={(stylee.asterisk)}>*</span></label>
                <div className={cn(stylee.tooltip, 'ml-2')}>
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                          <span className={cn(stylee.tooltiptext, 'fw-600 font-size-15 text-left')}>{Message}</span>
                        </div>
                <div className="form-group m-0 position-relative">        
                <Field
                  className={(style.logininput)}
                  autocomplete="new-password"
                  type={props.eyeShow ? 'text' : 'password'}
                  placeholder="Set Password"
                  name="password"
                />
                
                          {props.eyeShow ? (
                            <i role="presentation" className="fa fa-eye fa-lg cursor-pointer" style={{marginLeft: '-35px',color:'black'}} onClick={() => handleEye()} />
                          ) : (
                            <i role="presentation" className="fa fa-eye-slash fa-lg cursor-pointer" style={{marginLeft: '-35px',color:'black'}} onClick={() => handleEye()} />
                          )
                       }
                        </div>
                        </div>
                <br />

                <label for="passwordConfirm">Confirm Password <span className={(stylee.asterisk)}>*</span></label>
                <div className="form-group m-0 position-relative">  
                <Field
                  className={(style.logininput)}
                  autocomplete="new-password"
                  type={props.confirmEyeShow ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  name="passwordConfirm"
                />
                  {props.confirmEyeShow ? (
                            <i role="presentation" className="fa fa-eye fa-lg cursor-pointer" style={{marginLeft: '-35px',color:'black'}} onClick={() => handleConfirmEye()} />
                          ) : (
                            <i role="presentation" className="fa fa-eye-slash fa-lg cursor-pointer" style={{marginLeft: '-35px',color:'black'}} onClick={() => handleConfirmEye()} />
                          )
                       }
                       </div>

                <br /><br />

                <button className="button" type="submit">Sign Up</button>
                <p className={(style.register)}>Have an account? <a href="/">Log in</a></p>
              </div>
            )}
          </div>

        </Form>
      </div>
    </>
  )
}

export default Signup;