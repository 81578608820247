import React from "react";
import '../../components/Dashboard/Dashboard.css'
import { useNavigate } from "react-router";
import polygonLogo from "../../asset/images/polygonLogo.png"
import datetimeDifference from "datetime-difference";
import Pagination from "../common/Pagination";

const Dashboard = (props) => {
  const navigate = useNavigate();

  const storeAddress = (address, name, date, id) => {
    window.localStorage.setItem('address', address);
    window.localStorage.setItem('name', name)
    window.localStorage.setItem('date', date.split('T')[0])
    window.localStorage.setItem('contractId', id);
    window.localStorage.setItem('tabName', 1);
  };
  const userJson = JSON.parse(window.localStorage.getItem('userjson'));
  let constructAge = (val) => {
    let constructedAge = ''
    const date = new Date(val);
    const formattedDate = new Date(`${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${((date.getHours() % 12) || 12).toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`);
    console.log(formattedDate, 'formatedData');
    const now = new Date();
    const formattedCurrentDate = new Date(`${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}-${now.getFullYear()} ${((now.getHours() % 12) || 12).toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')} ${now.getHours() >= 12 ? 'PM' : 'AM'}`);
    const result = datetimeDifference(formattedDate, formattedCurrentDate);
    console.log('result.days')
    if (result.days > 0) {
      constructedAge += result.days + 'days' + ' '
    }
    if (result.hours > 0) {
      constructedAge += result.hours + 'hr' + ' '
    }
    if (!result.days && result.minutes > 0) {
      constructedAge += result.minutes + 'min' + ' '
    }
    if (!result.days && !result.hours && result.seconds >= 0) {
      constructedAge += result.seconds + 'sec' + ' '
    }
    console.log('constructedAge', constructedAge)
    console.log('result', result, 'formattedCurrentDate', formattedCurrentDate);
    return constructedAge
  }
  return (
    <div className="row m-0">
      {userJson && userJson.roleId === 2 && (
        <div className='container textFont'>
          <div class='my-5 smartHeader pt-1 d-flex justify-content-center align-items-center'>
            Balance : {props && props.balanceData && props.balanceData.balance}{' '}
          </div>
        </div>
      )}
      {userJson && userJson.roleId !== 2 && (
        <div className="container textFont">
        <div>
          <div class="float-left my-5 smartHeader pt-1">Smart Contracts</div>
          <button type="button" class="float-right my-5 createBtn" onClick={() => navigate("/smart")}>Create Smart Contract</button>
        </div>
        <table class="table borderTable">
          <thead>
            <tr>
              <th scope="col-3">Name</th>
              <th scope="col-2">Age</th>
              <th scope="2">Created by</th>
              <th scope="col-3">Date</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {props && props.contracts && props.contracts.length > 0 && props.contracts.map((smartContract) => {
              return (
                <>
                  <tr>
                    <td className="col-3 td_vertical_align"><div className="textColor" style={{ wordBreak: 'word-break' }}>{smartContract.contractName}</div></td>
                    <td className="col-2 textColor td_vertical_align"><div>{constructAge(smartContract.date)}</div></td>
                    <td className="col-2 textColor td_vertical_align">{smartContract.userName}</td>
                    <td className="col-3 textColor td_vertical_align">{smartContract.date.split('T')[0]}</td>
                    <td className="col"><button className="button" onClick={() => { navigate("/viewtab/view"); storeAddress(smartContract.address, smartContract.contractName, smartContract.date, smartContract.id); }}>View</button></td>
                    {/* <td className="textColor td_vertical_align"><i class="fa fa-trash" style={{ fontSize: '30px' }}></i></td> */}
                    <td className="col td_vertical_align"><div role="presentation" target="_blank" onClick={() => { window.open(`https://amoy.polygonscan.com/address/${smartContract.address}`, "_blank"); }}>
                      <span className="cursor-pointer"> <img src={polygonLogo} className="removeIconMain" alt="polygonLogo" /></span>
                    </div></td>
                  </tr>
                </>
              )
            })}
          </tbody>

        </table>
        {(props && !props.loading && props.contracts && props.contracts.length === 0) && (
          <div>
            <div className="d-flex justify-content-center textFont">No Records Found</div>
          </div>
        )}
        {(props && props.loading) && (
          <div className="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

        )
        }
      <div className="row m-0 mt-5">
        {props && props.totalCount > 10 && (
          <Pagination handlePageClick={props.handlePagination} pageCount={props.page} forcePage={props.curPage - 1} />
        )}
      </div>
      </div>
      )}
    </div>
  )
}

export default Dashboard;