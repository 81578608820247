import React, { useState } from 'react';
import TryImplementationComponent from '../../components/TryImplementation';
import { Formik } from 'formik';
import axiosClient from '../../ApiClient'; 
import { notifyFailure, notifySuccess } from "../../components/helpers/utils";
import Loader from '../../components/common/Loader';

const TryImplementation = () => {
    const [saveResponse, setSaveResponse] = useState();
    const [loading, setLoading] = useState(false);
    const payload = JSON.parse(window.localStorage.getItem('payload'));

    const handledot = (e) => {
        const forbiddenKeys = ['.', '-', '+'];
        const isForbiddenKey = forbiddenKeys.includes(e.key);
        const isNumberInput = e.target.type === 'number';
        const isTextInput = e.target.type === 'text';
        const isFirstCharacter = e.target.value.length === 0;
        const isNumericKey = isFirstCharacter && /^\d$/.test(e.key);
      
        if (isForbiddenKey && isNumberInput) {
          e.preventDefault();
        } else if ((e.key === 'e' || e.key === 'E') && isNumberInput) {
          e.preventDefault();
        } else if (isTextInput && isNumericKey) {
          e.preventDefault();
        }
      };

      const onSubmit = (data) => {
console.log('clicked', data);
setLoading(true);
const obj = data
delete obj.data;

axiosClient.post(payload.api, obj).then((res) => {
    console.log('res', res)
    setSaveResponse(res);
    notifySuccess("Submitted successfully");
    setLoading(false);
  }).catch((err) => {
console.log('err', err);
setLoading(false);
notifyFailure(err.response.data.message);
  })
      };


return(
    <div>
    <Formik
     render={formProps => <TryImplementationComponent  formProps={formProps} handledot={handledot} payload={payload} loading={loading} saveResponse={saveResponse} onSubmit={onSubmit} />}
     initialValues={{}}
     onSubmit={onSubmit}
   />
</div>
)
}
export default TryImplementation;