import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import "../../components/ViewTab/viewtab.css"
import cn from 'classnames';
import Events from "../../containers/Events";
import ViewContract from "../../containers/ViewContract";
import ApiKeyGenrator from "../../containers/ApiKeyGen";
// import style from '../../components/ViewTab'



const ViewTab = (props) => {
    const seletedScreen = window.localStorage.getItem('SelectedDashboard');
    const [selectedMenu, setSelectedMenu] = useState(1);
    const navigate = useNavigate();

    const role = JSON.parse(window.localStorage.getItem('userjson')).roleId;
    const handleMenu = (menu) => {
        setSelectedMenu(menu);
        window.localStorage.setItem('tabName', menu);
    };

    useEffect(() => {
        setSelectedMenu(window.localStorage.getItem('tabName'));
    }, [selectedMenu]);
        
    window.addEventListener("popstate", function (e) {
        let currentLocation = window.location.pathname;
        if (currentLocation === '/viewtab/events') {
            setSelectedMenu('2');
            window.localStorage.setItem('tabName', '2');
        }
        else if (currentLocation === '/viewtab/view') {
            setSelectedMenu('1');
            window.localStorage.setItem('tabName', '1');

        }
        else {
            setSelectedMenu('3');
            window.localStorage.setItem('tabName', '3');
        }
    });
    return (
        <div className="container">
            {seletedScreen === '1' && (<div className="mt-3">
                <span className="smartHeader cursor-pointer"><i class="fa fa-angle-left" aria-hidden="true" onClick={() => {navigate("/CouponDashboard"); window.localStorage.removeItem('APIKEY')}}></i></span>
                <span className="smartHeader ml-3">Gift Card Management APIs</span>
            </div>)}
            {seletedScreen === '2' && (<div className="mt-3">
                <span className="smartHeader cursor-pointer"><i class="fa fa-angle-left" aria-hidden="true" onClick={() => {navigate("/contractDashboard"); window.localStorage.removeItem('APIKEY')}}></i></span>
                <span className="smartHeader ml-3">Smart Contract Details</span>
            </div>)}
            <div className="d-flex">
                <div>
                    <Link to='/viewtab/view' className={selectedMenu === '1' ? 'actives d-flex px-4 py-2 cursor-pointer' : 'inactives d-flex px-4 py-2 cursor-pointer'} onClick={() => { handleMenu(1); }}>
                        Plugin Details
                    </Link >
                </div >
                <div>
                    <Link to='/viewtab/events' className={selectedMenu === '2' ? 'actives d-flex px-4 py-2 cursor-pointer' : 'inactives d-flex px-4 py-2 cursor-pointer'} onClick={() => { handleMenu(2); }}>
                        Events
                    </Link>
                </div>
                {role === 1 && (
                   <div>
                   <Link to='/viewtab/apiKeyGenration' className={selectedMenu === '3' ? 'actives d-flex px-4 py-2 cursor-pointer' : 'inactives d-flex px-4 py-2 cursor-pointer'} onClick={() => { handleMenu(3); }}>
                       API Key
                   </Link>
               </div> 
                )}
            </div>
        </div>

    )
}
export default ViewTab