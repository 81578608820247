const API_ENDPOINTS = {
    createEvents: 'api/createevent',
    dataTypes: 'api/datatypes/',
    getContracts: 'api/getContracts/',
    fetchEvents: 'api/fetchEvents',
    fetchArgs: 'api/fetchArgs',
    postEvents: 'api/postevents',
    logEvents: 'api/logevents',
    emittedEvents: 'api/emittedevents',
    getEmittedGiftData: 'api/getEmittedGiftData',
    login: 'api/login',
    fetchTenantName: 'api/fetchtenantname',
    register:'api/register',
    apiKeyGenration: 'api/generateApiKey',
    getApiKey: 'api/fetchApiKey',
    getBalance: 'api/balance',
    postMessage: 'api/AddUserMessage',
    fetchPluginDetails: 'api/getPluginDetails'
}

export default API_ENDPOINTS;