import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router"
import  SmartContractComponent  from '../../components/SmartContract';
import axiosClient from '../../ApiClient';
import _ from 'lodash';
import Loader from '../../components/common/Loader';
import { notifyFailure, notifySuccess, validateEvent } from '../../components/helpers/utils';
import API_ENDPOINTS from '../../components/common/api';
import messages from '../../components/common/messages'

 const SmartContract =()=>{
    const userId = JSON.parse(window.localStorage.getItem("userjson"));
    const [initialValues]= useState({data: [{eventName: '', argument: [{arg: '', argType: ''}]}]});
    const [datatypes, setDatatypes]= useState();
    const [loading, setLoading]= useState(false);
    const [timer, setTimer] = useState(false);
    // const [newData, setnewData]= useState('');
    const options = [{id: 1, name: 'Text'}, {id: 2, name: 'Number'}, {id: 3, name: 'Json'}]
    const navigate = useNavigate();


    useEffect(() => {
        axiosClient.get(API_ENDPOINTS.dataTypes).then((res) => {
            console.log('resdata', res.data.data)
            setDatatypes(res.data.data);
        })        
    }, []);
    const handleKeyPress = e => {
      if (e.key === '-') {
        e.preventDefault();
      }
    }
    function validateData(data) {
        let formValidated = false;
        for(let i = 0; i < data.length; i++) {
          function countWord(i) {
            const j = i % 10,
                k = i % 100;
            if (j == 1 && k != 11) {
                return i + "st";
            }
            if (j == 2 && k != 12) {
                return i + "nd";
            }
            if (j == 3 && k != 13) {
                return i + "rd";
            }
            return i + "th";
        }
          if (data[i].eventName === '') {
            notifyFailure(`Please enter the ${countWord([i+1])} event name`);
            return formValidated;
          }
          if (!/^[A-Za-z]+$/i.test(data[i].eventName.charAt(0))){
            notifyFailure(`First letter should be alphabet only in ${countWord([i+1])} event name`);
            return formValidated;
          }
          if (!/^[A-Za-z_]+$/i.test(data[i].eventName)) {
            notifyFailure('Please enter only alphabets in event name');
            return formValidated;
          }
          let validateEventName = validateEvent(data[i].eventName)
          if(!validateEventName.validated) {
            notifyFailure(`Please enter another ${countWord([i+1])} event name`);
            return formValidated;
          }
          if (data[i].argument.length === 0) {
            notifyFailure('Please add input field ');
            return formValidated; 
          }
          
          for (let j = 0; j < data[i].argument.length; j++) { 
          if (data[i].argument[j] === undefined) {
            notifyFailure('Please fill all required fields');
            return formValidated; 
          }
          if (data[i].argument[j].arg === '') {
            notifyFailure(`Please enter the ${countWord([i+1])} event Input`);
            return formValidated;
          }
          if (data[i].eventName === data[i].argument[j].arg) {
            notifyFailure(`Event name and Input name cannot be same in ${countWord(i + 1)} event Input`);
            return formValidated; 
          } 
          let count = 0;
          for (let k = 0; k < data[i].argument.length; k++) { 
          if (data[i].argument[k].arg === data[i].argument[j].arg) {
            count += 1;
            if (count > 1) {
              notifyFailure(`Input name cannot be same in ${countWord(i + 1)} event`);
              return formValidated; 
            }
          } 
          }

          if (!/^[A-Za-z_]+$/i.test(data[i].argument[j].arg)) {
            notifyFailure('Please enter alphabets only');
            return formValidated;
          }
          let validateEventName = validateEvent(data[i].argument[j].arg)
          if(!validateEventName.validated) {
            notifyFailure(`Please enter another ${countWord([i+1])} input name`);
            return formValidated;
          }
          if (data[i].argument[j].argType === '') {
            notifyFailure(`Please select ${countWord([i+1])} event type`);
            return formValidated;
          }
          if (data[i].argument[j].arg === '' || data[i].argument[j].argType === '') {
            notifyFailure(`Please enter input and type`);
            return formValidated; 
          }
      }   
      let countevent = 0;
          for (let k = 0; k < data.length; k++) { 
          if (data[i].eventName === data[k].eventName) {
            countevent += 1;
            if (countevent > 1) {
              notifyFailure('Event name cannot be same');
              return formValidated; 
            }
          } 
          }
    }
    
        formValidated = true;
        return formValidated;
      }
    

  const onSubmit = (data) => {
    let formValidated = validateData(data && data.data)
    if (formValidated) {
      setLoading(true);
      const preValue = { ...data };
      for (let i = 0; i < data.data.length; i += 1) {
        for (let j = 0; j < data.data[i].argument.length; j += 1) {
          // const preValue = { ...data}; 
          const name = preValue.data[i].argument[j].argType.id;
          // console.log(name, 'name');
          delete preValue.data[i].argument[j].argType
          preValue.data[i].argument[j].argType = name;
          // console.log({...preValue}, 'shreya');

        }
      }

      console.log({ ...preValue }, 'success');
      setTimeout(() => {
        setTimer(true);
      }, 10000);
      axiosClient.post(API_ENDPOINTS.createEvents, { ...preValue, userId: userId.id, tenentId: userId.tenantId, id_options: 1 }).then((res) => {
        setLoading(false);
        notifySuccess(messages.CONTRACT_DEPLOYED_SUCCESS);
        navigate('/contractDashboard');
      })
        .catch((error) => {
          setLoading(false);
          notifyFailure(error.response.data.POSSIBLE_REASONS ? error.response.data.POSSIBLE_REASONS : messages.CONTRACT_DEPLOYMENT_FAIL);
        })
    }
  }
    return (
        <div>
            {loading ? (<>
            <Loader timer={timer} />
            </>) : 
            <div>
                 <Formik
                  render={formProps => <SmartContractComponent  formProps={formProps} datatypes={options} handleKeyPress={handleKeyPress} />}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                />
            </div>
           }
        </div>
    )
}

export default SmartContract;