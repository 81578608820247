import React, { useEffect, useState } from "react";
import './loader.css';

const Loader = (props) => {
    console.log('timer', props.timer);
return (
    <div className="row m-0 justify-content-center align-items-center h70">
        <div class="triple-spinner"></div>
        {props.timer && (<div class="col-12 d-flex justify-content-center">Please wait, Process is taking longer than usual.</div>)}
    </div>
)
}

export default Loader;