import { useEffect, useState } from "react";
import ExportTab from "../../components/ExportTab";
import axiosClient from '../../ApiClient';
import { Formik } from 'formik';
import API_ENDPOINTS from '../../components/common/api';
const ExportTabs = () => {
    const [events, setEvents] = useState();
    const [EventName, setEventName] = useState();
    const [arg, setArg] = useState()
    const [to, setTo] = useState()
    const [from, setFrom] = useState()
    const [demo, setDemo] = useState([])
    const contractId = window.localStorage.getItem('contractId')

    const contractAddress = window.localStorage.getItem('address');

    useEffect(() => {
        axiosClient.post(API_ENDPOINTS.fetchEvents, {
            "smartContractId": contractId
        }).then((res) => {
            console.log('export', res.data.data);
            setEvents(res.data.data);
        })
    }, []);
    const onChange = (from, to) => {
        console.log('eee', e);
        setEventName(e.name);
        axiosClient.post('api/emittedevents', {
            "smartContractId": contractId
        }).then((res) => {
            console.log('export', res.data.data);
            setArg(res.data.data);
        });
    }
    const handleFilterButtonClick = () => {
        const currentDate = new Date();
        const startDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 3,
            currentDate.getDate()
        );
        console.log(currentDate, startDate, "date");
        let from = Date.parse(currentDate)
        let to = Date.parse(startDate)
        setTo(to)
        setFrom(from)
        console.log(from, to, "date format");
        if (to && from) {
            console.log('test');
            axiosClient.post(API_ENDPOINTS.emittedEvents, {
                "address": contractAddress
            }).then((res) => {
                setDemo(res.data.result);
                console.log('export', demo, res);
            });
        }
    }


    return (
        <div>
            <div>
                <Formik
                    render={formProps => <ExportTab formProps={formProps} events={events} onChange={onChange} EventName={EventName} arg={arg} setTo={setTo} setFrom={setFrom} handleFilterButtonClick={handleFilterButtonClick} demo={demo} />}

                    initialValues={{}}
                />
            </div>

        </div>
    )
}
export default ExportTabs;