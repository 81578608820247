import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
    const user = localStorage.getItem('userjson');
    if (!user) {
        return <Navigate to="/" />;
    }
    return children;
};

export const AuthorisedRoute = ({ children }) => {
    const user = localStorage.getItem('userjson');
    if (user) {
        return <Navigate to="/dashboard" />;
    }
    return children;
};

