import React, { useEffect, useState } from 'react'
import ApiKeyGen from '../../components/ApiKeyGen';
import axiosClient from '../../ApiClient';
import API_ENDPOINTS from '../../components/common/api';
import { notifySuccess } from "../../components/helpers/utils";

const ApiKeyGenrator = () => {
  const contractId = window.localStorage.getItem('contractId')
  const userJson =JSON.parse(window.localStorage.getItem('userjson'));
  const [apiKey, setApikey] = useState()
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
  axiosClient.post(API_ENDPOINTS.getApiKey, {
    smartContractId : contractId,
    userId: userJson.id
  }).then((res) => {
    console.log('res', res)
    setApikey(res && res.data && res.data.data) 
  })
  }, [])
  
  const handleApiKeyGenration = () => {
    axiosClient.post(API_ENDPOINTS.apiKeyGenration, {
       smartContractId : contractId,
       userId : userJson.id,
       email : userJson.emailId
    }).then((res) => {
     notifySuccess("API key generated successfully");
     setApikey(res && res.data && res.data.KeyInfo && res.data.KeyInfo[0] && res.data.KeyInfo[0].APIKEY)
     setDisabled(true);
    })
  }
  return (
    <div>
        <ApiKeyGen handleApiKeyGenration={handleApiKeyGenration} apiKey={apiKey}  disabled={disabled}/>
    </div>
  )
}

export default ApiKeyGenrator;