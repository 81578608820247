import React, { useEffect, useState } from "react";
import CouponDashboardComponent from "../../components/CouponDashboard";
import axiosClient from '../../ApiClient';
import API_ENDPOINTS from '../../components/common/api';
import { Formik } from 'formik';
import { notifySuccess, notifyFailure } from "../../components/helpers/utils";
import Loader from '../../components/common/Loader';

const CouponDashboard = (props) => {
    const [contracts, setContracts] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [curPage, setCurPage] = useState(1);
    const [totalCount , setTotalCount] = useState();
    const [balanceData, setBalanceData] = useState();
    const [timer, setTimer] = useState(false);
    const [modal, setModal] = useState(false);

    const userId = JSON.parse(window.localStorage.getItem("userjson"));
    const itemsPerPage = 10;

    const handleModal = () => {
      if (modal) {
        setModal(false)
      } else {
        setModal(true)
      }
    }

    const couponCall = () => {
      axiosClient.post(`api/getCoupons/${itemsPerPage}/1`, {
        // "user": userId.id,
        "tenantId":userId.tenantId
    }).then((res) => {
        if (res && res.data && res.data.totalCount) {
            setTotalCount(res && res.data && res.data.totalCount);
            setPage(Math.ceil((res && res.data && res.data.totalCount) / itemsPerPage))
        }
        setLoading(false);
        console.log('resdata', res.data.data)
        setContracts(res.data.data);
        setLoading(false);
        
    }).catch((error) => {
        setLoading(false);
        setPage(0);
    });
    };

    useEffect(() => {
        setLoading(true);
        axiosClient.post(`api/getCoupons/${itemsPerPage}/${page}`, {
          // "user": userId.id,
          "tenantId":userId.tenantId
      }).then((res) => {
          if (res && res.data && res.data.totalCount) {
              setTotalCount(res && res.data && res.data.totalCount);
              setPage(Math.ceil((res && res.data && res.data.totalCount) / itemsPerPage))
          }
          setLoading(false);
          console.log('resdata', res.data.data)
          setContracts(res.data.data);
          setLoading(false);
          
      }).catch((error) => {
          setLoading(false);
          setPage(0);
      });

        // axiosClient.post('api/emittedevents', {
        //     "address" : "0xa0d9978D95AD9e7913DDB1BFE170F0B8238A8904"
        // }).then((res) => {
        //     console.log('dummy', res.data.result);
        // });     
    }, []);

    useEffect(() => {
    if(userId && userId.roleId === 2) {
        axiosClient
      .get(`${API_ENDPOINTS.getBalance}?roleId=${userId && userId.roleId}`)
      .then((res) => {
        console.log('res', res);
        setBalanceData(res && res.data);
      });
    }
  }, [userId && userId.roleId === 2]);

    const handlePagination = (data) => {
    console.log('data', data);
     const pageNo = data.selected + 1;
     console.log('pageNO', pageNo);
     setCurPage(pageNo);
     axiosClient.post(`api/getContracts/${itemsPerPage}/${pageNo}`, {
        "tenantId":userId.tenantId
    }).then((res) => {
        if (res && res.data && res.data.totalCount) {
            setPage(Math.ceil((res && res.data && res.data.totalCount) / itemsPerPage))
        }
        setLoading(true);
        console.log('resdata', res.data.data)
        setContracts(res.data.data);
        setLoading(false);
       
    }).catch((error) => {
        setLoading(true);
        setPage(0);
        if (error.response.status === 403) {
            setTimeout(() => {
                window.location.replace('/dashboard');
                setLoading(false);
            }, 1000);
        }
    });
    }

    const onSubmits = (data) => {
          setLoading(true);
          setModal(true)
        //   console.log({ ...preValue }, 'success');
          setTimeout(() => {
            setTimer(true);
          }, 10000);
          axiosClient.post(API_ENDPOINTS.createEvents, {"data":
          [{
              "eventName":"giftcard",
              "argument":[{"arg":"types","argType":2}]
              
          }],
          "userId":userId.id,
          "tenentId":userId.tenantId,
          "functionId":[1,2,3,4,5,6,7,8],
           "id_options": 2}).then((res) => {
            setLoading(false);
            setModal(false)
            couponCall();
            notifySuccess(messages.CONTRACT_DEPLOYED_SUCCESS);
            // navigate('/dashboard');
            console.log('asd');
          }).catch((error) => {
              setLoading(false);
              setModal(false)
              couponCall();
              notifyFailure(error.response.data.POSSIBLE_REASONS ? error.response.data.POSSIBLE_REASONS : messages.CONTRACT_DEPLOYMENT_FAIL);
            })
      }

    return (<>
    <div>
    {loading ? (<>
            <Loader timer={timer} />
          </>) :(<div>
            <Formik
                  render={formProps => <CouponDashboardComponent formProps={formProps}  handleModal={handleModal} modal={modal} onSubmits={onSubmits} contracts={contracts} loading={loading} handlePagination={handlePagination} curPage={curPage} page={page} totalCount={totalCount} balanceData={balanceData}/>}
                  initialValues={{}}
                />
            </div>)
}
            </div>
    </>
    )
}
export default CouponDashboard;