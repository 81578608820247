import React from 'react';
import { Field } from 'formik';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    '&:active': {
      color: 'white',
    }
  })
};

const RenderSelectInput = (props) => {
  const {
    field,
    options,
    isMulti,
    isClearable,
    formatOptionLabel,
    placeholder,
    label,
    form: { setFieldValue },
    customLabel,
    customId,
    isDisabled,
    onChangeOption = () => { },
    onScrollToButtom = () => { },
    onScrollToTop = () => { },
    onChangeNameOption = () => {},
    className,
    clear,
    styles,
    theme
  } = props;

  if (clear && !isDisabled) {
    setFieldValue(field.name, '');
    props.setClear(false);
  }
  return (
    <div className="pos-r">
      {label && (
        <label className="fs-12 clr-input-box-label mb-0 background_color_transparant">
          {label}
        </label>
      )}
      <Select
        options={options}
        getOptionLabel={(option) => { return customLabel ? option[customLabel] : option.name; }}
        getOptionValue={(option) => { return customId ? option[customId] : option.id; }}
        name={field.name}
        classNamePrefix="reactSelect"
        className={className}
        placeholder={placeholder}
        isDisabled={isDisabled}
        defaultValue={field.value || props.defaultValue}
        onChange={(option) => { setFieldValue(field.name, option); onChangeOption(option); onChangeNameOption({ name: field.name, value: option }); }}
        formatOptionLabel={formatOptionLabel}
        isMulti={isMulti}
        isClearable={isClearable}
        onMenuScrollToBottom={() => { onScrollToButtom(); }}
        onMenuScrollToTop={() => { onScrollToTop(); }}
        width="100%"
        value={field.value}
        // style={customStyles}
        styles={styles}
        theme={theme}
        components={{
          IndicatorSeparator: () => null
        }}
      />
    </div>
  );
};

const ReactSelect = (props) => {
  const {
    name,
    options,
    placeholder,
    isMulti,
    isDisabled,
    isSearchable,
    formatOptionLabel,
    isClearable,
    getOptionLabel,
    className,
    errorLabel,
    label,
    touched,
    customId,
    customLabel,
    onChangeOption,
    onScrollToButtom,
    onScrollToTop,
    defaultValue,
    onChangeNameOption,
    clear,
    setClear,
    styles,
    theme
  } = props;
  return (
    <div>
      <Field
        name={name}
        component={RenderSelectInput}
        options={options}
        touched={touched}
        placeholder={placeholder}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        getOptionLabel={getOptionLabel}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable}
        className={className}
        errorLabel={errorLabel}
        label={label}
        customLabel={customLabel}
        customId={customId}
        onChangeOption={onChangeOption}
        onScrollToButtom={onScrollToButtom}
        onScrollToTop={onScrollToTop}
        defaultValue={defaultValue}
        onChangeNameOption={onChangeNameOption}
        clear={clear}
        setClear={setClear}
        styles={styles}
        theme={theme}
      />
      {/* <ErrorMessage name={name} /> */}
    </div>
  );
};
export default ReactSelect;
