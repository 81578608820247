import React, { useEffect, useState } from "react";
import DashboardComponent from "../../components/Dashboard";
import axiosClient from '../../ApiClient';
import API_ENDPOINTS from '../../components/common/api';

const Dashboard = () => {
    const [contracts, setContracts] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [curPage, setCurPage] = useState(1);
    const [totalCount , setTotalCount] = useState();
    const [balanceData, setBalanceData] = useState();

    const userId = JSON.parse(window.localStorage.getItem("userjson"));
    const itemsPerPage = 10;
    useEffect(() => {
        setLoading(true);
        axiosClient.post(`api/getContracts/${itemsPerPage}/${page}`, {
            // "user": userId.id,
            "tenantId":userId.tenantId
        }).then((res) => {
            if (res && res.data && res.data.totalCount) {
                setTotalCount(res && res.data && res.data.totalCount);
                setPage(Math.ceil((res && res.data && res.data.totalCount) / itemsPerPage))
            }
            setLoading(false);
            console.log('resdata', res.data.data)
            setContracts(res.data.data);
            setLoading(false);
            
        }).catch((error) => {
            setLoading(false);
            setPage(0);
        });

        // axiosClient.post('api/emittedevents', {
        //     "address" : "0xa0d9978D95AD9e7913DDB1BFE170F0B8238A8904"
        // }).then((res) => {
        //     console.log('dummy', res.data.result);
        // });     
    }, []);

    useEffect(() => {
    if(userId && userId.roleId === 2) {
        axiosClient
      .get(`${API_ENDPOINTS.getBalance}?roleId=${userId && userId.roleId}`)
      .then((res) => {
        console.log('res', res);
        setBalanceData(res && res.data);
      });
    }
  }, [userId && userId.roleId === 2]);

    const handlePagination = (data) => {
    console.log('data', data);
     const pageNo = data.selected + 1;
     console.log('pageNO', pageNo);
     setCurPage(pageNo);
     axiosClient.post(`api/getContracts/${itemsPerPage}/${pageNo}`, {
        "tenantId":userId.tenantId
    }).then((res) => {
        if (res && res.data && res.data.totalCount) {
            setPage(Math.ceil((res && res.data && res.data.totalCount) / itemsPerPage))
        }
        setLoading(true);
        console.log('resdata', res.data.data)
        setContracts(res.data.data);
        setLoading(false);
       
    }).catch((error) => {
        setLoading(true);
        setPage(0);
        if (error.response.status === 403) {
            setTimeout(() => {
                window.location.replace('/dashboard');
                setLoading(false);
            }, 1000);
        }
    });
    }

    return (<>
        <DashboardComponent contracts={contracts} loading={loading} handlePagination={handlePagination} curPage={curPage} page={page} totalCount={totalCount} balanceData={balanceData} />
    </>
    )
}
export default Dashboard;