import React from 'react'
import { Form, Field } from 'formik';
import { useNavigate } from 'react-router';
import Main from '../../containers/MainDashboard';
import Loader from '../common/Loader';

const TryImplementation = (props) => {
    console.log('loading', props && props.loading);
    const navigate = useNavigate();
    console.log('ll', props && props.payload && props.payload.payload && props.payload.payload.key );
return(
    <div className="container">
      <div className="mb-2 mt-2">
        <span className="smartHeader cursor-pointer"><i class="fa fa-angle-left" aria-hidden="true" onClick={() => navigate("/viewTab/view")}></i></span>
        <span className="smartHeader ml-3">{props && props.payload && props.payload.name}</span>
      </div>

      <div className="shadow border border-primary">
        <Form>
            {props && props.payload && props.payload.payload && props.payload.payload.map((showPayload, index) => {
                console.log('showPayload', showPayload);
                return(
                    <>
<div className="row col mt-4 mb-4 align-items-center">
              <div className="col"> <span>Input {index + 1}</span> </div>
              <div className="col">
                <Field
                  name={`eventpayload`}
                  value={showPayload.key}
                  className="inputBox border text isDisabled"
                  type="text"
                />
              </div>
              <div className="col"> <span>Input Value {index + 1}</span> </div>
              <div className="col">
                <Field
                  className="inputBox border text"
                  name={showPayload.key}
                  type={(showPayload.key === 'pin' || showPayload.key === 'amount') ? `number` : `text`}
                  min='0'
                  placeholder={`${showPayload.value}`}
                  onKeyPress={(e)=>props.handledot(e)}
                //   oninput= {props.setType(props && props.argsDisplay && props.argsDisplay[0] && props.argsDisplay[0].argTypes[index])}
                />
              </div>
              <input name="api" type="hidden" value={showPayload.api} />
            </div>
            </>
                )
            })}
            <button className="button col-3 m-4 " type="submit">Submit</button>
        </Form>
      </div>
      {props && props.loading ? (<Loader />) : props && props.saveResponse && (
        <div className='mt-5'>
        <div className="mb-2 mt-2">
        <span className="smartHeader ml-3">Response</span>
      </div>
            <div className="border border-primary row m-0 p-4 d-flex ">
                <div className="col-10 fs16">
                    <pre>
                        {JSON.stringify(props.saveResponse.data, null, 2)}
                    </pre>
                </div>
            </div>
            </div>
      )}
    </div>
)
}
export default TryImplementation;