import './App.css';
// import axiosClient from './ApiClient';
import "bootstrap/dist/css/bootstrap.min.css";
import Toastify from './components/common/Toastify';
import Router from './router';
import { useNavigate } from 'react-router';

const App = () => {
  const navigate = useNavigate();
  let token = window.localStorage.getItem('token');
  window.onstorage = () => {
      // console.log(token, 'sss token');
      if (!token) {
        return navigate("/");
      }
  };

  return (
    <div className="App">
      <Router/>
      <Toastify />
    </div>
  );
}

export default App;
