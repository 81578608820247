import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReactSelect from '../../components/common/ReactSelectFormik';
import Events from "../Events";
import ViewTab from "../../components/ViewTab";
import { customStyles } from "../helpers/utils";
import style from '../ApiKeyGen/apiKeyGen.module.css'
import copy from '../../asset/images/copy.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import cn from 'classnames'
import {Tooltip } from 'reactstrap';
import polygonLogo from "../../asset/images/polygonLogo.png"
import privateblock from "../../asset/images/privateblockicon.png"

const ViewContract = (props) => {
  const seletedScreen = window.localStorage.getItem('SelectedDashboard');
  const seletedBlockchain = JSON.parse(window.localStorage.getItem('userjson')).blockChainType;
  console.log(props && props.formProps);
  console.log('argument', props && props.pluginDetails);
  const [text, setText] = useState('');
  const [copySuccess, setCopySuccess] = useState('');
  const [copySuccess2, setCopySuccess2] = useState('');
  const [createEntity, setCreateEntity] = useState('');
  const [copycreateEntitySuccess, setCopycreateEntitySuccess] = useState('');
  const [copycreateEntitySuccess2, setCopycreateEntitySuccess2] = useState('');
  const [debit, setDebit] = useState('');
  const [credit, setCredit] = useState('');
  const [cardDetails, setCardDetails] = useState('');
  const [balance, setBalance] = useState('');
  const [history, setHistory] = useState('');
  const [clicked, setClicked] = useState('');
  
  const { REACT_APP_SERVER_URL } = process.env;
  const navigate = useNavigate();
  const name = window.localStorage.getItem('name');
  const address = window.localStorage.getItem('address')
  const date = window.localStorage.getItem('date')
  useEffect(() => {
    // setText(props && props.genratedApiKey ? `${REACT_APP_SERVER_URL}/api/postevents` : `${REACT_APP_SERVER_URL}/api/postevents`)
    // setCreateEntity(props && props.genratedApiKey ? `${REACT_APP_SERVER_URL}/api/CreateGiftCard` : `${REACT_APP_SERVER_URL}/api/CreateGiftCard`)
    // setDebit(props && props.genratedApiKey ? `${REACT_APP_SERVER_URL}/api/Debit` : `${REACT_APP_SERVER_URL}/api/Debit`)
    // setCredit(props && props.genratedApiKey ? `${REACT_APP_SERVER_URL}/api/Credit` : `${REACT_APP_SERVER_URL}/api/Credit`)
    // setCardDetails(props && props.genratedApiKey ? `${REACT_APP_SERVER_URL}/api/GetGiftCardDetails` : `${REACT_APP_SERVER_URL}/api/GetGiftCardDetails`)
    // setBalance(props && props.genratedApiKey ? `${REACT_APP_SERVER_URL}/api/Balance` : `${REACT_APP_SERVER_URL}/api/Balance`)
    // setHistory(props && props.genratedApiKey ? `${REACT_APP_SERVER_URL}/api/History` : `${REACT_APP_SERVER_URL}/api/History`)
    setText(`${REACT_APP_SERVER_URL}/api/postevents` )
    setCreateEntity(`${REACT_APP_SERVER_URL}/api/CreateGiftCard`)
    setDebit(`${REACT_APP_SERVER_URL}/api/Debit`)
    setCredit(`${REACT_APP_SERVER_URL}/api/Credit`)
    setCardDetails(`${REACT_APP_SERVER_URL}/api/GetGiftCardDetails`)
    setBalance(`${REACT_APP_SERVER_URL}/api/Balance`)
    setHistory(`${REACT_APP_SERVER_URL}/api/History`)
  }, [props && props.genratedApiKey]);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  // const copytext = (e) => {
  //   let copyText = document.querySelector("#input");
  //   copyText.select();
  //   document.execCommand("copy");
  // }

  const copytext = () => {
    // setCopySuccess('Copied!');
    // setTimeout(() => {
    //   setCopySuccess('');
    // }, 1000);
  }
  const copytext2 = () => {
    setCopySuccess2('Copied!');
    setTimeout(() => {
      setCopySuccess2('');
    }, 1000);
  };
  // const copycreateEntity = () => {
  //   setCopycreateEntitySuccess('Copied!');
  //   setTimeout(() => {
  //     setCopycreateEntitySuccess('');
  //   }, 1000);
  // }
  // const copycreateEntity2 = () => {
  //   setCopycreateEntitySuccess2('Copied!');
  //   setTimeout(() => {
  //     setCopycreateEntitySuccess2('');
  //   }, 1000);
  // };

  //   useEffect(() => {
  // callFun();
  //   },[props && props.argument && props.argument[0].name && props.argument[0].name.length > 0]);

  const data = {
    "eventpayload": {
      "userId": "bjk1df",
      "txnId": "0x02",
      "eventname": "Transfer",
      "arguments": [
        { "arg": "from", "argValue": "0x43BbAC99b0735F4811F2CE610A6846211BA9103d" },
        { "arg": "to", "argValue": "0xEb4685a2F3C0E1F01521D8397819bc24f4C31733" },
        { "arg": "value", "argValue": "5" }
      ]
    }
  }

const DataCreateEntity = {
  "email" : "<example@gmail.com>" ,
  "amount" : "<0.00> (Number)"
}
const DatacardDetails = {
  "email" : "<example@gmail.com>"
}
const Datadebit = {
  "amount" : "<0.00> (Number)",
  "giftCardId" : "<GIFT-CARD-ID>",
  "pin": "<GENERATED PIN> (Number)"
}
const Datacredit = {
  "giftCardId" : "<GIFT-CARD-ID>" ,
  "amount" : "<0.00> (Number)",
  "pin": "<GENERATED PIN> (Number)"
}
const Databalance = {
  "giftCardId" : "<GIFT-CARD-ID>",
  "pin": "<GENERATED PIN> (Number)"
}
const Datahistory = {
  "giftCardId" : "<GIFT-CARD-ID>"
}
//GIFT-t64aojs9-2023

const functionClick = (clicky) => {
  console.log(clicky);
setClicked(clicky);
setCopySuccess('Copied!');
    setTimeout(() => {
      setCopySuccess('');
    }, 1000);
}
let payloadKeyArr = [];
const pushArr = (payload) => {
Object.keys(payload).forEach(element => {
  payloadKeyArr.push({key : element, value: payload[element]})
});
return payloadKeyArr;
}

const StorePayload = (methodName) => {
let data = {};
// if (methodName === 'Create Gift Card') {
//   data.payload =  pushArr(DataCreateEntity)
//   data.name = methodName
//   data.api = `${REACT_APP_SERVER_URL}/api/CreateGiftCard`
// }
// if (methodName === 'Get Gift Card Details') {
//   data.payload =  pushArr(DatacardDetails)
//   data.name = methodName
//   data.api = `${REACT_APP_SERVER_URL}/api/GetGiftCardDetails`
// }
// if (methodName === 'Debit') {
//   data.payload =  pushArr(Datadebit)
//   data.name = methodName
//   data.api = `${REACT_APP_SERVER_URL}/api/Debit`
// }
// if (methodName === 'Credit') {
//   data.payload =  pushArr(Datacredit)
//   data.name = methodName
//   data.api = `${REACT_APP_SERVER_URL}/api/Credit`
// }
// if (methodName === 'Balance') {
//   data.payload =  pushArr(Databalance)
//   data.name = methodName
//   data.api = `${REACT_APP_SERVER_URL}/api/Balance`
// }
// if (methodName === 'History') {
//   data.payload =  pushArr(Datahistory)
//   data.name = methodName
//   data.api = `${REACT_APP_SERVER_URL}/api/History`
// }

props && props.pluginDetails && props.pluginDetails.forEach((pluginDetails) => {
  if (methodName === pluginDetails.apiName) {
    data.payload =  pushArr(pluginDetails.payload)
    data.name = pluginDetails.apiName
    data.api = pluginDetails.url
  }
})


console.log('data', data);
window.localStorage.setItem('payload', JSON.stringify(data));
};

const redirect = (saveTranshash) => {
  window.open(`https://amoy.polygonscan.com/address/${saveTranshash}`, '_blank');
};

const redirectPrivate = (saveTranshash) => {
  window.open(`http://49.206.233.181:8080/contracts/${saveTranshash}`, '_blank');
};

  return (
    <>
    {seletedScreen === '1' && (
      <div className="container pb-5">
      <div className="row m-0 pt-3 mb-4">
        <div className="col-10 p-0 align-items-center">
          <div className="fs16 fw600">
            Name
          </div>
          <div className="textColor">
            {name}
          </div>
          <div className="mt-4 fs16 fw600">
            Address
          </div>
          {seletedBlockchain === 1 ? (<div className="textColor d-flex">
            <span>{address}</span> <div className="ml-3" role="presentation" onClick={() => { redirect(address) }}>
                                            <span className="cursor-pointer"> <img src={polygonLogo} className="removeIconMain" alt="polygonLogo" /> </span>
                                        </div>
          </div>) :(<div className="textColor d-flex">
            <span>{address}</span> <div className="ml-3" role="presentation" onClick={() => { redirectPrivate(address) }}>
                                            <span className="cursor-pointer"> <img src={privateblock} className="removeIconMain" alt="polygonLogo" /> </span>
                                        </div>
          </div>)}
          <div className="mt-4 fs16 fw600">
            Note
          </div>
          <div className="textColor">
          {props && props.genratedApiKey ? `Please add API key in headers of the request with key name as x-api-key:<API KEY>` : `Please generate API key`}
          </div>
        </div>
        <div className="col-2 text-left">
          <div className="fs16 fw600">Created Date</div>
          <div className="textColor">
            {date}
          </div>
        </div>
      </div>
      {props && props.pluginDetails && props.pluginDetails.map((pluginDetails, idx) => {
        return(
          <div className="mt-5">
  <div className="row m-0">
  <div className="fs16 fw600 mb-3 col-9 d-flex align-items-center">
{pluginDetails.apiName}
          </div>
          <div className="col-3">
          {props && props.genratedApiKey && (
            <button className="button m-4" onClick={() => {navigate("/tryNow"); StorePayload(pluginDetails.apiName);}}>Try Now</button>
          )}
          </div>
  </div>

<div className="row m-0 p-3 border border-primary">
        <div className={cn(style.apiKeyCont, 'd-flex align-items-center')}>
          <div className={props && props.genratedApiKey ? cn(style.inputContWidth, " col-10 d-flex align-items-center text fs16 textShadow") : cn(style.inputContWidth, style.unselectable, " col-10 d-flex align-items-center text fs16 textShadow")}>{pluginDetails.url}</div>
          <div className={props && props.genratedApiKey ? cn(style.imgContWidth, 'col-1 text-right') : cn(style.imgContWidth, style.noCursor, 'col-1 text-right')}>
            <CopyToClipboard text={pluginDetails.url} onCopy={copytext} >
              <img onClick={() => {functionClick(idx);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1')}>{clicked === idx && copySuccess}</div>
        </div>
      </div>
      <div className="border border-primary row m-0 p-4 d-flex ">
          <div className="col-10 fs16">
              <pre className="">
            {JSON.stringify(pluginDetails.payload, null, 2)}
          </pre>
          </div>
          <div className={cn(style.imgContWidth, 'col-1 text-right')}>
            <CopyToClipboard text={JSON.stringify(pluginDetails.payload, null, 2)} onCopy={copytext2} >
              <img onClick={() => {functionClick(`1${idx}`);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1 text-left')}>{clicked === `1${idx}` && copySuccess}</div>
        </div>
        <div className="border border-primary row m-0 p-4 d-flex ">
        <div className="textColor">Description: {pluginDetails.description}</div>
        </div>
</div>
        )
      })}
{/* <div className="mt-5">
  <div className="row m-0">
  <div className="fs16 fw600 mb-3 col-9 d-flex align-items-center">
Create Gift Card
          </div>
          <div className="col-3">
          {props && props.genratedApiKey && (
            <button className="button m-4" onClick={() => {navigate("/tryNow"); StorePayload('Create Gift Card');}}>Try Now</button>
          )}
          </div>
  </div>

<div className="row m-0 p-3 border border-primary">
        <div className={cn(style.apiKeyCont, 'd-flex align-items-center')}>
          <div className={props && props.genratedApiKey ? cn(style.inputContWidth, " col-10 d-flex align-items-center text fs16 textShadow") : cn(style.inputContWidth, style.unselectable, " col-10 d-flex align-items-center text fs16 textShadow")}>{createEntity}</div>
          <div className={props && props.genratedApiKey ? cn(style.imgContWidth, 'col-1 text-right') : cn(style.imgContWidth, style.noCursor, 'col-1 text-right')}>
            <CopyToClipboard text={createEntity} onCopy={copytext} >
              <img onClick={() => {functionClick(1);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1')}>{clicked === 1 && copySuccess}</div>
        </div>
      </div>
      <div className="border border-primary row m-0 p-4 d-flex ">
          <div className="col-10 fs16">
              <pre className="">
            {JSON.stringify(DataCreateEntity, null, 2)}
          </pre>
          </div>
          <div className={cn(style.imgContWidth, 'col-1 text-right')}>
            <CopyToClipboard text={JSON.stringify(DataCreateEntity, null, 2)} onCopy={copytext2} >
              <img onClick={() => {functionClick(11);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1 text-left')}>{clicked === 11 && copySuccess}</div>
        </div>
        <div className="border border-primary row m-0 p-4 d-flex ">
        <div className="textColor">Description: This API enables the creation of a gift card with a distinctive gift card id and a pin attached.</div>
        </div>
</div>
<div className="mt-4">
  <div className="row m-0">
  <div className="fs16 fw600 mb-3 col-9 d-flex align-items-center">
Get Gift Card Details
          </div>
          <div className="col-3">
          {props && props.genratedApiKey && (
            <button className="button m-4" onClick={() => {navigate("/tryNow"); StorePayload('Get Gift Card Details');}}>Try Now</button>
          )}
          </div>
  </div>
<div className="row m-0 p-3 border border-primary">
        <div className={cn(style.apiKeyCont, 'd-flex align-items-center')}>
          <div className={props && props.genratedApiKey ? cn(style.inputContWidth, " col-10 d-flex align-items-center text fs16 textShadow") : cn(style.inputContWidth, style.unselectable, " col-10 d-flex align-items-center text fs16 textShadow")}>{cardDetails}</div>
          <div className={props && props.genratedApiKey ? cn(style.imgContWidth, 'col-1 text-right') : cn(style.imgContWidth, style.noCursor, 'col-1 text-right')}>
            <CopyToClipboard text={cardDetails} onCopy={copytext} >
              <img onClick={() => {functionClick(2);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1')}>{clicked === 2 && copySuccess}</div>
        </div>
      </div>

      <div className="border border-primary row m-0 p-4 d-flex ">
          <div className="col-10 fs16">
              <pre className="">
            {JSON.stringify(DatacardDetails, null, 2)}
          </pre>
          </div>
          <div className={cn(style.imgContWidth, 'col-1 text-right')}>
            <CopyToClipboard text={JSON.stringify(DatacardDetails, null, 2)} onCopy={copytext2} >
              <img onClick={() => {functionClick(12);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1 text-left')}>{clicked === 12 && copySuccess}</div>
        </div>
        <div className="border border-primary row m-0 p-4 d-flex ">
        <div className="textColor">Description: This API assists in retrieving gift card's information.</div>
        </div>
</div>

<div className="mt-4">
  <div className="row m-0">
  <div className="fs16 fw600 mb-3 col-9 d-flex align-items-center">
            Debit
          </div>
    <div className="col-3">
    {props && props.genratedApiKey && (
            <button className="button m-4" onClick={() => {navigate("/tryNow"); StorePayload('Debit');}}>Try Now</button>
          )}
    </div>
  </div>
<div className="row m-0 p-3 border border-primary">
        <div className={cn(style.apiKeyCont, 'd-flex align-items-center')}>
          <div className={props && props.genratedApiKey ? cn(style.inputContWidth, " col-10 d-flex align-items-center text fs16 textShadow") : cn(style.inputContWidth, style.unselectable, " col-10 d-flex align-items-center text fs16 textShadow")}>{debit}</div>
          <div className={props && props.genratedApiKey ? cn(style.imgContWidth, 'col-1 text-right') : cn(style.imgContWidth, style.noCursor, 'col-1 text-right')}>
            <CopyToClipboard text={debit} onCopy={copytext} >
              <img onClick={() => {functionClick(3);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1')}>{clicked === 3 && copySuccess}</div>
        </div>
      </div>

      <div className="border border-primary row m-0 p-4 d-flex ">
          <div className="col-10 fs16">
              <pre className="">
            {JSON.stringify(Datadebit, null, 2)}
          </pre>
          </div>
          <div className={cn(style.imgContWidth, 'col-1 text-right')}>
            <CopyToClipboard text={JSON.stringify(Datadebit, null, 2)} onCopy={copytext2} >
              <img onClick={() => {functionClick(13)}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1 text-left')}>{clicked === 13 && copySuccess}</div>
        </div>
        <div className="border border-primary row m-0 p-4 d-flex ">
        <div className="textColor">Description: This API allows you to deduct amount from a gift card.</div>
        </div>
</div>
    
<div className="mt-4">
  <div className="row m-0">
  <div className="fs16 fw600 mb-3 col-9 d-flex align-items-center">
            Credit
          </div>
    <div className="col-3">
    {props && props.genratedApiKey && (
            <button className="button m-4" onClick={() => {navigate("/tryNow"); StorePayload('Credit');}}>Try Now</button>
          )}
    </div>
  </div>

<div className="row m-0 p-3 border border-primary">
        <div className={cn(style.apiKeyCont, 'd-flex align-items-center')}>
          <div className={props && props.genratedApiKey ? cn(style.inputContWidth, " col-10 d-flex align-items-center text fs16 textShadow") : cn(style.inputContWidth, style.unselectable, " col-10 d-flex align-items-center text fs16 textShadow")}>{credit}</div>
          <div className={props && props.genratedApiKey ? cn(style.imgContWidth, 'col-1 text-right') : cn(style.imgContWidth, style.noCursor, 'col-1 text-right')}>
            <CopyToClipboard text={credit} onCopy={copytext} >
              <img onClick={() => {functionClick(4);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1')}>{clicked === 4 && copySuccess}</div>
        </div>
      </div>

      <div className="border border-primary row m-0 p-4 d-flex ">
          <div className="col-10 fs16">
              <pre className="">
            {JSON.stringify(Datacredit, null, 2)}
          </pre>
          </div>
          <div className={cn(style.imgContWidth, 'col-1 text-right')}>
            <CopyToClipboard text={JSON.stringify(Datacredit, null, 2)} onCopy={copytext2} >
              <img onClick={() => {functionClick(14);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1 text-left')}>{clicked === 14 && copySuccess}</div>
        </div>
        <div className="border border-primary row m-0 p-4 d-flex ">
        <div className="textColor">Description: This API allows you to add amount to the gift card.</div>
        </div>
</div>
<div className="mt-4">
  <div className="row m-0">
  <div className="fs16 fw600 mb-3 col-9 d-flex align-items-center">
            Balance
          </div>
    <div className="col-3">
    {props && props.genratedApiKey && (
            <button className="button m-4" onClick={() => {navigate("/tryNow"); StorePayload('Balance');}}>Try Now</button>
          )}
    </div>
  </div>

<div className="row m-0 p-3 border border-primary">
        <div className={cn(style.apiKeyCont, 'd-flex align-items-center')}>
          <div className={props && props.genratedApiKey ? cn(style.inputContWidth, " col-10 d-flex align-items-center text fs16 textShadow") : cn(style.inputContWidth, style.unselectable, " col-10 d-flex align-items-center text fs16 textShadow")}>{balance}</div>
          <div className={props && props.genratedApiKey ? cn(style.imgContWidth, 'col-1 text-right') : cn(style.imgContWidth, style.noCursor, 'col-1 text-right')}>
            <CopyToClipboard text={balance} onCopy={copytext} >
              <img onClick={() => {functionClick(5);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1')}>{clicked === 5 && copySuccess}</div>
        </div>
      </div>

      <div className="border border-primary row m-0 p-4 d-flex ">
          <div className="col-10 fs16">
              <pre className="">
            {JSON.stringify(Databalance, null, 2)}
          </pre>
          </div>
          <div className={cn(style.imgContWidth, 'col-1 text-right')}>
            <CopyToClipboard text={JSON.stringify(Databalance, null, 2)} onCopy={copytext2} >
              <img onClick={() => {functionClick(15);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1 text-left')}>{clicked === 15 && copySuccess}</div>
        </div>
        <div className="border border-primary row m-0 p-4 d-flex ">
        <div className="textColor">Description: This API allows you to check the remaining amount of the gift card.</div>
        </div>
</div>

<div className="mt-4">
  <div className="row m-0">
  <div className="fs16 fw600 mb-3 col-9 d-flex align-items-center">
            History
          </div>
    <div className="col-3">
    {props && props.genratedApiKey && (
            <button className="button m-4" onClick={() => {navigate("/tryNow"); StorePayload('History');}}>Try Now</button>
          )}
    </div>
  </div>

<div className="row m-0 p-3 border border-primary">
        <div className={cn(style.apiKeyCont, 'd-flex align-items-center')}>
          <div className={props && props.genratedApiKey ? cn(style.inputContWidth, " col-10 d-flex align-items-center text fs16 textShadow") : cn(style.inputContWidth, style.unselectable, " col-10 d-flex align-items-center text fs16 textShadow")}>{history}</div>
          <div className={props && props.genratedApiKey ? cn(style.imgContWidth, 'col-1 text-right') : cn(style.imgContWidth, style.noCursor, 'col-1 text-right')}>
            <CopyToClipboard text={history} onCopy={copytext} >
              <img onClick={() => {functionClick(6);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1')}>{clicked === 6 && copySuccess}</div>
        </div>
      </div>

      <div className="border border-primary row m-0 p-4 d-flex ">
          <div className="col-10 fs16">
              <pre className="">
            {JSON.stringify(Datahistory, null, 2)}
          </pre>
          </div>
          <div className={cn(style.imgContWidth, 'col-1 text-right')}>
            <CopyToClipboard text={JSON.stringify(Datahistory, null, 2)} onCopy={copytext2} >
              <img onClick={() => {functionClick(16);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1 text-left')}>{clicked === 16 && copySuccess}</div>
        </div>
        <div className="border border-primary row m-0 p-4 d-flex ">
        <div className="textColor">Description: This API allows you to check the transaction history of the gift card.</div>
        </div>
</div> */}

    </div>
    )}
    {seletedScreen === '2' && (
      <div className="container pb-5">
      <div className="row m-0 pt-3">
        <div className="col-10 p-0 align-items-center">
          <div className="fs16 fw600">
            Name
          </div>
          <div className="textColor">
            {name}
          </div>
          <div className="mt-4 fs16 fw600">
            Address
          </div>
          {seletedBlockchain === 1 ? (<div className="textColor d-flex">
            <span>{address}</span> <div className="ml-3" role="presentation" onClick={() => { redirect(address) }}>
                                            <span className="cursor-pointer"> <img src={polygonLogo} className="removeIconMain" alt="polygonLogo" /> </span>
                                        </div>
          </div>) :(<div className="textColor d-flex">
            <span>{address}</span> <div className="ml-3" role="presentation" onClick={() => { redirectPrivate(address) }}>
                                            <span className="cursor-pointer"> <img src={privateblock} className="removeIconMain" alt="polygonLogo" /> </span>
                                        </div>
          </div>)}
          <div className="mt-4 fs16 fw600">
            Note
          </div>
          <div className="textColor">
          {props && props.genratedApiKey ? `Please add API key in headers of the request with key name as x-api-key:<API KEY>` : `Please generate API key`}
          </div>
        </div>
        <div className="col-2 text-left">
          <div className="fs16 fw600">Date</div>
          <div className="textColor">
            {date}
          </div>
        </div>
      </div>
      <div className={props.genratedApiKey ? cn("row m-0 align-items-center") : cn("row m-0 align-items-center mb-4 mt-4")}>
        <div className="col-3 p-0">
          <ReactSelect
            styles={customStyles}
            name="event"
            placeholder="Select Event Name"
            options={props && props.events}
            onChangeOption={(e) => { props.onChange(e) }}
          />
        </div>
        <div className="col-2 ml-auto">
          {props && props.genratedApiKey && (
            <button className="button m-4" onClick={() => navigate("/edit")}>Try Now</button>
          )}
        </div>
      </div>

      <div className="row m-0 p-3 border border-primary">
        <div className={cn(style.apiKeyCont, 'd-flex align-items-center')}>
          <div className={props && props.genratedApiKey ? cn(style.inputContWidth, " col-10 d-flex align-items-center text fs16 textShadow") : cn(style.inputContWidth, style.unselectable, " col-10 d-flex align-items-center text fs16 textShadow")}>{text}</div>
          <div className={props && props.genratedApiKey ? cn(style.imgContWidth, 'col-1 text-right') : cn(style.imgContWidth, style.noCursor, 'col-1 text-right')}>
            <CopyToClipboard text={text} onCopy={copytext} >
              <img onClick={() => {functionClick(1);}} className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noPointerEvent, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1')}>{copySuccess}</div>
        </div>
      </div>

      {props && props.argument && props.argument[0].name && (
        <div className="border border-primary row m-0 p-4 d-flex ">
          <div className="col-10 fs16">
            <>
              <div>{`{`}</div>
              <div className="ml-1">{`"eventpayload": {`}</div>
              <div className="ml-3">{`"id": "${props && props.id}",`}</div>
              <div className="ml-3">{`"txnId": "${props && props.txnId}",`}</div>
              <div className="ml-3">{`"choice": "<0 or 1>",`}<span className="" id='Info'><img style={{width:'20px',marginLeft:'5px', paddingBottom:'3px'}} src="../infoIcon.png" alt="info"/><Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={'Info'}
        toggle={toggle}
      >
        To save data in data base pass 0 or else 1 
      </Tooltip> </span></div>
              <div className="ml-3">{`"eventname": "${props && props.eventName}",`}</div>
              <div className="ml-3">{` "arguments": [`}</div>
              {props && props.argument && props.argument[0].name && props.argument[0].name.map((payload, index) => {
                return (
                  <div className="ml-5">{props && props.argument && props.argument[0].name && props.argument[0].argTypes && props.argument[0].argTypes[index] === 'int' ? `{"arg": "${payload}", "argValue": <ARGUMENT VALUE ${(props && props.argument && props.argument[0].name && props.argument[0].argTypes && props.argument[0].argTypes[index]).toUpperCase()}>}${index < props.argument[0].argTypes.length - 1 ? ',' : ''}` : `{"arg": "${payload}", "argValue": "<ARGUMENT VALUE ${(props && props.argument && props.argument[0].name && props.argument[0].argTypes && props.argument[0].argTypes[index]).toUpperCase()}>"}${index < props.argument[0].argTypes.length - 1 ? ',' : ''}`}</div>
                )
              })}
              <div className="ml-3">{`]`}</div>
              <div className="ml-3">{`}`}</div>
              <div className="ml-1">{`}`}</div>
            </>
          </div>
          <div className={cn(style.imgContWidth, 'col-1 text-right')}>
            <CopyToClipboard text={props && props.jsonDetail} onCopy={copytext2} >
              <img className={props && props.genratedApiKey ? cn('w-50 cursor-pointer') : cn(style.noCursor, 'w-50 cursor-pointer')} src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
          <div className={cn(style.copiedCont, 'col-1 text-left')}>{copySuccess2}</div>
        </div>
      )}
    </div>
    )}
    </>
    );
}

export default ViewContract;