import { useEffect, useState } from "react";
import ViewContract from "../../components/ViewContract";
import axiosClient from '../../ApiClient';
import { Formik } from 'formik';
import API_ENDPOINTS from '../../components/common/api';

const ViewContracts = () => {
    const [events, setEvents] = useState();
    const [eventName, setEventName] = useState();
    const [argument, setArgument] = useState();
    const [genratedApiKey, setGenratedApiKey] = useState();
    const contractId = window.localStorage.getItem('contractId')
    const [id, setId] = useState();
    const [txnId, setTxnId] = useState();
    const [jsonDetail, setjsonDetail] = useState();
    const [pluginDetails, setpluginDetails] = useState();

    const contractAddress = window.localStorage.getItem('address');
    const userjson = JSON.parse(window.localStorage.getItem('userjson'));

    useEffect(() => {
        axiosClient.post(API_ENDPOINTS.fetchEvents, {
            "smartContractId": contractId
        }).then((res) => {
            console.log('resdata', res.data.data);
            setEvents(res.data.data);
            axiosClient.post(API_ENDPOINTS.getApiKey, {
            "userId": userjson.id,
            "smartContractId": contractId
        }).then((res) => {
            setGenratedApiKey(res && res.data && res.data.data)
            window.localStorage.setItem('APIKEY', res && res.data && res.data.data)
        }).catch(error => {
            console.log('error', error)
        })
        });

        axiosClient.get(API_ENDPOINTS.fetchPluginDetails).then((res) => {
            console.log('rest', res.data);
            setpluginDetails(res.data.pluginDetails);
        }).catch(error => {
            console.log('error', error)
        });
        
    }, []);

    const onChange = (e) => {
        console.log('eee', e);
        setEventName(e.name);
        setId(e.id)
        setTxnId(`0X${Math.floor(100000 + Math.random() * 900000)}`);
        axiosClient.post(API_ENDPOINTS.fetchArgs, {
            "id": e.id
        }).then((res) => {
            console.log('resdata', res.data.data);
            setArgument(res.data.data);

            const eventpayload = {};
            eventpayload.id = e.id;
            eventpayload.txnId = `0X${Math.floor(100000 + Math.random() * 900000)}`;
            eventpayload.choice = 0;
            eventpayload.eventname = e.name;
            eventpayload.arguments = [];
            res.data.data && res.data.data[0].name && res.data.data[0].name.forEach((load, index) => {
                eventpayload.arguments.push({ "arg": load, "argValue": `<ARGUMENT VALUE ${(res.data.data && res.data.data[0].name && res.data.data[0].argTypes && res.data.data[0].argTypes[index]).toUpperCase()}>` });
            });
            console.log('eventpayload', eventpayload);
            setjsonDetail(JSON.stringify({ eventpayload: eventpayload }));
        });
    };

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <div>
            <Formik
                render={formProps => <ViewContract formProps={formProps} eventName={eventName} pluginDetails ={pluginDetails} argument={argument} events={events} onChange={onChange} genratedApiKey={genratedApiKey} id={id} txnId={txnId} jsonDetail={jsonDetail} />}
                initialValues={{}}
                onSubmit={onSubmit}
            />
        </div>
    )
}

export default ViewContracts;